import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { getAnnouncementAsync } from './asyncActions'
import { Status } from 'models'
import { announcementModel } from './announcementModel'

export interface AnnouncementState {
  data: announcementModel | null
  status: Status
  error: string | any
}

const initialState: AnnouncementState = {
  data: null,
  error: null,
  status: Status.IDLE,
}

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    reset: state => {
      state = initialState
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAnnouncementAsync.pending, state => {
        state.error = null
        state.status = Status.PENDING
      })
      .addCase(getAnnouncementAsync.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = Status.SUCCEEDED
      })
      .addCase(getAnnouncementAsync.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const { reset } = announcementSlice.actions

export const selectAnnouncementData = (state: RootState): AnnouncementState['data'] => state.announcement.data

export default announcementSlice.reducer
