import { AllMethods } from 'Components/PaymentCards'
import styled from 'styled-components'

const AllMethodsContainer = () => {
  return (
    <MethodsContainer>
      {AllMethods.map((method, index) => {
        const Icon = method.icon
        return (
          <ImageWrapper key={index}>
            <Icon />
          </ImageWrapper>
        )
      })}
    </MethodsContainer>
  )
}

export default AllMethodsContainer
const MethodsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  max-width: 80%;
  gap: 70px;
  flex-wrap: wrap;
  @media (max-width: 700px) {
    max-width: 90%;
    gap: 20px;
    justify-content: space-between;
    row-gap: 60px;
  }
`

const ImageWrapper = styled.div`
  object-fit: contain;
  @media (max-width: 350px) {
    width: 100px;
  }
`
