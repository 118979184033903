import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { loginRequestAsync, logoutRequestAsync, requestPasswordReset, resetPasswordReset } from './asyncActions'
import { AppTokenResource } from './asyncActions'
import { Status } from 'models'

export interface LoginState {
  data: AppTokenResource | null
  status: Status
  error: string | any
  updatePassSuccessMessage: string
  updatePassFailMessage: string | any
}

const initialState: LoginState = {
  data: null,
  error: null,
  status: Status.IDLE,
  updatePassSuccessMessage: null,
  updatePassFailMessage: null,
}

export const loginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload
    },
    deleteStatus: state => {
      state.status = Status.IDLE
    },
    deletePassResetMessages: state => {
      state.updatePassSuccessMessage = null
      state.updatePassFailMessage = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(logoutRequestAsync.fulfilled, (state, action) => {
        state.data = null
        state.status = Status.IDLE
        state.error = null
      })
      .addCase(loginRequestAsync.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = Status.SUCCEEDED
      })
      .addCase(requestPasswordReset.fulfilled, (state, action) => {
        state.status = Status.IDLE
      })
      .addCase(resetPasswordReset.fulfilled, (state, action) => {
        state.status = Status.SUCCEEDED
        state.updatePassSuccessMessage = action.payload.message
      })
      .addCase(resetPasswordReset.rejected, (state, action) => {
        state.updatePassFailMessage = action.payload || 'Unknown Error'
      })
      .addMatcher(
        isAnyOf(requestPasswordReset.pending, loginRequestAsync.pending, resetPasswordReset.pending),
        (state, action) => {
          state.error = null
          state.status = Status.PENDING
        }
      )
      .addMatcher(isAnyOf(requestPasswordReset.rejected, loginRequestAsync.rejected), (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const { setStatus, deleteStatus, deletePassResetMessages } = loginSlice.actions

export const selectData = (state: RootState): LoginState['data'] => state.auth.data
export const selectLoginError = (state: RootState): LoginState['error'] => state.auth.error
export const selectLoginStatus = (state: RootState): LoginState['status'] => state.auth.status
export const selectPassResetSuccessMessage = (state: RootState): LoginState['updatePassSuccessMessage'] =>
  state.auth.updatePassSuccessMessage
export const selectPassResetFailMessage = (state: RootState): LoginState['updatePassFailMessage'] =>
  state.auth.updatePassFailMessage

export default loginSlice.reducer
