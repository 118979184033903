import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Input, Popconfirm } from 'antd'
import Text from 'Components/UiKit/Text'
import { FontSize, FontFamily, FontWeight, calculateDynamicFont } from 'constants/index'
import { Colors } from 'constants/colors'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import RateComponent from 'Components/Visited/VisiteItem/RateComponent'
import { useEffect, useState, VoidFunctionComponent } from 'react'
import Button from 'Components/UiKit/Buttons/Button'
import { useAppDispatch } from 'store/hooks'
import { deleteCommentAsync, addCommentAsync } from 'store/visits/comment/asyncActions'
import { getVisitsAsync } from 'store/visits/visitsList/asyncActions'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

const { TextArea } = Input

interface Props {
  date?: string
  name?: string
  comment?: string
  rate?: number
  ratingId?: number
  orderBy?: 'asc' | 'desc'
  userId?: number
  visitId?: number
  museumId?: number
  isMobile?: boolean
}

const VisitedItem: VoidFunctionComponent<Props> = ({
  date,
  name,
  comment,
  rate = 0,
  ratingId,
  orderBy,
  userId,
  museumId,
  visitId,
  isMobile = false,
}) => {
  const { t } = useTranslation()
  const [isVisibleBox, setVisibleBox] = useState<boolean>(false)
  const [commentText, setComment] = useState<string>()
  const [showRatingToast, setShowRatingToast] = useState<boolean>(false)
  const [visiteRate, setVisitRate] = useState<number>(0)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setVisitRate(rate)
  }, [rate])

  useEffect(() => {
    if (showRatingToast) {
      toast.error(t('ratingFirstMessage'))
      setShowRatingToast(false)
    }
  }, [showRatingToast, t])

  const handleVisibleBox = (): void => {
    if (ratingId) setVisibleBox(true)
    else setShowRatingToast(true)
  }

  const handleDeleteComment = async (): Promise<void> => {
    const deleteCommentBody = {
      ratingId: ratingId,
      requestBody: {
        note: comment,
      },
    }

    await dispatch(deleteCommentAsync(deleteCommentBody))
    dispatch(getVisitsAsync({ orderBy }))
  }

  const handleSubmitComment = async (): Promise<void> => {
    const addCommentBody = {
      requestBody: {
        rating_id: ratingId,
        comment: commentText,
        rating: rate,
      },
    }

    await dispatch(addCommentAsync(addCommentBody))
    dispatch(getVisitsAsync({ orderBy }))
    setVisibleBox(false)
  }

  const handleTextChanges = (e: any): void => {
    setComment(e.target.value)
  }

  const handleDeleteCommentConfirm = () => {
    handleDeleteComment()
  }

  const returnTopButton = () => {
    return (
      <ButtonTitleStyled isMobile={isMobile}>
        {comment && (
          <Popconfirm
            placement="topLeft"
            title={t('doYouWantToDelete')}
            onConfirm={handleDeleteCommentConfirm}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <TextBtnContainer
              containerClassName="mr-4"
              fontWeight={FontWeight.BOLD}
              size={FontSize.FOURTEEN}
              fontFamily={FontFamily.BOLD}
              text={t('deleteComment')}
            />
          </Popconfirm>
        )}

        {/* <TextBtnContainer
          containerClassName="flex-end"
          fontWeight={FontWeight.BOLD}
          size={FontSize.FOURTEEN}
          fontFamily={FontFamily.BOLD}
          onClick={() => {}}
          text={t('seeOtherReviews')}
          shortIcon
        /> */}
      </ButtonTitleStyled>
    )
  }
  return (
    <CardWrapper>
      <TitleStyled>
        <DateInTitleStyled>
          <Text
            textAlign="left"
            color={Colors.GREY_CHATEAU}
            fontSize={calculateDynamicFont({
              max: FontSize.SIXTEEN,
              min: FontSize.TWELVE,
            })}
          >
            {dayjs(date).format('D.M.YYYY, HH:mm')}
          </Text>
          <Text textAlign="left" fontSize={FontSize.TWENTY} fontFamily={FontFamily.BOLD} fontWeight={FontWeight.SEVEN}>
            {name}
          </Text>
        </DateInTitleStyled>
        {!isMobile && returnTopButton()}
      </TitleStyled>
      {comment && (
        <Text className="mt-3" textAlign="left" fontSize={FontSize.SIXTEEN}>
          {comment}
        </Text>
      )}
      {isVisibleBox && (
        <CommentBoxContainer>
          <CommentBox rows={7} placeholder={t('writeTheReviewHere')} onChange={handleTextChanges} />
          <Text className="mt-2" textAlign="right">
            {t('max500Char')}
          </Text>
        </CommentBoxContainer>
      )}
      <CommentRateStyled isMobile={isMobile}>
        {!comment && (
          <BtnContainer
            bgColor={Colors.ROYAL_BLUE}
            fontWeight={FontWeight.NORMAL}
            size="small"
            text={isVisibleBox ? t('sendReview') : t('writeAreview')}
            onClick={() => (isVisibleBox ? handleSubmitComment() : handleVisibleBox())}
            whiteSpace="nowrap"
          />
        )}
        <RateComponent
          rate={visiteRate}
          ratingId={ratingId}
          orderBy={orderBy}
          userId={userId}
          visitId={visitId}
          museumId={museumId}
          onChange={setVisitRate}
        />
      </CommentRateStyled>
      {isMobile && returnTopButton()}
    </CardWrapper>
  )
}

export default VisitedItem

export const CardWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 40px;
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
  background-color: ${Colors.WHITE};
`
const TitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonTitleStyled = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: flex-end;
  gap: 12px;
  margin-top: ${({ isMobile }) => (isMobile ? '24px' : '0px')};
`
const CommentRateStyled = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  margin-top: 32px;
  gap: ${({ isMobile }) => (isMobile ? '24px' : '34px')};
`
const DateInTitleStyled = styled.div`
  width: 60%;
  @media (max-width: 700px) {
    width: 100%;
  }
`
const CommentBox = styled(TextArea)`
  background: ${Colors.ALICE_BLUE};
  box-shadow: inset 0px 1px 2px rgba(49, 44, 61, 0.07);
  border-radius: 10px;
  height: 194px;
  padding: 17px 25px;
  font-size: ${FontSize.FOURTEEN};
  font-weight: ${FontWeight.NORMAL};
  font-family: ${FontFamily.NORMAL};
`

const TextBtnContainer = styled(TextBotton)`
  height: fit-content;
`
const BtnContainer = styled(Button)`
  width: 100% !important;
  max-width: 200px !important;
  height: 30px;
  padding-left: 0px;
  padding-right: 0px;
  @media (max-width: 700px) {
    width: 150px;
    height: 40px;
  }
`

const CommentBoxContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 60px;
`
