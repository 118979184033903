import { useState } from 'react'
import { Input } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont } from 'constants/index'
import { Colors } from 'constants/colors'
import Card from 'assets/icons/socialMediaCard.svg'
import { useParams } from 'react-router'

const { TextArea } = Input

interface StandingOrderModalProps {
  levelNumber?: number
  levelText?: string
  commentText?: string
  setComment?: (value: string) => void
}

const ShareUrl = ({ levelNumber, levelText, commentText, setComment }: StandingOrderModalProps) => {
  const { t } = useTranslation()
  const [isVisiblePicture, setVisiblePicture] = useState<boolean>(true)
  const params = useParams<{ levelNumber: string; levelText: string; commentText: string }>()
  const maxTextLength = 160

  const replaceSpace = (text: string): string => {
    return text.replaceAll(' ', '%20')
  }

  const handleTextChanges = (e: any): void => {
    setComment(replaceSpace(e.target.value))
  }

  const handlePictureVisibility = () => {
    setVisiblePicture(!isVisiblePicture)
  }

  return (
    <Container>
      <BodyWrapper>
        <Text
          textAlign={'center'}
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          fontSize={calculateDynamicFont({
            min: FontSize.EIGHTEEN,
            max: FontSize.THIRTY_TWO,
          })}
          className="mb-3"
        >
          {t('shareOnSocialMedia')}
        </Text>
        {!params?.commentText && (
          <UpperLevelBox>
            <Text
              textAlign="left"
              fontSize={FontSize.EIGHTEEN}
              fontWeight={FontWeight.SEVEN}
              fontFamily={FontFamily.BOLD}
            >
              {t('editShareModalText')}
            </Text>
            <Text
              textAlign="right"
              fontSize={FontSize.SIXTEEN}
              fontWeight={FontWeight.NORMAL}
              fontFamily={FontFamily.NORMAL}
            >
              {commentText.length} / {maxTextLength}
            </Text>
          </UpperLevelBox>
        )}
        {!params?.commentText ? (
          <CommentBox size="large" defaultValue={commentText} rows={4} onChange={handleTextChanges} />
        ) : (
          <CommentedBox>
            <Text
              textAlign="left"
              fontSize={FontSize.SIXTEEN}
              fontWeight={FontWeight.NORMAL}
              fontFamily={FontFamily.NORMAL}
            >
              {params.commentText}
            </Text>
          </CommentedBox>
        )}

        {!params?.levelNumber && !params?.levelText ? (
          <UpperLevelBox>
            <Text
              textAlign="left"
              fontSize={FontSize.EIGHTEEN}
              fontWeight={FontWeight.SEVEN}
              fontFamily={FontFamily.BOLD}
            >
              {t('sharePhoto')}
            </Text>
            <Text
              onClick={handlePictureVisibility}
              textAlign="right"
              fontSize={FontSize.SIXTEEN}
              fontWeight={FontWeight.NORMAL}
              fontFamily={FontFamily.NORMAL}
              className="pointer"
            >
              {t('deletePhoto')}
            </Text>
          </UpperLevelBox>
        ) : null}
        {isVisiblePicture ? (
          <LevelBox>
            <Text
              color={Colors.WHITE}
              textAlign="left"
              fontSize={FontSize.EIGHTEEN}
              fontWeight={FontWeight.FOUR}
              fontFamily={FontFamily.LIGHT}
              className="mb-4"
            >
              {t('youHaveAchieved')}
              {t(levelText) || t(params.levelText)}
              {t('thisLevel!')}
            </Text>
            <Text
              color={Colors.WHITE}
              textAlign="left"
              fontSize={FontSize.SEVENTY}
              fontWeight={FontWeight.EIGHT}
              fontFamily={FontFamily.EXTRABOLD}
            >
              {levelNumber || params.levelNumber}
            </Text>
            <Text
              color={Colors.WHITE}
              textAlign="left"
              fontSize={FontSize.TWENTY}
              fontWeight={FontWeight.EIGHT}
              fontFamily={FontFamily.EXTRABOLD}
            >
              {t('uniqueMuseumVisit')}
              <br />
              {t('withMuseumCard!')}
            </Text>
            <CardStyled src={Card} alt="card" />
          </LevelBox>
        ) : null}
      </BodyWrapper>
    </Container>
  )
}

export default ShareUrl

const CommentBox = styled(TextArea)`
  background: ${Colors.ALICE_BLUE};
  box-shadow: inset 0px 1px 2px rgba(49, 44, 61, 0.07);
  border-radius: 10px;
  margin-top: -10px;
`
const CommentedBox = styled.div`
  background: ${Colors.ALICE_BLUE};
  box-shadow: inset 0px 1px 2px rgba(49, 44, 61, 0.07);
  border-radius: 10px;
  margin-top: -10px;
  width: 100%;
  padding: 15px 20px;
`
const UpperLevelBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 97%;
  margin-bottom: -5px;
`
const LevelBox = styled.div`
  position: relative;
  width: 573px;
  background: ${Colors.DARK_PURPLE};
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 20px;
  width: 100%;
`
const CardStyled = styled.img`
  position: absolute;
  right: 0;
  top: 40px;
  @media (max-width: 700px) {
    top: 70px;
    width: 40%;
  }
`
const Container = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`
const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 573px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .pointer {
    cursor: pointer;
  }
`
