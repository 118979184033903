import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'

export type UpdateCommentBodyModel = {
  ratingId: number
  requestBody: {
    rating: number
  }
}

export type AddCommentBodyModel = {
  requestBody: {
    visit_id: number
    museum_id: number
    rating: number
    user_id?: number
  }
}

export const updateRatingAsync: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  '/web/rating/updateRating',
  async ({ ratingId, requestBody }: UpdateCommentBodyModel, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webRatingUpdate(ratingId, requestBody)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const addRatingAsync: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  '/web/rating/addRating',
  async ({ requestBody }: AddCommentBodyModel, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webRatingCreate(requestBody)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
