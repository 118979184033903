import { FunctionComponent, useState } from 'react'
import { CheckBox } from '../../../../UiKit/CheckBox'
import Text from '../../../../UiKit/Text'
import { FontSize, FontWeight } from '../../../../../constants/fonts'
import { useTranslation } from 'react-i18next'

const UseAddressCheckbox: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const [isActive, setActive] = useState<boolean>(true)
  return (
    <div className={'horizontal checkbox-in-profile'}>
      <div className={'item flex-none'}>
        <CheckBox isChecked={isActive} onToggle={(): void => setActive(!isActive)}></CheckBox>
      </div>
      <div className={'item flex-1 justify-content-start'}>
        <Text
          fontFamily="RightGrotesk-bold"
          fontWeight={FontWeight.EIGHT}
          fontSize={FontSize.EIGHTEEN}
          className={'display-inline height-fit-content'}
          textAlign={'left'}
        >
          {t('useSameAddressWithPaymentInformation')}
        </Text>
      </div>
    </div>
  )
}

export default UseAddressCheckbox
