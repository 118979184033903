import { useState, VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { FontSize, calculateDynamicFont, FontWeight, FontFamily, ModalContainer } from 'constants/index'
import { Colors } from 'constants/colors'
import GuysIcon from 'assets/icons/guysCard.svg'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { useHistory } from 'react-router'
import { handleCardRenewal, checkBtnAccess, isCardExpired, formatPrice } from 'utils/functions'
import { selectData } from 'store/user'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import CouponModal from 'Components/Modals/CouponModal'

interface Props {
  isMobile?: boolean
  price?: number
}

const Notice: VoidFunctionComponent<Props> = ({ isMobile = false, price }) => {
  const [t, { language }] = useTranslation()
  const userInfo = useAppSelector(selectData)
  const [showCouponModal, setShowCouponModal] = useState(false)
  const history = useHistory()
  const dispatch = useAppDispatch()

  const handleClick = (coupon?: string) => {
    handleCardRenewal('renew', userInfo.code, language, dispatch, coupon)
    history.push('/card-renewal')
  }

  return (
    <Contanier isMobile={isMobile}>
      <div className="notice-text d-flex">
        <Text
          width="fit-content"
          textAlign="left"
          fontSize={calculateDynamicFont({
            max: FontSize.SIXTEEN,
            min: FontSize.FOURTEEN,
          })}
          fontWeight={isMobile ? FontWeight.SEVEN : FontWeight.NORMAL}
          fontFamily={isMobile ? FontFamily.BOLD : FontFamily.NORMAL}
          color={Colors.WHITE}
          className="mr-1"
        >
          {userInfo.code.expires_at
            ? isCardExpired(userInfo.code.expires_at)
              ? t('homeRenewExpired', {
                  break: isMobile ? '\n' : ' ',
                  price: formatPrice(price / 100, false),
                })
              : t('renewYourMuseomCardForReduce', {
                  break: isMobile ? '\n' : ' ',
                  price: formatPrice(price / 100, false),
                })
            : t('homeRenewNoVists')}
        </Text>
        {checkBtnAccess(userInfo) && (
          <TextBotton
            fontWeight={FontWeight.SEVEN}
            fontFamily={FontFamily.BOLD}
            size={calculateDynamicFont({
              max: FontSize.SIXTEEN,
              min: FontSize.FOURTEEN,
            })}
            onClick={() => setShowCouponModal(true)}
            text={t('renewYourMuseomCard')}
          />
        )}
      </div>
      <GuysImg isMobile={isMobile} src={GuysIcon} />
      <ModalContainer isVisible={showCouponModal} setVisible={() => setShowCouponModal(false)}>
        {showCouponModal && <CouponModal handleClick={handleClick} />}
      </ModalContainer>
    </Contanier>
  )
}

export default Notice

const Contanier = styled.div<Props>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  justify-content: ${({ isMobile }) => (!isMobile ? 'flex-start' : 'center')};
  padding-left: ${({ isMobile }) => (isMobile ? '32px' : '43px')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '42px' : '53px')};
  height: ${({ isMobile }) => (isMobile ? '94px' : '65px')};
  background-color: ${Colors.TOLOPEA};
  border-radius: ${(props: Props) => (props.isMobile ? 0 : '44px')};
  position: relative;
  .notice-text {
    flex-wrap: wrap;
    max-width: 70%;
  }
`
const GuysImg = styled.img<Props>`
  position: absolute;
  right: 39px;
  bottom: 0;
  width: ${({ isMobile }) => (isMobile ? '23%' : 'auto')};
  @media (max-width: 700px) {
    right: 18px;
  }
`
