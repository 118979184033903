import { useAppDispatch } from 'store/hooks'
import { logoutRequestAsync } from 'store/auth/asyncActions'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import AllTabsForProfilePage from './Tabs'
import { FontFamily } from 'constants/index'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { generateFieldUuid } from 'utils/functions'

export function TabsAndLogout() {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()

  function logout() {
    dispatch(logoutRequestAsync())
  }

  return (
    <Container>
      <AllTabsForProfilePage />
      <TextBotton
        fontFamily={FontFamily.BOLD}
        containerClassName={'position-absolute sign-out-profile'}
        onClick={logout}
        text={t('signOut')}
        id={generateFieldUuid('signOut', 'mobile-size-link')}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  .sign-out-profile {
    top: 20px;
    right: 0px;
    @media (max-width: 700px) {
      display: none;
    }
  }
`
