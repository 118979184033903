import { VoidFunctionComponent } from 'react'
import Text from 'Components/UiKit/Text'
import { FontSize, FontFamily, FontWeight } from 'constants/index'

interface Props {
  title: string
  description?: string
}

const LevelItem: VoidFunctionComponent<Props> = ({ title, description }) => {
  return (
    <div style={{ width: '80%' }}>
      <Text
        width="fit-content"
        textAlign="left"
        fontSize={FontSize.EIGHTEEN}
        fontFamily={FontFamily.EXTRABOLD}
        fontWeight={FontWeight.EIGHT}
      >
        {title}
      </Text>
      <Text
        width="fit-content"
        textAlign="left"
        fontSize={FontSize.FOURTEEN}
        fontFamily={FontFamily.NORMAL}
        fontWeight={FontWeight.NORMAL}
      >
        {description}
      </Text>
    </div>
  )
}

export default LevelItem
