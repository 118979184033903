import React from 'react'
import { useTranslation } from 'react-i18next'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'
import Text from '../UiKit/Text'
import Button from '../UiKit/Buttons/Button'
import { FontWeight } from 'constants/fonts'

const Popup: React.FunctionComponent = () => {
  const { t, ready } = useTranslation()

  const deleteAllCookies = (): void => {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=museokortti.fi;path=/'
    }
  }

  return (
    <Container className="popup">
      {ready && (
        <CookieConsent
          disableStyles
          location="none"
          disableButtonStyles
          buttonClasses="buttonStyle"
          buttonText="OK"
          cookieName="museokortti"
          buttonWrapperClasses="buttonWrapper"
          overlay
          setDeclineCookie={false}
          enableDeclineButton
          // flipButtons
          onDecline={() => {
            deleteAllCookies()
            // resetCookieConsentValue('museokortti')
          }}
          declineButtonClasses="buttonStyle"
          declineButtonText={t('cookiesDisableButton')}
          overlayClasses="overlayclass"
        >
          <Text textAlign="left" fontWeight={FontWeight.BOLD} className="bold-text">
            {t('cookiesTitle')}
          </Text>
          <Text textAlign="left" className="paragraph">
            {t('cookiesBody1')}
          </Text>
          {/* <a
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration"
          href={'https://museot.fi/doc/kayttoehdot/Tietosuojaseloste2020.pdf'}
        >
          <Text className="hyperlink-text">{t('cookiesHyperlink1')}</Text>
        </a>
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration"
          href={'https://museot.fi/doc/kayttoehdot/Museokortin-kayttoehdot-2020.pdf'}
        >
          <Text className="hyperlink-text">{t('cookiesHyperlink2')}</Text>
        </a>
        <Text type={TextType.REGULAR} className="paragraph">
          {t('cookiesChoosingOk')}
        </Text>
        <Text type={TextType.REGULAR} className="paragraph">
          {t('cookiesChoosingDisable1')}
          <a target="_blank" rel="noopener noreferrer" className="text-decoration" href={'https://museot.fi/'}>
            <Text className="hyperlink-text"> Museot.fi </Text>
          </a>
          {t('cookiesChoosingDisable2')}
        </Text> */}
          <div className="buttonWrapper">
            <Button
              onClick={() => (window.location.href = 'https://museot.fi/doc/kayttoehdot/Tietosuojaseloste2020.pdf')}
              className="buttonStyle"
              id={'cookiesPrivacyButton'}
              text={t('cookiesPrivacyButton')}
            />
            <Button
              onClick={() =>
                (window.location.href = 'https://museot.fi/doc/kayttoehdot/Museokortin-kayttoehdot-2020.pdf')
              }
              className="buttonStyle"
              id={'cookiesTermsOfUseButton'}
              text={t('cookiesTermsOfUseButton')}
            />
          </div>
        </CookieConsent>
      )}
    </Container>
  )
}

export default Popup

const Container = styled.div`
  .CookieConsent {
    max-width: 500px;
    border-radius: 5px;
    padding: 1em 2em;
    background-color: white;
    position: fixed;
    z-index: 10210;
    top: 60%;
    left: 50%;
    overflow: auto;
    max-height: 90vh;
    right: auto;
    bottom: auto;
    transform: translate3d(-50%, -50%, 9999990px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }

  .overlayclass {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .bold-text {
    font-size: 18px;
    font-weight: bold
    display: inline-block;
    line-height: 21px;
  }
  .paragraph {
    font-size: 16px;
    margin-bottom: 20px;
    display: inline-block;
    line-height: 21px;
    margin-top: 10px;
  }
  .hyperlink-text {
    font-size: 16px;
    color: #fc3d7e;
    font-family: Montserrat-bold;
  }
  .text-decoration {
    text-decoration: none;
  }
  .buttonStyle {
    font-family: RightGrotesk-SpatialDark, sans-serif;
    font-weight: 800;
    font-style: normal;
    cursor: pointer;
    border-radius: 3rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #ffffff;
    background-color: #00a751;
    width: 15em;
    height: 3em;
    text-align: center;
    cursor: pointer;
    line-height: 2em;
    border-radius: 0.5em;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  .buttonWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`
