import { VoidFunctionComponent, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Header } from 'Components/UiKit/Header'
import HomeHeader from './DesktopSize/HomeHeader'
import Notice from './DesktopSize/Notice'
import Order from './DesktopSize/Order'
import LevelAndVisited from './DesktopSize/LevelAndVisited'
import CalturalAdvantage from './DesktopSize/CalturalAdvantage'
import Subscribe from './DesktopSize/Subscribe'
import NewsSlider from './DesktopSize/NewsSlider'
import ExhibitionSlider from './DesktopSize/ExhibitionSlider'
import MobileFooter from './DesktopSize/MobileFooter'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import CustomerCard from 'Components/UiKit/CustomerCard'
import { FontSize, FontWeight, Colors, FontFamily, ModalContainer } from 'constants/index'
import Text from 'Components/UiKit/Text'
import { selectPriceData } from 'store/price'
import { getPriceAsync } from 'store/price/asyncActions'
import { selectAnnouncementData } from 'store/announcement'
import { checkBtnAccess, displayDate, generateFieldUuid, handleCardRenewal, isCardExpired } from 'utils/functions'
import Button from 'Components/UiKit/Buttons/Button'
import { useHistory } from 'react-router'
import InfoIcon from 'assets/icons/info.svg'
import NewsAndRelease from './DesktopSize/NewsAndRelease'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import CouponModal from 'Components/Modals/CouponModal'
import { returnPrice } from 'utils/functions'
import { logoutRequestAsync, deactivateRequestAsync } from 'store/auth/asyncActions'
import DeactivateUserModal from 'Components/Modals/DeactivateUserModal'

const DesktopSize: VoidFunctionComponent = () => {
  const [t, { language }] = useTranslation<string>()
  const userInfo = useAppSelector(selectData)
  const [showCouponModal, setShowCouponModal] = useState(false)
  const history = useHistory()
  const [visibleAnnouncement, setVisibleAnnouncement] = useState(true)
  const dispatch = useAppDispatch()
  const priceData = useAppSelector(selectPriceData)
  const announcementData = useAppSelector(selectAnnouncementData)
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)

  useEffect(() => {
    if (!priceData) {
      dispatch(getPriceAsync())
    }
  }, [dispatch, userInfo, priceData])

  const checkAnouncementData = useCallback((): boolean => {
    if (
      announcementData &&
      visibleAnnouncement &&
      announcementData?.data.filter(item => item.text_language === language).length > 0
    )
      return true
    else return false
  }, [announcementData, visibleAnnouncement, language])

  useEffect(() => {
    let elem = Array.from(document.getElementsByClassName('language-icon') as HTMLCollectionOf<HTMLElement>)
    if (checkAnouncementData()) {
      elem[0].style.setProperty('top', '80px', 'important')
    } else elem[0].style.setProperty('top', '0px', 'important')

    return () => {
      elem[0].style.setProperty('top', '0px', 'important')
    }
  }, [checkAnouncementData])

  const handleClick = (coupon?: string) => {
    handleCardRenewal('renew', userInfo.code, language, dispatch, coupon)
    history.push('/card-renewal')
  }
  function logout() {
    dispatch(logoutRequestAsync())
  }
  const deactivate = () => {
    dispatch(deactivateRequestAsync())
  }
  return (
    <Container>
      {checkAnouncementData() && (
        <HomeHeader text={announcementData?.data} setVisibleAnnouncement={setVisibleAnnouncement} isMobile={true} />
      )}
      <BodyWrapper>
        <Header title={t('yourCustomerInfo')} subTitle={t('manageEverythingEasily')} />
        <CustomerCardStyled className={'cardNumber flex-3 position-relative'}>
          <CustomerCard cardNumber={userInfo.code.code} fontSize={FontSize.TWENTY_ONE} />
        </CustomerCardStyled>
        <NotificationCardWrapper>
          <img src={InfoIcon} alt="" width={19} height={19} className="mb-2" />
          <Text
            className="mb-3"
            fontSize={FontSize.FOURTEEN}
            fontWeight={FontWeight.SEVEN}
            fontFamily={FontFamily.BOLD}
          >
            {userInfo.code.expires_at
              ? isCardExpired(userInfo.code.expires_at)
                ? t('cardExpired', { date: displayDate(userInfo.code.expires_at) })
                : t('cardValidUntil', { date: displayDate(userInfo.code.expires_at) })
              : t('noExpiryDateMessage')}
          </Text>
          {checkBtnAccess(userInfo) && (
            <BtnContainer
              onClick={() => setShowCouponModal(true)}
              bgColor={Colors.FRENCH_ROSE}
              text={t('renewYourMuseomCard')}
            />
          )}
        </NotificationCardWrapper>
        <TextBotton
          id={generateFieldUuid('customerInformation', 'mobile-size')}
          containerStyle={{ width: '100%' }}
          fontFamily={FontFamily.BOLD}
          size={FontSize.FOURTEEN}
          onClick={() => history.push('/profile')}
          text={t('customerInformation')}
        />
        <TextBtnContainer
          fontFamily={FontFamily.BOLD}
          size={FontSize.FOURTEEN}
          onClick={() => setShowDeactivateModal(true)}
          text={t('deleteYourCustomerAccount')}
        />
        <TextBtnContainer fontFamily={FontFamily.BOLD} size={FontSize.FOURTEEN} onClick={logout} text={t('signOut')} />
      </BodyWrapper>
      <Notice isMobile price={returnPrice(userInfo?.code.expires_at, priceData)} />
      <BodyWrapper>
        <NewsSlider name={userInfo.firstname} isMobile={true} />
        <Order />
        <LevelAndVisited />
        <CalturalAdvantage isMobile />
        <Subscribe isMobile />
        <ExhibitionSlider isMobile />
        <MobileFooter />
        <NewsAndRelease isMobile />
      </BodyWrapper>
      <ModalContainer isVisible={showCouponModal} type={'combine'} setVisible={() => setShowCouponModal(false)}>
        {<CouponModal handleClick={handleClick} />}
      </ModalContainer>
      <ModalContainer isVisible={showDeactivateModal} type={'combine'} setVisible={() => setShowDeactivateModal(false)}>
        <DeactivateUserModal handleClick={deactivate} setVisible={() => setShowDeactivateModal(false)} />
      </ModalContainer>
    </Container>
  )
}

export default DesktopSize

const Container = styled.div`
  width: 100%;
  background-color: ${Colors.ALICE_BLUE};
`
const CustomerCardStyled = styled.div`
  .customCard {
    width: 100%;
    height: 210px;
    @media (max-width: 350px) {
      height: 180px;
    }
  }
  margin-bottom: 18px;
`
const NotificationCardWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: ${Colors.LIGHT_PINK};
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 16px;
`

const BodyWrapper = styled.div`
  padding: 0px 24px;
  width: 100%;
`
const BtnContainer = styled(Button)`
  width: 210px;
`

const TextBtnContainer = styled(TextBotton)`
  margin-bottom: 42px;
  width: 100%;
`
