import { ReactElement } from 'react'

function ProfileIcon(props: any): ReactElement {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 16C9.791 16 8 14.209 8 12V10C8 7.791 9.791 6 12 6C14.209 6 16 7.791 16 10V12C16 14.209 14.209 16 12 16Z"
        stroke="#FF598F"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1024 21.154C17.0024 19.86 15.3324 19 13.5004 19H10.5004C8.65339 19 7.00139 19.835 5.90039 21.147"
        stroke="#FF598F"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        stroke="#FF598F"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ProfileIcon
