import Footer from './Footer'
import { FunctionComponent } from 'react'
import Text from 'Components/UiKit/Text'
import { calculateFont, FontWeight, FontFamily } from 'constants/index'
import MainContent from './MainContent'
import { useTranslation } from 'react-i18next'
import { Space } from 'Components/UiKit/Space'
import { isDesktopOrLaptop } from 'constants/responsive-constant'

const UpdateUserInfo: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  return (
    <div className={'vertical ' + (isDesktopOrLaptop() ? 'tab-three' : '')}>
      <div className={'item flex-none'}>
        <div className={'vertical'}>
          <div className={'item flex-1 padding-top-5'}>
            <Text
              fontSize={calculateFont({ coefficient: 1.0 })}
              fontWeight={FontWeight.EIGHT}
              fontFamily={FontFamily.EXTRABOLD}
            >
              {t('contactInfo')}
            </Text>
          </div>
          <div className={'item flex-none '}>
            <Text fontSize={calculateFont({ coefficient: 0.8 })}>{t('updateYourInformationToTheFollowingFields')}</Text>
          </div>
        </div>
      </div>
      <div className={'item flex-none padding-top-2 '}>
        <MainContent />
      </div>
      <Space height={50} />
      <div className={'item flex-none'}>
        <Footer />
      </div>
      <Space height={20} />
    </div>
  )
}

export default UpdateUserInfo
