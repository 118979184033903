import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontSize, Colors } from 'constants/index'
import { Container, BodyWrapper, BtnContainer } from './ModalStyledComponents'
import Input from 'Components/UiKit/Input'
import ModalTitleAndMessage from './ModalTitleAndMessage'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { museumCodeMerge } from 'store/payment/asyncActions'
import { selectData } from 'store/user'
import { getUserInfoAsync } from 'store/user/asyncActions'
import { formatCardNumber, removeSpaces, generateFieldUuid, padStartCode } from 'utils/functions'
import { selectPaymentFormsStatus } from 'store/payment'
import { Status } from 'models'

const CombineCardsModal = () => {
  const [t] = useTranslation()
  const [value, setValue] = useState('')
  const userInfo = useAppSelector(selectData)
  const status = useAppSelector(selectPaymentFormsStatus)
  const dispatch = useAppDispatch()

  const initialTitleState = {
    title: t('combineTwoMuseumCards'),
    message: t('combineTwoMuseumCardsMessage'),
  }

  const handleSubmit = () => {
    let params = {
      code: Number(padStartCode(userInfo.code.code.toString())),
      gift_code: Number(removeSpaces(value)),
    }
    dispatch(museumCodeMerge(params)).then(res => {
      if (res.type.includes('fulfilled')) {
        dispatch(getUserInfoAsync())
      }
    })
  }

  return (
    <Container>
      <BodyWrapper>
        <ModalTitleAndMessage {...initialTitleState} />
        <InputWrapper
          id={generateFieldUuid('numberOfUnusedMuseumCard')}
          defaultValue={value}
          maxLength={14}
          name={t('numberOfUnusedMuseumCard')}
          onChange={e => {
            e.target.value = formatCardNumber(e.target.value)
            setValue(e.target.value)
          }}
        />
        <BtnWrapper
          id={generateFieldUuid('connectTheCards', 'button')}
          text={t('connectTheCards')}
          fontSize={FontSize.SIXTEEN}
          bgColor={Colors.FRENCH_ROSE}
          onClick={handleSubmit}
          disabled={value.length < 14 || status === Status.PENDING}
          isLoading={status === Status.PENDING}
        />
      </BodyWrapper>
    </Container>
  )
}
export default CombineCardsModal

const InputWrapper = styled(Input)`
  height: fit-content;
  margin: 20px 0px;
  .item {
    flex: none;
    input {
      background: ${Colors.ALICE_BLUE};
      padding-left: 25px;
    }
    label {
      margin-left: 14px;
      margin-bottom: 9px;
    }
  }
`

const BtnWrapper = styled(BtnContainer)`
  max-width: 235px;
`
