import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../index'
import { getVisitsAsync } from './asyncActions'
import { Status } from 'models'

export interface VisitsState {
  data: any
  status: Status
  error: string | any
}

const initialState: VisitsState = {
  data: null,
  error: null,
  status: Status.IDLE,
}

export const visitsSlice = createSlice({
  name: 'visits',
  initialState,
  reducers: {
    reset: state => {
      state = initialState
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getVisitsAsync.pending, state => {
        state.error = null
        state.status = Status.PENDING
      })
      .addCase(getVisitsAsync.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = Status.SUCCEEDED
      })
      .addCase(getVisitsAsync.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const { reset } = visitsSlice.actions

export const selectVisitsListData = (state: RootState): VisitsState['data'] => state.visits.visitList.data
export const selectVisitsListError = (state: RootState): VisitsState['error'] => state.visits.visitList.error
export const selectVisitsListStatus = (state: RootState): VisitsState['status'] => state.visits.visitList.status

export default visitsSlice.reducer
