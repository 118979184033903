import { useState } from 'react'
import Input from '../../../../UiKit/Input'
import UseAddressCheckbox from './UseAddressCheckbox'
import Button from '../../../../UiKit/Buttons/Button'
import { FunctionComponent, useEffect } from 'react'
import NewsLetterCheckbox from './NewsLetterCheckbox'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { selectData, selectUpdateUserStatus, selectUserError, deleteError } from '../../../../../store/user'
import { getUserInfoAsync } from '../../../../../store/user/asyncActions'
import { Colors } from '../../../../../constants'
import { Space } from '../../../../UiKit/Space'
import { TFunction } from 'i18next'
import * as Yup from 'yup'
import { ObjectSchema } from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateUserInfoAsync } from '../../../../../store/user/asyncActions'
import { UpdateUserInfo } from '../../../../../store/user/models/UpdateUserInfo'
import { Status } from 'models'
import { toast } from 'react-toastify'
import { returnErrorMessageContent } from 'utils/functions'

const MainContent: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectData)
  const status = useAppSelector(selectUpdateUserStatus)
  const error = useAppSelector(selectUserError)
  const [subscribed, setSubscribed] = useState<boolean>(userData.subscribed)

  useEffect(() => {
    if (status === Status.FAILED) {
      toast.error(returnErrorMessageContent(error)[0])
    }
    if (status === Status.SUCCEEDED) {
      dispatch(getUserInfoAsync())
      toast.success(t('infoIsUpdated'))
    }
    return () => {
      dispatch(deleteError())
    }
  }, [error, status, dispatch, t])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserInfo>({
    defaultValues: {
      address: userData.address,
      city: userData.city,
      email: userData.email,
      zip_code: userData.zip_code,
      phone: userData.phone,
    },
    resolver: yupResolver(updateUserInfoSchema(t)),
  })

  const onSubmit = handleSubmit(async (data: UpdateUserInfo) => {
    let newData = {
      ...data,
      phone: data.phone || null,
      subscribed: subscribed,
    }

    if (!!errors) {
      await dispatch(updateUserInfoAsync(newData))
    }
  })
  return (
    <div className={'vertical justify-content-start'}>
      <div className={'item flex-none'}>
        <Input readOnly={true} defaultValue={userData.firstname + ' ' + userData.lastname} name={t('name')} />
      </div>
      <div className={'item flex-none'}>
        <Input name={t('address')} {...register('address')} control={control} />
      </div>
      <div className={'item flex-none'}>
        <Input control={control} name={t('city')} {...register('city')} />
      </div>
      <div className={'item flex-none'}>
        <Input control={control} name={'postalCode'} {...register('zip_code')} />
      </div>
      <div className={'item flex-none'}>
        <Input control={control} name={'email'} {...register('email')} />
      </div>
      <div className={'item flex-none'}>
        <Input control={control} name={t('phoneNumber')} type="phone" {...register('phone')} />
      </div>
      <div className={'item flex-none'}>
        <UseAddressCheckbox />
      </div>
      <div className={'item flex-none'}>
        <NewsLetterCheckbox value={subscribed} changeAction={() => setSubscribed(!subscribed)} />
      </div>
      <Space height={50} />
      <div className={'item flex-none'}>
        <Button bgColor={Colors.FRENCH_ROSE} text={t('saveSelection')} onClick={onSubmit} />
      </div>
    </div>
  )
}

export default MainContent

const updateUserInfoSchema = (t: TFunction): ObjectSchema<ObjectShape> =>
  Yup.object().shape({
    address: Yup.string().required(t('required')),
    city: Yup.string().required(t('required')),
    email: Yup.string().required(t('required')),
    zip_code: Yup.string().required(t('required')),
    phone: Yup.string(),
  })

interface UserInfo {
  address: string
  city: string
  email: string
  zip_code: string
  phone: string
  subscribed?: boolean
}
