import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'

export type AddRatingBodyModel = {
  requestBody: {
    rating_id: number
    comment: string
    rating: number
  }
}

export type DeleteCommentBodyModel = {
  ratingId: number
  requestBody: {
    note: string
  }
}

export const addCommentAsync: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  '/web/rating/comment',
  async ({ requestBody }: AddRatingBodyModel, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webRatingUpdateComment(requestBody.rating_id, requestBody)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const deleteCommentAsync: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  'web/rating/deleteComment',
  async ({ ratingId, requestBody }: DeleteCommentBodyModel, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webRatingDeleteComment(ratingId, requestBody)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
