import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../index'
import { addCommentAsync, deleteCommentAsync } from './asyncActions'
import { Status } from 'models'

export interface commentState {
  add_comment_status: Status
  delete_comment_status: Status
  add_comment_error: string | any
  delete_comment_error: string | any
}

const initialState: commentState = {
  add_comment_error: null,
  delete_comment_error: null,
  add_comment_status: Status.IDLE,
  delete_comment_status: Status.IDLE,
}

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(addCommentAsync.pending, state => {
        state.add_comment_error = null
        state.add_comment_status = Status.PENDING
      })
      .addCase(addCommentAsync.fulfilled, state => {
        state.add_comment_status = Status.SUCCEEDED
      })
      .addCase(addCommentAsync.rejected, (state, action) => {
        state.add_comment_status = Status.FAILED
        state.add_comment_error = action.payload ?? 'Unknown Error'
      })
    builder
      .addCase(deleteCommentAsync.pending, state => {
        state.delete_comment_error = null
        state.delete_comment_status = Status.PENDING
      })
      .addCase(deleteCommentAsync.fulfilled, state => {
        state.delete_comment_status = Status.SUCCEEDED
      })
      .addCase(deleteCommentAsync.rejected, (state, action) => {
        state.delete_comment_status = Status.FAILED
        state.delete_comment_error = action.payload ?? 'Unknown Error'
      })
  },
})

export const selectAddCommentsStatus = (state: RootState): commentState['add_comment_status'] =>
  state.visits.comment.add_comment_status
export const selectDeleteCommentsStatus = (state: RootState): commentState['delete_comment_status'] =>
  state.visits.comment.delete_comment_status

export default commentsSlice.reducer
