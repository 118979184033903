import { createContext, FunctionComponent, useState } from 'react'
import { Header } from 'Components/UiKit/Header'
import { Footer } from 'Components/UiKit/Footer'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import InboxContainer from './Inbox/'
import MessageCardsWrapper from './Cards'
import ContactService from './ContactService'
import MobileMessage from './Inbox/MobileMessage'

export const Context = createContext(null)

const Buy: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const [value, setValue] = useState({
    current: 1,
    message: null,
    showMessage: false,
  })

  return (
    <Context.Provider value={{ value: value, setValue: setValue }}>
      <div className={'w-100 alice-blue-background-color'}>
        <BodyWrapper>
          {!value.showMessage ? (
            <>
              <Header title={t('messages')} subTitle={t('messagesSubTitle')} />
              <InboxContainer />
              <MessageCardsWrapper />
              <ContactService />
            </>
          ) : (
            <MobileMessage message={value.message} />
          )}
        </BodyWrapper>
        <div className={'item flex-none item-top-left'}>
          <Footer />
        </div>
      </div>
    </Context.Provider>
  )
}
export default Buy

const BodyWrapper = styled.div`
  padding-left: 60px;
  padding-right: 56px;
  width: 100%;
  @media (max-width: 700px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`
