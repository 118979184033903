import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../index'
import { updateRatingAsync, addRatingAsync } from './asyncActions'
import { Status } from 'models'

export interface RatingState {
  adding_status: Status
  updating_status: Status
  error: string | any
}

const initialState: RatingState = {
  error: null,
  adding_status: Status.IDLE,
  updating_status: Status.IDLE,
}

export const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(updateRatingAsync.pending, state => {
        state.error = null
        state.adding_status = Status.PENDING
      })
      .addCase(updateRatingAsync.fulfilled, (state, action) => {
        state.adding_status = Status.SUCCEEDED
      })
      .addCase(updateRatingAsync.rejected, (state, action) => {
        state.adding_status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
      .addCase(addRatingAsync.pending, state => {
        state.error = null
        state.updating_status = Status.PENDING
      })
      .addCase(addRatingAsync.fulfilled, (state, action) => {
        state.updating_status = Status.SUCCEEDED
      })
      .addCase(addRatingAsync.rejected, (state, action) => {
        state.updating_status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const selectAddRatingStatus = (state: RootState): RatingState['adding_status'] =>
  state.visits.rating.adding_status
export const selectUpdateRatingStatus = (state: RootState): RatingState['updating_status'] =>
  state.visits.rating.updating_status

export default ratingSlice.reducer
