import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import BuyCardWrapper from './BuyCardWrapper'
import { Colors } from 'constants/index'
// import tipAFriendIcon from 'assets/icons/tipAFriendIcon.png'
import makeGiftIcon from 'assets/icons/makeGiftIcon.png'
import { generateFieldUuid } from 'utils/functions'

const BuyCards: FunctionComponent = () => {
  const [t, { language }] = useTranslation<string>()

  return (
    <CardContainer>
      <BuyCardWrapper
        id={generateFieldUuid('buyGift', 'button')}
        title={t('museumCardGift')}
        message={t('surprisingWithGift')}
        btnLabel={t('buyGift')}
        onClick={() => window.open(`https://osta.museokortti.fi/gift/${language}`)}
        btnColor={Colors.FRENCH_ROSE}
        icon={makeGiftIcon}
      />
      {/* <BuyCardWrapper
        title={t('tipFriend')}
        message={t('getValueFromFriend')}
        btnLabel={t('tip')}
        onClick={() => null}
        btnColor={Colors.ROYAL_BLUE}
        icon={tipAFriendIcon}
      /> */}
    </CardContainer>
  )
}

export default BuyCards

const CardContainer = styled.div`
  display: flex;
  margin-top: 34px;
  margin-bottom: 36px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
`
