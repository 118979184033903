import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { getPriceAsync } from './asyncActions'
import { Status } from 'models'
import { priceResource } from './priceModel'

export interface PriceState {
  data: priceResource | null
  status: Status
  error: string | any
}

const initialState: PriceState = {
  data: null,
  error: null,
  status: Status.IDLE,
}

export const priceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    reset: state => {
      state = initialState
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getPriceAsync.pending, state => {
        state.error = null
        state.status = Status.PENDING
      })
      .addCase(getPriceAsync.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = Status.SUCCEEDED
      })
      .addCase(getPriceAsync.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const { reset } = priceSlice.actions

export const selectPriceData = (state: RootState): PriceState['data'] => state.price.data
export const selectPriceError = (state: RootState): PriceState['error'] => state.price.error
export const selectPriceStatus = (state: RootState): PriceState['status'] => state.price.status

export default priceSlice.reducer
