import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { getElement } from './asyncActions'
import { Status } from 'models'
import { ElementResource } from './elementModel'

export interface ElementState {
  data: Array<ElementResource>
  status: Status
  error: string | any
}

const initialState: ElementState = {
  data: null,
  error: null,
  status: Status.IDLE,
}

export const elementSlice = createSlice({
  name: 'element',
  initialState,
  reducers: {
    reset: (state: ElementState) => {
      state = initialState
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getElement.pending, state => {
        state.error = null
        state.status = Status.PENDING
      })
      .addCase(getElement.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = Status.SUCCEEDED
      })
      .addCase(getElement.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const { reset } = elementSlice.actions

export const selectElementData = (state: RootState): ElementState['data'] => state.element.data
export const selectElementError = (state: RootState): ElementState['error'] => state.element.error
export const selectElementStatus = (state: RootState): ElementState['status'] => state.element.status

export default elementSlice.reducer
