const Collectorb2c = (props: any) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1500 644"
      width="130"
      height="40"
      {...props}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M423.5,131.2V274l-144.9-72.3c-6.2-3.1-13.8-3.1-20,0L113.7,274V131.2H67H0c-0.5,0-0.7,0.7-0.3,1L67,171.5v142.6
		c0,13.5,16.7,22,30.1,15.3l171.5-85.6l171.5,85.6c13.4,6.7,30.1-1.8,30.1-15.3V131.2H423.5z"
        />
        <path
          d="M764.7,232.4v99.2h-48.6v-24.5c-37.7,17.5-79,30.1-126.3,30.1c-58.9,0-91.6-20.2-91.6-65.5c0-51.2,54.9-67.8,116.4-67.8
		c35.7,0,70.8,6,101.5,14.5c-1.3-42.7-52.9-48.3-82-48.3c-28.4,0-61.5,3.3-105.5,16.9l-14.2-36.7c40.7-11.9,80-19.2,126-19.2
		C722.3,131.2,764,156.7,764.7,232.4L764.7,232.4z M716.1,274v-19.5c-26.8-7.3-58.2-13.6-97.2-13.6c-42.7,0-68.8,8.3-68.8,29.4
		c0,22.8,20.8,27.4,54.9,27.4C635,297.8,683.3,285.6,716.1,274L716.1,274z"
        />
        <path d="M794.2,69.5h48.2v262.2h-48.2V69.5z" />
        <path d="M873.6,69.5h48.2v262.2h-48.2V69.5z" />
        <path
          d="M1162.4,265.3h50.5c-11.8,44.9-56.4,71.5-128.2,71.5c-80.6,0-136.4-33.1-136.4-102.6s58-102.6,133.4-102.6
		c76.4,0,134.1,29.2,134.1,113.1H999.5c4.6,37.4,34.7,52.1,86.2,52.1C1132.6,296.8,1153.2,284,1162.4,265.3L1162.4,265.3z
		 M1002.1,210.6h160.3c-8.5-28.8-36.1-41.3-80.6-41.3C1039.8,169.3,1011.3,181.4,1002.1,210.6L1002.1,210.6z"
        />
        <path
          d="M1445.8,131.6h54.8l-117.2,213.3c-25.7,47.8-51,75.4-102.8,75.4c-25.7,0-46.6-3.4-68.2-7.8v-42.3
		c25.7,5.8,44.5,7.5,59.3,7.5c29.1,0,44.2-8.5,62.7-43.3l1.4-2.7h-26.7l-109.6-200.1h55.5l95.2,174L1445.8,131.6z"
        />
      </g>
      <g>
        <path d="M66.6,567.4V467.2h13.3v88.4h49.4v11.8H66.6z" />
        <path
          d="M139.6,567.4l38.5-100.2h14.3l41,100.2h-15.1L206.6,537h-41.9l-11,30.4H139.6z M168.5,526.2h34L192,498.5
		c-3.2-8.4-5.6-15.4-7.1-20.8c-1.3,6.4-3.1,12.8-5.4,19.1L168.5,526.2z"
        />
        <path
          d="M239.5,535.2l12.5-1.1c0.6,5,2,9.1,4.1,12.3c2.2,3.2,5.5,5.8,10.1,7.8c4.6,2,9.7,3,15.4,3c5.1,0,9.5-0.8,13.4-2.3
		c3.9-1.5,6.8-3.6,8.6-6.2c1.9-2.6,2.8-5.5,2.8-8.6c0-3.1-0.9-5.9-2.7-8.2c-1.8-2.3-4.8-4.3-9-5.9c-2.7-1-8.6-2.7-17.8-4.9
		c-9.2-2.2-15.7-4.3-19.3-6.3c-4.8-2.5-8.4-5.6-10.7-9.3c-2.3-3.7-3.5-7.9-3.5-12.5c0-5.1,1.4-9.8,4.3-14.2c2.9-4.4,7.1-7.7,12.6-10
		c5.5-2.3,11.6-3.4,18.4-3.4c7.4,0,14,1.2,19.7,3.6s10,5.9,13.1,10.6c3.1,4.6,4.7,9.9,4.9,15.8l-12.7,1c-0.7-6.3-3-11.1-6.9-14.4
		c-3.9-3.2-9.8-4.9-17.5-4.9c-8,0-13.9,1.5-17.5,4.4c-3.7,2.9-5.5,6.5-5.5,10.6c0,3.6,1.3,6.6,3.9,8.9c2.6,2.3,9.2,4.7,20,7.1
		c10.8,2.4,18.2,4.6,22.2,6.4c5.8,2.7,10.1,6.1,12.9,10.2c2.8,4.1,4.2,8.9,4.2,14.3c0,5.3-1.5,10.4-4.6,15.1
		c-3.1,4.7-7.4,8.4-13.2,11c-5.7,2.6-12.2,3.9-19.3,3.9c-9.1,0-16.7-1.3-22.8-4c-6.1-2.6-10.9-6.6-14.4-11.9
		C241.5,547.9,239.7,541.9,239.5,535.2z"
        />
        <path d="M336.8,567.4V467.2h13.3v49.7l49.8-49.7h18l-42,40.6l43.9,59.6h-17.5l-35.7-50.7l-16.4,16v34.7H336.8z" />
        <path
          d="M496.5,467.2h13.3v57.9c0,10.1-1.1,18.1-3.4,24c-2.3,5.9-6.4,10.7-12.3,14.5c-5.9,3.7-13.8,5.6-23.4,5.6
		c-9.4,0-17.1-1.6-23-4.9c-6-3.2-10.2-7.9-12.8-14c-2.6-6.1-3.8-14.5-3.8-25.1v-57.9h13.3V525c0,8.7,0.8,15.1,2.4,19.2
		c1.6,4.1,4.4,7.3,8.3,9.5c3.9,2.2,8.8,3.3,14.5,3.3c9.8,0,16.7-2.2,20.8-6.6c4.1-4.4,6.2-12.9,6.2-25.5V467.2z"
        />
        <path d="M562.7,569.1l29.1-103.6h9.8l-29,103.6H562.7z" />
        <path
          d="M652.9,518.6c0-16.6,4.5-29.7,13.4-39.1c8.9-9.4,20.5-14.1,34.6-14.1c9.3,0,17.6,2.2,25,6.6c7.4,4.4,13.1,10.6,17,18.5
		c3.9,7.9,5.8,16.9,5.8,26.9c0,10.2-2.1,19.3-6.2,27.3c-4.1,8-9.9,14.1-17.4,18.2c-7.5,4.1-15.6,6.2-24.3,6.2
		c-9.4,0-17.9-2.3-25.3-6.8c-7.4-4.6-13.1-10.8-16.9-18.7C654.8,535.7,652.9,527.4,652.9,518.6z M666.6,518.8
		c0,12.1,3.2,21.6,9.7,28.5c6.5,7,14.6,10.4,24.4,10.4c10,0,18.2-3.5,24.6-10.5c6.4-7,9.7-17,9.7-29.9c0-8.2-1.4-15.3-4.1-21.4
		c-2.8-6.1-6.8-10.8-12.1-14.2c-5.3-3.3-11.3-5-17.9-5c-9.4,0-17.5,3.2-24.2,9.7C670,492.9,666.6,503.7,666.6,518.8z"
        />
        <path
          d="M761.3,535.2l12.5-1.1c0.6,5,2,9.1,4.1,12.3c2.2,3.2,5.5,5.8,10.1,7.8c4.6,2,9.7,3,15.4,3c5.1,0,9.5-0.8,13.4-2.3
		c3.9-1.5,6.8-3.6,8.6-6.2c1.9-2.6,2.8-5.5,2.8-8.6c0-3.1-0.9-5.9-2.7-8.2c-1.8-2.3-4.8-4.3-9-5.9c-2.7-1-8.6-2.7-17.8-4.9
		c-9.2-2.2-15.7-4.3-19.3-6.3c-4.8-2.5-8.4-5.6-10.7-9.3c-2.3-3.7-3.5-7.9-3.5-12.5c0-5.1,1.4-9.8,4.3-14.2c2.9-4.4,7.1-7.7,12.6-10
		c5.5-2.3,11.6-3.4,18.4-3.4c7.4,0,14,1.2,19.7,3.6s10,5.9,13.1,10.6c3.1,4.6,4.7,9.9,4.9,15.8l-12.7,1c-0.7-6.3-3-11.1-6.9-14.4
		c-3.9-3.2-9.8-4.9-17.5-4.9c-8,0-13.9,1.5-17.5,4.4c-3.7,2.9-5.5,6.5-5.5,10.6c0,3.6,1.3,6.6,3.9,8.9c2.6,2.3,9.2,4.7,20,7.1
		c10.8,2.4,18.2,4.6,22.2,6.4c5.8,2.7,10.1,6.1,12.9,10.2c2.8,4.1,4.2,8.9,4.2,14.3c0,5.3-1.5,10.4-4.6,15.1
		c-3.1,4.7-7.4,8.4-13.2,11c-5.7,2.6-12.2,3.9-19.3,3.9c-9.1,0-16.7-1.3-22.8-4c-6.1-2.6-10.9-6.6-14.4-11.9
		C763.3,547.9,761.5,541.9,761.3,535.2z"
        />
        <path
          d="M848.2,567.4l38.5-100.2H901l41,100.2h-15.1L915.2,537h-41.9l-11,30.4H848.2z M877.1,526.2h34l-10.5-27.8
		c-3.2-8.4-5.6-15.4-7.1-20.8c-1.3,6.4-3.1,12.8-5.4,19.1L877.1,526.2z"
        />
        <path
          d="M952.2,567.4V467.2h20l23.7,71c2.2,6.6,3.8,11.6,4.8,14.8c1.1-3.6,2.9-9,5.3-16.1l24-69.7h17.8v100.2H1035v-83.9
		l-29.1,83.9h-12l-29-85.3v85.3H952.2z"
        />
        <path
          d="M1058.2,567.4l38.5-100.2h14.3l41,100.2h-15.1l-11.7-30.4h-41.9l-11,30.4H1058.2z M1087.1,526.2h34l-10.5-27.8
		c-3.2-8.4-5.6-15.4-7.1-20.8c-1.3,6.4-3.1,12.8-5.4,19.1L1087.1,526.2z"
        />
        <path d="M1162,567.4V467.2h13.3v49.7l49.8-49.7h18l-42,40.6l43.9,59.6h-17.5l-35.7-50.7l-16.4,16v34.7H1162z" />
        <path
          d="M1251.5,535.2l12.5-1.1c0.6,5,2,9.1,4.1,12.3c2.2,3.2,5.5,5.8,10.1,7.8c4.6,2,9.7,3,15.4,3c5.1,0,9.5-0.8,13.4-2.3
		c3.9-1.5,6.8-3.6,8.6-6.2c1.9-2.6,2.8-5.5,2.8-8.6c0-3.1-0.9-5.9-2.7-8.2c-1.8-2.3-4.8-4.3-9-5.9c-2.7-1-8.6-2.7-17.8-4.9
		c-9.2-2.2-15.7-4.3-19.3-6.3c-4.8-2.5-8.4-5.6-10.7-9.3c-2.3-3.7-3.5-7.9-3.5-12.5c0-5.1,1.4-9.8,4.3-14.2c2.9-4.4,7.1-7.7,12.6-10
		c5.5-2.3,11.6-3.4,18.4-3.4c7.4,0,14,1.2,19.7,3.6s10,5.9,13.1,10.6c3.1,4.6,4.7,9.9,4.9,15.8l-12.7,1c-0.7-6.3-3-11.1-6.9-14.4
		c-3.9-3.2-9.8-4.9-17.5-4.9c-8,0-13.9,1.5-17.5,4.4c-3.7,2.9-5.5,6.5-5.5,10.6c0,3.6,1.3,6.6,3.9,8.9c2.6,2.3,9.2,4.7,20,7.1
		c10.8,2.4,18.2,4.6,22.2,6.4c5.8,2.7,10.1,6.1,12.9,10.2c2.8,4.1,4.2,8.9,4.2,14.3c0,5.3-1.5,10.4-4.6,15.1
		c-3.1,4.7-7.4,8.4-13.2,11c-5.7,2.6-12.2,3.9-19.3,3.9c-9.1,0-16.7-1.3-22.8-4c-6.1-2.6-10.9-6.6-14.4-11.9
		C1253.5,547.9,1251.6,541.9,1251.5,535.2z"
        />
        <path
          d="M1415.1,467.2h13.3v57.9c0,10.1-1.1,18.1-3.4,24c-2.3,5.9-6.4,10.7-12.3,14.5c-5.9,3.7-13.8,5.6-23.4,5.6
		c-9.4,0-17.1-1.6-23-4.9c-6-3.2-10.2-7.9-12.8-14c-2.6-6.1-3.8-14.5-3.8-25.1v-57.9h13.3V525c0,8.7,0.8,15.1,2.4,19.2
		c1.6,4.1,4.4,7.3,8.3,9.5c3.9,2.2,8.8,3.3,14.5,3.3c9.8,0,16.7-2.2,20.8-6.6c4.1-4.4,6.2-12.9,6.2-25.5V467.2z"
        />
      </g>
    </svg>
  )
}

export default Collectorb2c
