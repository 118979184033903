// @flow
import * as React from 'react'
import { FunctionComponent } from 'react'

type Props = {
  height?: number
  width?: number
}

export const Space: FunctionComponent<Props> = ({ height = 0, width = 0 }: Props) => {
  return (
    <div className="item flex-none">
      <div className="space" style={{ height: height + 'px', width: width + 'px' }} />
    </div>
  )
}
