/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Code } from '../models/Code';
import type { CodeSheetResponse } from '../models/CodeSheetResponse';
import type { CompanyOrder } from '../models/CompanyOrder';
import type { Coupon } from '../models/Coupon';
import type { EventCalendarStatistics } from '../models/EventCalendarStatistics';
import type { MinimalMuseum } from '../models/MinimalMuseum';
import type { Offer } from '../models/Offer';
import type { PaymentForms } from '../models/PaymentForms';
import type { Rating } from '../models/Rating';
import type { RatingList } from '../models/RatingList';
import type { RegisterNewsLetterReceiver } from '../models/RegisterNewsLetterReceiver';
import type { UserPaymentCard } from '../models/UserPaymentCard';
import type { UserTransaction } from '../models/UserTransaction';
import { request as __request } from '../core/request';

export class WebService {

    /**
     * Create new user
     * create new user
     * @param requestBody create new user
     * @param lang records language
     * @returns any Created
     * @throws ApiError
     */
    public static async webUserCreate(
        requestBody: {
            code: number,
            firstname: string,
            lastname: string,
            birth_year?: number,
            address: string,
            city: string,
            email: string,
            zip_code: string,
            newsletter?: boolean,
            contact_language?: string,
            coupon_code?: string,
            password: string,
            plasticCard: boolean,
            phone?: string,
        },
        lang?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/user/create`,
            query: {
                'lang': lang,
            },
            body: requestBody,
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update user info
     * update user info
     * @param requestBody update user info
     * @returns any Ok
     * @throws ApiError
     */
    public static async webUserUpdateInfo(
        requestBody: {
            date_of_birth?: number,
            address: string,
            city: string,
            email: string,
            zip_code: string,
            phone?: string,
            subscribed: boolean,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/user/update`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Subscribe user for standing order
     * subscribe user for standing order
     * @param requestBody Subscribe user for standing order
     * @returns any Ok
     * @throws ApiError
     */
    public static async webUserSettingsCreate(
        requestBody: {
            user_id: number,
            auto_renewal: boolean,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/user/settings`,
            body: requestBody,
            errors: {
                401: `Unauthorized - Token expired`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update user subscription for standing order
     * Update user subscription for standing order
     * @param requestBody Update user subscription for standing order
     * @returns any Accepted
     * @throws ApiError
     */
    public static async webUserSettingsUpdate(
        requestBody: {
            user_id: number,
            auto_renewal: boolean,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/user/settings/update`,
            body: requestBody,
            errors: {
                401: `Unauthorized - Token expired`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Deactivate user auto_renewal/subscription
     * Deactivate user auto_renewal/subscription
     * @returns any Accepted
     * @throws ApiError
     */
    public static async webUserSettingUnsubscribe(): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/user/settings/unsubscribe`,
            errors: {
                401: `Unauthorized - Token expired`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Subscribe to newsletter
     * Subscribe newsletter
     * @returns any Accepted
     * @throws ApiError
     */
    public static async webUserNewsletterSubscribe(): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/user/newsletter/subscribe`,
            errors: {
                401: `Unauthorized - Token expired`,
            },
        });
        return result.body;
    }

    /**
     * Get list of transactions for user
     * Get list of transactions for user
     * @param orderBy Records ordering direction
     * @param pageSize Records count per page
     * @param page The number of page of the results
     * @returns any Success
     * @throws ApiError
     */
    public static async webUserTransactions(
        orderBy?: string,
        pageSize?: number,
        page?: number,
    ): Promise<{
        data?: Array<UserTransaction>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/user/transactions`,
            query: {
                'order_by': orderBy,
                'page_size': pageSize,
                'page': page,
            },
            errors: {
                401: `Unauthorized - Token expired`,
                404: `Not found`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get valid payment card for user
     * Get valid payment card for user
     * @returns any Success
     * @throws ApiError
     */
    public static async webUserPaymentCards(): Promise<{
        data?: Array<UserPaymentCard>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/user/payments/cards`,
            errors: {
                401: `Unauthorized - Token expired`,
                404: `Not found`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * user login
     * user login
     * @param requestBody Login
     * @param lang records language
     * @returns any Success
     * @throws ApiError
     */
    public static async webUserLogin(
        requestBody: {
            code: number,
            email?: string,
            password: string,
        },
        lang?: string,
    ): Promise<{
        token?: string,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/user/login`,
            query: {
                'lang': lang,
            },
            body: requestBody,
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                422: `Unprocessable Entity`,
                429: `Too many failed attempts. Please try again later.`,
            },
        });
        return result.body;
    }

    /**
     * Get User info
     * Get User info
     * @returns any Success
     * @throws ApiError
     */
    public static async webUserInfo(): Promise<{
        id?: number,
        created_at?: string,
        updated_at?: string,
        firstname?: string,
        lastname?: string,
        date_of_birth?: number,
        email?: string,
        phone?: string,
        address?: string,
        city?: string,
        zip_code?: string,
        country?: string,
        photo_updated_at?: string,
        photo?: string,
        role?: string,
        subscribed?: boolean,
        user_option?: string,
        reviews_count?: number,
        visits_count?: number,
        codes?: Array<{
            code?: number,
            user_id?: number,
            printing_museum_id?: number,
            first_museum_id?: number,
            first_visit?: string,
            expires_at?: string,
            valid_for?: number,
            codesheet?: number,
            created_at?: string,
            updated_at?: string,
            registered_at?: string,
            status?: string,
            deleted_at?: string,
            company_order_id?: number,
            visits_count?: number,
            sold_at?: string,
            seller_museum_id?: number,
            code_sheet_id?: number,
        }>,
        readonly valid_for_renewal?: boolean,
        readonly has_standing_order?: boolean,
        standing_order_date?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/user/info`,
            errors: {
                401: `Unauthorized - Token expired`,
            },
        });
        return result.body;
    }

    /**
     * admin authenticate
     * admin authenticate
     * @param requestBody Login
     * @returns any Success
     * @throws ApiError
     */
    public static async webAdminAuthenticate(
        requestBody: {
            email: string,
            password: string,
        },
    ): Promise<{
        token?: string,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/user/admin/authenticate`,
            body: requestBody,
            errors: {
                403: `Forbidden - Access denied`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * user registration via gift4you
     * user registration via gift4you
     * @param requestBody Register
     * @returns any Success - User is created/A new Code has generated and mailed to the User's email address
     * @throws ApiError
     */
    public static async webAdminGift4YouRegister(
        requestBody: {
            firstname: string,
            lastname: string,
            birth_year: number,
            address: string,
            city: string,
            email: string,
            zip_code: string,
            newsletter: boolean,
            contact_language?: string,
            coupon_code?: string,
            password: string,
            plasticCard: boolean,
            gift4you_code: string,
            phone_number?: string,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/user/gift4you/register`,
            body: requestBody,
            errors: {
                400: `Failure - Gift4You code can not be redeemed, possible codes:  0=NOT_FOUND, 2=ALREADY_REDEEMED, 3=PRODUCT_VOIDED`,
                422: `Failure - User is not created due to validation errors`,
            },
        });
        return result.body;
    }

    /**
     * check gift4you code
     * check gift4you code
     * @param requestBody Check
     * @returns any Success - Check the response body
     * @throws ApiError
     */
    public static async webAdminGift4YouCheck(
        requestBody: {
            gift4you_code: string,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/user/gift4you/check`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                422: `Failure - Code is not valid to be used in registration API`,
            },
        });
        return result.body;
    }

    /**
     * user deactivate request
     * user deactivate request
     * @returns any Success
     * @throws ApiError
     */
    public static async webUserDeactivate(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/user/deactivate`,
            errors: {
                400: `Failed to revoke bearer token`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Renew code
     * renew code
     * @param requestBody Renew code request parameters
     * @returns any Code renewed
     * @throws ApiError
     */
    public static async webCodeRenew(
        requestBody: {
            code?: number,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/code`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden - Access denied`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Generate new codes sheet
     * Generate new codes sheet
     * @returns any Success
     * @throws ApiError
     */
    public static async webCodeGenerateCodeSheet(): Promise<{
        data?: Array<CodeSheetResponse>,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/code/code-sheet`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Logout
     * Logout
     * @returns void
     * @throws ApiError
     */
    public static async webAuthLogout(): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/web/auth/logout`,
            errors: {
                400: `Failed to revoke access token`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Logout
     * Logout
     * @returns void
     * @throws ApiError
     */
    public static async webAuthSecretaryLogout(): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/web/auth/secretary/logout`,
            errors: {
                400: `Failed to revoke access token`,
                401: `Unauthorized - Token expired`,
            },
        });
        return result.body;
    }

    /**
     * Get login page for dashboard statistics
     * Get login page for dashboard statistics
     * @returns void
     * @throws ApiError
     */
    public static async webAhtSecretary(): Promise<void> {
        const result = await __request({
            method: 'GET',
            path: `/web/auth/secretary`,
        });
        return result.body;
    }

    /**
     * Authenticate user to view dashboard statistics
     * Authenticate user to view dashboard statistics
     * @param requestBody Login
     * @returns any Ok
     * @throws ApiError
     */
    public static async webAhtSecretaryLogin(
        requestBody: {
            email: string,
            password: string,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/auth/secretary/login`,
            body: requestBody,
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Mark code as sold
     * Mark code as sold
     * @param requestBody Mark code as sold
     * @returns any Ok
     * @throws ApiError
     */
    public static async webCodeMarkSold(
        requestBody: {
            code: number,
            museum_id: number,
            source: string,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/code/mark-sold`,
            body: requestBody,
            errors: {
                401: `Unauthorized - Token expired`,
                403: `Forbidden - Access denied`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Cancel code sale
     * @param requestBody Cancel code sale
     * @returns any Accepted
     * @throws ApiError
     */
    public static async webCodeCancelSale(
        requestBody: {
            code: number,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/code/cancel-sale`,
            body: requestBody,
            errors: {
                403: `if code has visits OR is registered OR is merged OR status != sold OR is renewed`,
                422: `Code must exist`,
            },
        });
        return result.body;
    }

    /**
     * get a list of codes
     * get a list of codes
     * @param query search text
     * @param orderBy Records ordering direction
     * @param pageSize Records count per page
     * @param page The number of page of the results
     * @returns any Success
     * @throws ApiError
     */
    public static async webCodeSearch(
        query?: string,
        orderBy?: string,
        pageSize?: number,
        page?: number,
    ): Promise<{
        data?: Array<Code>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/code/search`,
            query: {
                'query': query,
                'order_by': orderBy,
                'page_size': pageSize,
                'page': page,
            },
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * view GroupMessage of a User
     * view GroupMessage of a User
     * @param query group message id
     * @param token user token
     * @returns string Success
     * @throws ApiError
     */
    public static async webGroupMessageView(
        query: number,
        token: string,
    ): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/web/group-message`,
            query: {
                'query': query,
                'token': token,
            },
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * register NewsLetterReceiver
     * register NewsLetterReceiver
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static async webNewsLetterReceiverRegister(
        requestBody: RegisterNewsLetterReceiver,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/news-letter-receiver`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Merge 2 codes
     * @param requestBody Renew or extend validity period for original code by combining with gift code, If museum_id not present in request body user's lastname will be used
     * @param lang records language
     * @returns any Code data received
     * @throws ApiError
     */
    public static async webCodeMerge(
        requestBody: {
            /**
             * If not present in request body, user's lastname will be used
             */
            museum_id?: number,
            source?: string,
            code: number,
            gift_code: number,
        },
        lang?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/code/merge`,
            query: {
                'lang': lang,
            },
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `1. 'exception' (belongs to excepted museums list, cannot be used for merging) 2. 'used' (registered or has visits, cannot be used for merging) 3. 'failed' (failed to update original code/ send email) 4. 'merged' (gift code is already merged) 5. 'unsold' (card was not marked as sold)`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get/Check code detail
     * @param code code of museum
     * @param lang records language
     * @returns any Check Code resource
     * @throws ApiError
     */
    public static async webCodeGet(
        code: number,
        lang?: string,
    ): Promise<{
        readonly code?: number,
        readonly registered?: boolean,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/code/${code}`,
            query: {
                'lang': lang,
            },
            errors: {
                403: `Forbidden - Access denied`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Validate code
     * @param requestBody
     * @returns any Access code resource
     * @throws ApiError
     */
    public static async webCodeValidate(
        requestBody: {
            code: number,
            museum_id: number,
        },
    ): Promise<{
        readonly code?: number,
        readonly is_registered?: boolean,
        readonly customer_name?: string,
        readonly sold_at?: string,
        readonly seller_museum_id?: number,
        readonly expires_at?: string,
        readonly days_left?: number,
        readonly merged_status?: string,
        readonly original_code?: string,
        readonly free?: string,
        readonly regular?: string,
        readonly auto_renew?: boolean,
        readonly to_be_renewed_at?: string,
        readonly free_visits_only?: boolean,
        readonly valid_for_renewal?: boolean,
        readonly can_be_sold?: boolean,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/code/validate`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not found`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create code
     * @returns any Access code resource
     * @throws ApiError
     */
    public static async webCodeNew(): Promise<{
        readonly code?: number,
        readonly is_registered?: boolean,
        readonly customer_name?: string,
        readonly sold_at?: string,
        readonly seller_museum_id?: number,
        readonly expires_at?: string,
        readonly days_left?: number,
        readonly merged_status?: string,
        readonly original_code?: string,
        readonly free?: string,
        readonly regular?: string,
        readonly auto_renew?: boolean,
        readonly to_be_renewed_at?: string,
        readonly free_visits_only?: boolean,
        readonly valid_for_renewal?: boolean,
        readonly can_be_sold?: boolean,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/code/new`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get code extended details
     * @param requestBody
     * @returns any Code extended resource
     * @throws ApiError
     */
    public static async webCodeExtendedDetails(
        requestBody: {
            code: number,
        },
    ): Promise<{
        readonly code?: number,
        readonly printing_museum_id?: number,
        readonly user_id?: number,
        readonly first_museum_id?: number,
        readonly first_visit?: number,
        readonly expires_at?: string,
        readonly valid_for?: number,
        readonly valid_for_renewal?: boolean,
        readonly can_be_sold?: boolean,
        readonly codesheet?: number,
        readonly status?: string,
        readonly created_at?: string,
        readonly updated_at?: string,
        readonly registered_at?: string,
        readonly sold_at?: string,
        readonly visits_free?: number,
        readonly visits_regular?: number,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/code/details`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * user newsletter subscription
     * user newsletter subscription
     * @param token user token
     * @returns any Success
     * @throws ApiError
     */
    public static async userNewsletterSubscription(
        token: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/user/newsletter`,
            query: {
                'token': token,
            },
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Mark visit for the code
     * @param requestBody Request for creating new visit
     * @returns any Visit has been marked for the code
     * @throws ApiError
     */
    public static async webVisitCreate(
        requestBody: {
            code: number,
            info: string,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/visit`,
            body: requestBody,
            errors: {
                403: `Cannot create visit`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * get visit count
     * @returns any Visit count
     * @throws ApiError
     */
    public static async webVisitCount(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/visit/count`,
            errors: {
                401: `Unauthorized - Token expired`,
            },
        });
        return result.body;
    }

    /**
     * Create rating
     * @param requestBody create rating
     * @returns Rating Success
     * @throws ApiError
     */
    public static async webRatingCreate(
        requestBody: {
            user_id?: number,
            visit_id?: number,
            museum_id?: number,
            rating?: number,
        },
    ): Promise<Rating> {
        const result = await __request({
            method: 'POST',
            path: `/web/rating`,
            body: requestBody,
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List rating of a Museum
     * @param museumId id of museum
     * @param pageSize Records count per page
     * @param page The number of page of the results
     * @returns any Success
     * @throws ApiError
     */
    public static async webRatingListByMuseums(
        museumId: number,
        pageSize?: number,
        page?: number,
    ): Promise<{
        data?: Array<RatingList>,
        links?: {
            first?: string,
            last?: string,
            prev?: string,
            next?: string,
        },
        meta?: {
            current_page?: number,
            from?: number,
            last_page?: number,
            path?: string,
            per_page?: number,
            to?: number,
            total?: number,
        },
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/rating/${museumId}`,
            query: {
                'page_size': pageSize,
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * Download rating of a Museum
     * @param museumId id of museum
     * @returns any Success
     * @throws ApiError
     */
    public static async webRatingDownload(
        museumId: number,
    ): Promise<{
        download_link?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/rating/${museumId}/download`,
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * update rating
     * @param ratingId id of rating
     * @param requestBody update rating
     * @returns any Ok
     * @throws ApiError
     */
    public static async webRatingUpdate(
        ratingId: number,
        requestBody: {
            rating: number,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/rating/${ratingId}`,
            body: requestBody,
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * update rating comment
     * @param ratingId id of rating
     * @param requestBody update rating
     * @returns any Ok
     * @throws ApiError
     */
    public static async webRatingUpdateComment(
        ratingId: number,
        requestBody: {
            rating: number,
            comment: string,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/rating/${ratingId}/comment`,
            body: requestBody,
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * delete rating comment
     * @param ratingId id of rating
     * @param requestBody delete rating comment
     * @returns any Ok
     * @throws ApiError
     */
    public static async webRatingDeleteComment(
        ratingId: number,
        requestBody: {
            note: string,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'PATCH',
            path: `/web/rating/${ratingId}/comment`,
            body: requestBody,
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * update rating visibility
     * @param ratingId id of rating
     * @param requestBody update rating visibility
     * @returns any Ok
     * @throws ApiError
     */
    public static async webRatingUpdateVisibility(
        ratingId: number,
        requestBody: {
            visibility: boolean,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'PATCH',
            path: `/web/rating/${ratingId}/visibility`,
            body: requestBody,
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * update transaction
     * @param transactionId id of transaction
     * @param requestBody update transaction
     * @returns any Accepted
     * @throws ApiError
     */
    public static async webTransactionUpdate(
        transactionId: number,
        requestBody: {
            status?: string,
            filing_code?: string,
            method?: string,
            customerInfo?: string,
            auto_renewal?: boolean,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'PATCH',
            path: `/web/transaction/${transactionId}`,
            body: requestBody,
            errors: {
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * user visits
     * user visits
     * @param orderBy Records ordering direction
     * @param pageSize Records count per page
     * @param page The number of page of the results
     * @returns any Success
     * @throws ApiError
     */
    public static async userVisits(
        orderBy?: string,
        pageSize?: number,
        page?: number,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/user/visits`,
            query: {
                'order_by': orderBy,
                'page_size': pageSize,
                'page': page,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * get receipt info
     * @param filingCode filing_code of transaction
     * @returns any Success
     * @throws ApiError
     */
    public static async webTransactionReceiptInfo(
        filingCode: string,
    ): Promise<{
        code?: string,
        name?: string,
        date?: string,
        invoice_number?: number,
        phone?: string,
        street?: string,
        city?: string,
        zipcode?: string,
        price?: number,
        type?: string,
        buyer?: string,
        receivers?: string,
        total?: number,
        amount?: number,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/transaction/receipt-info`,
            query: {
                'filing_code': filingCode,
            },
            errors: {
                403: `Forbidden - Transaction is not paid`,
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * set user password with token sent with email
     * user password
     * @param requestBody Set password
     * @param lang records language
     * @returns any
     * @throws ApiError
     */
    public static async webUserPassword(
        requestBody: {
            token: string,
            password: string,
            password_confirmation?: string,
        },
        lang?: string,
    ): Promise<{
        message?: string,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/user/password`,
            query: {
                'lang': lang,
            },
            body: requestBody,
            errors: {
                403: `Forbidden - Token expired`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Reset user password via email
     * user password reset
     * @param requestBody Reset password
     * @returns any Success
     * @throws ApiError
     */
    public static async webUserPasswordReset(
        requestBody: {
            email: string,
            code: string,
            lang?: string,
        },
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/user/password/reset`,
            body: requestBody,
            errors: {
                400: `Failure - If Email was used in multiple User records`,
                422: `Unprocessable Entity`,
                503: `Service Unavailable`,
            },
        });
        return result.body;
    }

    /**
     * get museum
     * @returns any Success
     * @throws ApiError
     */
    public static async webMuseumGet(): Promise<{
        readonly id?: number,
        readonly event_calendar_id?: number,
        readonly name?: string,
        address?: {
            readonly street?: string,
            readonly zip_code?: string,
            readonly city?: string,
        },
        readonly email?: string,
        readonly contact?: string,
        pin_code?: string,
        free_museum?: boolean,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/museum`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * get museum minimal list
     * @returns MinimalMuseum Museum minimal resource
     * @throws ApiError
     */
    public static async webMuseumGetMinimal(): Promise<Array<MinimalMuseum>> {
        const result = await __request({
            method: 'GET',
            path: `/web/museum/minimal`,
        });
        return result.body;
    }

    /**
     * get exhibition list
     * @param page The number of page of the results
     * @returns any exhibition list
     * @throws ApiError
     */
    public static async webMuseumExhibitionList(
        page?: number,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/museum/exhibition`,
            query: {
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * get museum
     * @param id id of the of record
     * @returns any Museum resource
     * @throws ApiError
     */
    public static async webMuseumGetInfo(
        id: number,
    ): Promise<{
        readonly id?: number,
        readonly event_calendar_id?: number,
        readonly name?: string,
        address?: {
            readonly street?: string,
            readonly zip_code?: string,
            readonly city?: string,
        },
        readonly email?: string,
        readonly contact?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/museum/${id}/info`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * get museum average rating
     * @param id id of the of record
     * @returns any Museum average rating
     * @throws ApiError
     */
    public static async webMuseumGetAverageRating(
        id: number,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/museum/${id}/average-rating`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * verify museum pin code
     * @param pinCode pin of museum
     * @returns any Museum average rating
     * @throws ApiError
     */
    public static async webMuseumVerifyPinCode(
        pinCode: number,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/museum/${pinCode}/verify`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * get museum qr
     * @returns any Museum QR
     * @throws ApiError
     */
    public static async webMuseumGetQr(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/museum/qr`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * update museum qr
     * @returns any Museum QR
     * @throws ApiError
     */
    public static async webMuseumUpdateQr(): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/museum/qr`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * get museums tokens
     * @returns any tokens list
     * @throws ApiError
     */
    public static async webMuseumsGetTokens(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/museum/token`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden - Access denied`,
            },
        });
        return result.body;
    }

    /**
     * get announcement for customer portal
     * get announcement for customer portal
     * @returns any Success
     * @throws ApiError
     */
    public static async webAnnouncementOffers(): Promise<{
        items?: Offer,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/announcement/offers`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * get news for customer portal
     * get news for customer portal
     * @returns any Success
     * @throws ApiError
     */
    public static async webAnnouncementNews(): Promise<{
        items?: Offer,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/announcement/news`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * validate coupon code
     * check if coupon code is valid
     * @param requestBody Check Coupon
     * @param lang
     * @returns any Success
     * @throws ApiError
     */
    public static async webCouponCheck(
        requestBody: {
            coupon_code: string,
            /**
             * Source of request, can be any value of (personal, registration, customer_portal)
             */
            from: string,
        },
        lang?: string,
    ): Promise<{
        items?: Coupon,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/coupon/check`,
            query: {
                'lang': lang,
            },
            body: requestBody,
            errors: {
                429: `Too many failed attempts. Please try again later.`,
            },
        });
        return result.body;
    }

    /**
     * Get event calendar statistics
     * Get statistics for specific museum in event calendar
     * @returns any Success
     * @throws ApiError
     */
    public static async webStatisticsEventCalendar(): Promise<{
        items?: EventCalendarStatistics,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/statistics/event_calendar`,
        });
        return result.body;
    }

    /**
     * Get dashboard statistics
     * Get dashboard statistics
     * @returns any Success response with view containing tables of statistics
     * @throws ApiError
     */
    public static async webUserStatisticsDashboard(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/statistics/dashboard`,
        });
        return result.body;
    }

    /**
     * Create new company Order
     * Create new company Order
     * @param requestBody Create new company order
     * @returns CompanyOrder Success
     * @throws ApiError
     */
    public static async webCompanyOrder(
        requestBody: {
            amount: number,
            company: string,
            /**
             * send company order as paper cards or digitally (email and/or sms). value can be either letter or digital
             */
            delivery_type: string,
            contact_person: string,
            contact_email: string,
            contact_phone: string,
            /**
             * required only if delivery_type = letter
             */
            recipient: string,
            /**
             * required only if delivery_type = letter
             */
            address: string,
            /**
             * required only if delivery_type = letter
             */
            zip: string,
            /**
             * required only if delivery_type = letter
             */
            city: string,
            company_on_card?: string,
            business_code?: string,
            partner_museum_id?: string,
            partner_contact?: string,
            invoicing_company?: string,
            invoicing_address?: string,
            invoicing_zip?: string,
            invoicing_city?: string,
            invoice_reference?: string,
            einvoice_address?: string,
            einvoice_operator?: string,
            einvoice_broker_id?: string,
        },
    ): Promise<CompanyOrder> {
        const result = await __request({
            method: 'POST',
            path: `/web/companyOrder`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get Company Order by identifier
     * Get Company Order by identifier
     * @param identifier Identifier for company order
     * @returns any Success
     * @throws ApiError
     */
    public static async webCompanyOrderPdf(
        identifier: string,
    ): Promise<{
        readonly url?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/companyOrder/pdf/${identifier}`,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Generate Payment Forms
     * Generate Payment Forms
     * @param requestBody Generate forms for different payment providers
     * @param lang records language
     * @returns any Success
     * @throws ApiError
     */
    public static async webPaymentForms(
        requestBody: {
            /**
             * type of payment, should be any value form (add_card, personal, renew, or gift)
             */
            type: string,
            /**
             * source of payment, should be either web_shop, or customer_portal
             */
            source: string,
            /**
             * required if type any of (personal, renew, or gift)
             */
            amount: number,
            /**
             * should be any value from (production, staging, or local) based on projects environment
             */
            environment: string,
            contact_lang: string,
            auto_renewal: boolean,
            plastic_card: boolean,
            /**
             * required only if type is personal
             */
            customer_first_name?: string,
            /**
             * required only if type is personal
             */
            customer_last_name?: string,
            /**
             * required only if type is personal
             */
            customer_email?: string,
            /**
             * required only if type is personal
             */
            customer_phone?: string,
            /**
             * required only if type is personal
             */
            customer_address?: string,
            /**
             * required only if type is personal
             */
            customer_zipcode?: string,
            /**
             * required only if type is personal
             */
            customer_city?: string,
            /**
             * required only if type is personal
             */
            customer_date_of_birth?: string,
            /**
             * required only if type is personal
             */
            newsletter?: string,
            /**
             * required only if type is personal
             */
            password?: string,
            /**
             * required only if type is personal
             */
            password_confirmation?: string,
            /**
             * required only if payment type is gift
             */
            gift_type?: string,
            /**
             * required only if payment type is gift
             */
            gift_buyer_name?: string,
            /**
             * required only if payment type is gift
             */
            gift_buyer_email?: string,
            /**
             * not required but can be sent if added by customer and payment type is gift
             */
            gift_buyer_phone?: string,
            /**
             * required only if payment type is gift
             */
            gift_greetings_message?: string,
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_first_name?: string,
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_last_name?: string,
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_address?: string,
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_zip_code?: string,
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_city?: string,
            /**
             * not required but can be sent if added by customer and payment type is gift and gift type is letter
             */
            gift_receiver_organisation?: string,
            /**
             * required only if payment type is gift and gift type is digital
             */
            gift_send_at?: string,
            /**
             * required only if payment type is gift and gift type is digital, should contain array of arrays, each array is for gift receiver, and each should include receiver_name
             */
            gift_info?: Array<Array<{
                receiver_name?: string,
            }>>,
            /**
             * required only if payment type is renew or add_card
             */
            user_id?: number,
            /**
             * required only if payment type is renew
             */
            renewal_type?: string,
            coupon_code?: string,
        },
        lang?: string,
    ): Promise<{
        items?: PaymentForms,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/payment/forms`,
            query: {
                'lang': lang,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Handle response from payment service providers
     * Handle response from payment service providers
     * @param requestBody Handle response from payment service providers
     * @returns any Successful Payment Response
     * @throws ApiError
     */
    public static async webPaymentSuccess(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/payment/success`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Handle Paytrail Notifications
     * Handle Paytrail Notifications
     * @param requestBody Handle notification about possible issues related to paytrail payment
     * @returns any Success
     * @throws ApiError
     */
    public static async webPaymentNotify(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/payment/paytrail/notify`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Add new payment card
     * Add new payment card
     * @param requestBody Add new payment card
     * @returns any Created
     * @throws ApiError
     */
    public static async webPaymentCard(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/payment/card`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get price for new card
     * Get price for new card
     * @returns any New card price resource - success response
     * @throws ApiError
     */
    public static async webPriceCard(): Promise<{
        data?: Array<{
            readonly cents?: number,
            readonly type?: string,
            readonly started_at?: string,
        }>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/price/card`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * Get prices for web shop
     * Get prices for web shop
     * @returns any Prices for web shop resource - success response
     * @throws ApiError
     */
    public static async webPriceCardWebShop(): Promise<{
        data?: Array<{
            readonly card?: number,
            readonly digital_gift?: number,
            readonly letter_gift?: number,
            readonly renewal_after_expiry?: number,
            readonly renewal_before_expiry?: number,
        }>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/price/card/web_shop`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * Get prices for event calendar
     * Get prices for event calendar
     * @returns any Prices for event calendar resource - success response
     * @throws ApiError
     */
    public static async webPriceCardEventCalendar(): Promise<{
        data?: Array<{
            readonly card?: number,
            readonly renewal_after_expiry?: number,
            readonly renewal_before_expiry?: number,
        }>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/price/card/event_calendar`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * Get renewals prices for customer portal
     * Get renewals prices for customer portal
     * @returns any Prices for renewals in customer portal resource - success response
     * @throws ApiError
     */
    public static async webPriceRenewals(): Promise<{
        data?: Array<{
            readonly renewal_after_expiry?: number,
            readonly renewal_before_expiry?: number,
        }>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/price/renewals`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * get customer portal elements list
     * @returns any Customer portal elements list
     * @throws ApiError
     */
    public static async webCustomerPortalElementsList(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/customer-portal/element`,
        });
        return result.body;
    }

    /**
     * get customer portal translation
     * @param pageName
     * @param language
     * @returns any Success
     * @throws ApiError
     */
    public static async webCustomerPortalTranslation(
        pageName: string,
        language: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/translation/${pageName}/${language}`,
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * News List
     * News List
     * @param orderBy Records ordering direction
     * @param pageSize Records count per page
     * @param lang records language
     * @param page The number of page of the results
     * @returns any News list for Customer Portal
     * @throws ApiError
     */
    public static async latestNewsList(
        orderBy?: string,
        pageSize?: number,
        lang?: string,
        page?: number,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/news`,
            query: {
                'order_by': orderBy,
                'page_size': pageSize,
                'lang': lang,
                'page': page,
            },
            errors: {
                404: `Not found`,
            },
        });
        return result.body;
    }

    /**
     * Get header
     * Get remote header
     * @param lang file language
     * @returns any Header html
     * @throws ApiError
     */
    public static async getRemoteHeader(
        lang: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/web-pages/header`,
            query: {
                'lang': lang,
            },
            errors: {
                404: `Header not found`,
            },
        });
        return result.body;
    }

    /**
     * Get footer
     * Get remote footer
     * @param lang file language
     * @returns any Footer html
     * @throws ApiError
     */
    public static async getRemoteFooter(
        lang: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/web-pages/footer`,
            query: {
                'lang': lang,
            },
            errors: {
                404: `Footer not found`,
            },
        });
        return result.body;
    }

    /**
     * Add request info to logs
     * Add request info to logs
     * @param requestBody request parameters
     * @returns any Ok
     * @throws ApiError
     */
    public static async requestLogger(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/util/logger`,
            body: requestBody,
        });
        return result.body;
    }

}