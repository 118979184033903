import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, Colors } from 'constants/index'
import { useTranslation } from 'react-i18next'
import RounCheckIcon from 'assets/icons/roundCheckIcon.svg'

const MuseumPageCard = () => {
  const [t] = useTranslation()
  const returnOption = (text: string) => (
    <div className="d-flex mb-4 align-items-center">
      <img src={RounCheckIcon} alt="check" className="mr-3" />
      <Text textAlign={'left'} fontSize={FontSize.EIGHTEEN}>
        {t(text)}
      </Text>
    </div>
  )

  return (
    <Container>
      <Text
        textAlign={'left'}
        fontWeight={FontWeight.SEVEN}
        fontFamily={FontFamily.BOLD}
        className="mb-4"
        fontSize={FontSize.EIGHTEEN}
      >
        {t('extensionOfMuseumCard')}
      </Text>
      {returnOption('checkingValidityOfCard')}
      {returnOption('extendValidityOfCard')}
      {returnOption('turnOnPermanentSubscription')}
      {returnOption('rateMuseumVisits')}
      {returnOption('adjustNewsletterSetting')}
    </Container>
  )
}
export default MuseumPageCard

const Container = styled.div`
  padding: 48px 0px 0px 40px;
  background: ${Colors.WHITE_GREY};
  border-radius: 12px;
  width: 534px;
  height: 384px;
  @media (max-width: 700px) {
    height: fit-content;
    padding: 20px;
  }
`
