import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { getUserTransactions, getReceiptInfo } from './asyncActions'
import { Status } from 'models'
import { ReceiptModel } from './ReceiptModel'

export interface UserTransactionState {
  data: any
  status: Status
  error: string | any
  receipt: ReceiptModel
}

const initialState: UserTransactionState = {
  data: null,
  error: null,
  status: Status.IDLE,
  receipt: null,
}

export const userTransactionSlice = createSlice({
  name: 'userTransaction',
  initialState,
  reducers: {
    reset: (state: UserTransactionState) => {
      state = initialState
    },
    resetReceipt: (state: UserTransactionState) => {
      state.receipt = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getUserTransactions.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = Status.SUCCEEDED
      })
      .addCase(getUserTransactions.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
      .addCase(getReceiptInfo.fulfilled, (state, action) => {
        state.receipt = action.payload
        state.status = Status.SUCCEEDED
      })
      .addMatcher(isAnyOf(getUserTransactions.pending, getReceiptInfo.pending), state => {
        state.error = null
        state.status = Status.PENDING
      })
  },
})

export const { reset, resetReceipt } = userTransactionSlice.actions

export const selectUserTransactionData = (state: RootState): UserTransactionState['data'] => state.userTransaction.data
export const selectUserTransactionError = (state: RootState): UserTransactionState['error'] =>
  state.userTransaction.error
export const selectUserTransactionStatus = (state: RootState): UserTransactionState['status'] =>
  state.userTransaction.status
export const selectReceiptData = (state: RootState): UserTransactionState['receipt'] => state.userTransaction.receipt

export default userTransactionSlice.reducer
