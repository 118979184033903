import Text from 'Components/UiKit/Text'
import { FontWeight, FontSize, calculateDynamicFont } from 'constants/index'
import { FunctionComponent } from 'react'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { useTranslation } from 'react-i18next'
import { Space } from 'Components/UiKit/Space'

const ContactCustomerSupport: FunctionComponent = () => {
  const { t } = useTranslation<string>()

  return (
    <div className={'item flex-none mt-auto '}>
      <div className={'flex-wrap'}>
        <div className={'item flex-none'}>
          <Text
            fontSize={calculateDynamicFont({
              max: FontSize.TWENTY,
              min: FontSize.SIXTEEN,
            })}
          >
            {t('problems')}
            &nbsp;
            {t('contactOur')}
          </Text>
        </div>
        <Space width={5} />
        <div className={'item flex-none'}>
          <TextBotton
            fontWeight={FontWeight.NORMAL}
            size={calculateDynamicFont({
              max: FontSize.TWENTY,
              min: FontSize.SIXTEEN,
            })}
            onClick={() => window.open(`https://museot.fi/asiakaspalvelu/`)}
            text={`${t('contactOurCustomerService')}`}
          />
        </div>
      </div>
    </div>
  )
}
export default ContactCustomerSupport
