import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont, Colors } from 'constants/index'

const HeaderWrapper = () => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  return (
    <Container>
      <Text
        textAlign={'left'}
        fontWeight={FontWeight.SEVEN}
        fontFamily={FontFamily.BOLD}
        fontSize={calculateDynamicFont({
          max: FontSize.SIXTEEN,
          min: FontSize.SIXTEEN,
        })}
        color={Colors.FRENCH_ROSE}
        className="pointer"
        onClick={() => history.goBack()}
      >
        &#8592; &nbsp;
        {t('back')}
      </Text>
      <Title
        textAlign={'left'}
        fontFamily={FontFamily.NORMAL}
        fontSize={calculateDynamicFont({
          max: FontSize.TWENTY,
          min: FontSize.TWENTY,
        })}
      >
        {t('cardRenewal')}
      </Title>
      <SubTitle
        textAlign={'left'}
        fontWeight={FontWeight.EIGHT}
        fontFamily={FontFamily.EXTRABOLD}
        fontSize={calculateDynamicFont({
          max: FontSize.THIRTY_TWO,
          min: FontSize.THIRTY_TWO,
        })}
      >
        {t('paymentMethod')}
      </SubTitle>
      <Message
        textAlign={'left'}
        fontFamily={FontFamily.NORMAL}
        fontSize={calculateDynamicFont({
          max: FontSize.TWENTY,
          min: FontSize.TWENTY,
        })}
      >
        {t('selectPreferedPaymentMethod')}
      </Message>
      <SubMessage
        textAlign={'left'}
        fontFamily={FontFamily.NORMAL}
        fontSize={calculateDynamicFont({
          max: FontSize.TWENTY,
          min: FontSize.TWENTY,
        })}
      >
        {t('youCanPayWithDebitCardOptions')}
      </SubMessage>
    </Container>
  )
}
export default HeaderWrapper
const Container = styled.div`
  max-width: 594px;
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  @media (max-width: 700px) {
    padding-top: 42px;
  }
`
const Title = styled(Text)`
  margin-top: 58px;
  margin-bottom: 8px;
`
const SubTitle = styled(Text)`
  margin-bottom: 40px;
`
const Message = styled(Text)`
  margin-bottom: 20px;
`
const SubMessage = styled(Text)`
  margin-bottom: 64px;
`
