import Text from 'Components/UiKit/Text'
import { calculateFont, FontWeight } from 'constants/index'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { useTranslation } from 'react-i18next'
import { formatPrice } from 'utils/functions'

const checkTextAlignment = (columnKey: string) => {
  return columnKey === 'amount' ? 'center' : columnKey === 'status' ? 'right' : 'left'
}

export function CalculateCellValue({
  columnKey,
  cellValue,
  isReponsive,
  onClick,
}: {
  columnKey: string
  cellValue: string
  isReponsive?: boolean
  onClick?: () => void
}) {
  const { t } = useTranslation<string>()

  return (
    <Text
      textAlign={!isReponsive ? checkTextAlignment(columnKey) : 'left'}
      fontWeight={isReponsive ? FontWeight.BOLD : FontWeight.NORMAL}
      fontSize={calculateFont({ coefficient: 0.5 })}
    >
      {columnKey === 'created_at' ? cellValue.split(' ')[0] : null}
      {columnKey === 'type' ? cellValue : null}
      {columnKey === 'amount' ? formatPrice(Number(cellValue)) : null}
      {columnKey === 'status' ? (
        <Text
          textAlign={isReponsive ? 'left' : 'right'}
          fontWeight={isReponsive ? FontWeight.BOLD : FontWeight.NORMAL}
          fontSize={calculateFont({ coefficient: 0.5 })}
        >
          {cellValue} &nbsp;
          <TextBotton text={t('showReceipt')} containerClassName="status-button" onClick={onClick} />
        </Text>
      ) : null}
    </Text>
  )
}

export const getClass = (index: number): string => {
  return index % 2 === 0 ? 'white-background-color' : 'alice-blue-background-color'
}
