import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontFamily, FontSize, FontWeight } from 'constants/fonts'
import { Colors } from 'constants/colors'
import SliderGoArrow from 'assets/icons/sliderGoArrow.svg'
import 'react-multi-carousel/lib/styles.css'
import SlideShow from 'Components/UiKit/SlideShow'
import { useAppSelector } from 'store/hooks'
import { selectElementData } from 'store/element'
import { ElementResource } from 'store/element/elementModel'
import Text from 'Components/UiKit/Text'

interface SliderContainerProps {
  bgColor?: string
  image?: string
}

interface Props {
  name?: string
  isMobile?: boolean
}

export const NewsSlider: VoidFunctionComponent<Props> = ({ name, isMobile }) => {
  const { t } = useTranslation<string>()
  const elementData = useAppSelector(selectElementData)

  return (
    <Contanier>
      <SlideShow title={t('goodToSeeYou', { name })} normalElements={4} isMobile={isMobile || !elementData?.length}>
        {elementData?.map((item: ElementResource, idx: number) => (
          <BackgroundContainer key={idx} bgColor={item.background_color} image={item.background_image_url}>
            <TitleTextStyled>
              {item.icon ? (
                <img src={item.icon} alt="icon" />
              ) : (
                <Text
                  textAlign="left"
                  color={Colors.WHITE}
                  fontSize={FontSize.EIGHTEEN}
                  fontWeight={FontWeight.EIGHT}
                  fontFamily={FontFamily.EXTRABOLD}
                >
                  {item.title}
                </Text>
              )}
            </TitleTextStyled>
            <DescriptionStyled>
              <Text
                fontFamily={FontFamily.BOLD}
                color={Colors.WHITE}
                fontSize={FontSize.FOURTEEN}
                fontWeight={FontWeight.SEVEN}
              >
                {item.title}
              </Text>
            </DescriptionStyled>
            <GoIconStyled>
              <img src={SliderGoArrow} alt="go" />
            </GoIconStyled>
          </BackgroundContainer>
        ))}
      </SlideShow>
    </Contanier>
  )
}

export default NewsSlider

const Contanier = styled.div`
  margin-bottom: 104px;
  position: relative;
  .slick-slide {
    width: 255px !important;
  }
  @media (max-width: 700px) {
    margin-bottom: 64px;
  }
`

const TitleTextStyled = styled.div`
  margin-left: 12px;
  padding-top: 15px;
`
const DescriptionStyled = styled.div`
  position: absolute;
  left: 10px;
  bottom: 30px;
`
const GoIconStyled = styled.div`
  position: absolute;
  right: 13px;
  bottom: 22px;
  background: rgba(255, 255, 255, 0.2);
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`
const BackgroundContainer = styled.div<SliderContainerProps>`
  height: 197px;
  width: 231px;
  border-radius: 6px;
  position: relative;
  margin-top: 42px;
  border-radius: 16px;
  background: ${({ image }) => `url(${image}) no-repeat center`};
  background-color: ${({ bgColor }) => bgColor};
  background-size: cover;
`
