import { Diamond, Level2, Level3, Level4, Level5, Level6, Level7 } from '../../../../../../assets/iconComponents'

export const levels = {
  1: { icon: Diamond, title: 'diamond' },
  2: { icon: Level2, title: 'platium' },
  3: { icon: Level3, title: 'gold' },
  4: { icon: Level4, title: 'silver' },
  5: { icon: Level5, title: 'bronze' },
  6: { icon: Level6, title: 'brass' },
  7: { icon: Level7, title: 'iron' },
}
