import { FunctionComponent } from 'react'
import { Footer } from '../UiKit/Footer'
import styled from 'styled-components'
import HeaderWrapper from './HeaderWrapper'
import CardRenewalOptions from './CardRenewalOptions'
import ContactCustomerSupport from 'Components/ContactCustomerSupport'

const CardRenewal: FunctionComponent = () => {
  return (
    <div className="w-100">
      <Container
        className={'vertical height-unset justify-content-start align-items-center alice-blue-background-color'}
      >
        <HeaderWrapper />
        <div className={'item flex-none px-5'}></div>
        <BodyWrapper>
          <CardRenewalOptions />
        </BodyWrapper>
        <SupportWrapper>
          <ContactCustomerSupport />
        </SupportWrapper>
      </Container>
      <div className={'item flex-none item-top-left'}>
        <Footer />
      </div>
    </div>
  )
}
export default CardRenewal

const Container = styled.div`
  padding: 0px 25px;
  width: 100%;
`
const BodyWrapper = styled.div`
  max-width: 749px;
  width: 100%;
`
const SupportWrapper = styled.div`
  margin-bottom: 95px;
  @media (max-width: 700px) {
    margin-bottom: 48px;
  }
`
