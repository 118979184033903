import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import Button from 'Components/UiKit/Buttons/Button'
import {
  FontWeight,
  FontFamily,
  FontSize,
  Colors,
  calculateDynamicFont,
  isDesktopOrLaptop,
  ModalContainer,
} from 'constants/index'
import StandingOrderModal from 'Components/Modals/StandingOrderModal'
import PaymentMethodModal from 'Components/Modals/PaymentMethodModal'
import { useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import { generateFieldUuid } from 'utils/functions'

const InfoBanner = () => {
  const { t } = useTranslation<string>()
  const userInfo = useAppSelector(selectData)
  const [showStandingOrderModal, setShowStandingOrderModal] = useState(false)
  const [showPaymentModal, setPaymentModal] = useState(false)
  const handleClose = () => {
    localStorage.removeItem('subscribe')
    setShowStandingOrderModal(false)
    setPaymentModal(false)
  }

  const switchModals = () => {
    localStorage.setItem('subscribe', 'true')
    setShowStandingOrderModal(false)
    setPaymentModal(true)
  }

  return (
    <Container>
      <Text
        textAlign={isDesktopOrLaptop() ? 'left' : 'center'}
        fontWeight={FontWeight.EIGHT}
        width="fit-content"
        fontFamily={FontFamily.EXTRABOLD}
        fontSize={calculateDynamicFont({
          min: FontSize.EIGHTEEN,
          max: FontSize.TWENTY,
        })}
        color={Colors.WHITE}
      >
        {t('switchToRegularMessag')}
      </Text>
      <BtnContainer
        id={generateFieldUuid(
          !userInfo.has_standing_order ? 'switchStandingBtng' : 'deactivateStandingOrderBTn',
          'buttton'
        )}
        fontSize={FontSize.THIRTEEN}
        text={!userInfo.has_standing_order ? t('switchStandingBtng') : t('deactivateStandingOrderBTn')}
        bgColor={Colors.DARK_PURPLE}
        onClick={() => setShowStandingOrderModal(true)}
      />
      <ModalContainer type="combine" isVisible={showStandingOrderModal || showPaymentModal} setVisible={handleClose}>
        {showStandingOrderModal ? <StandingOrderModal action={switchModals} /> : <PaymentMethodModal />}
      </ModalContainer>
    </Container>
  )
}
export default InfoBanner

const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  padding-right: 44px;
  border-radius: 14px;
  background: ${Colors.MEDIUM_AQUAMARINE};
  margin-bottom: 40px;
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    height: 231px;
    padding-left: 26px;
    padding-right: 26px;
  }
`
const BtnContainer = styled(Button)`
  width: 258px !important;
  height: 44px !important;
  @media (max-width: 700px) {
    margin-top: 24px;
  }
`
