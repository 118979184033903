import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'
import { getUserInfoAsync } from 'store/user/asyncActions'
import { renderLang } from 'utils'

export const getNews: AsyncThunk<any, void, { rejectValue: string }> = createAsyncThunk(
  'web/news',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.latestNewsList('desc', 5, renderLang())
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const subscribeToNews = createAsyncThunk('web/news/subscribe', async (_, { rejectWithValue, dispatch }) => {
  try {
    const result = await WebService.webUserNewsletterSubscribe()
    dispatch(getUserInfoAsync())
    return result
  } catch (error: any) {
    return handleApiCallError(error, rejectWithValue, dispatch)
  }
})
