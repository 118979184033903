import styled from 'styled-components'
import { Cards } from './Cards'
import TransparentCard from './TransparentCard'
import Card from './Card'

const MessageCardsWrapper = () => {
  return (
    <Container>
      {Cards.map((card: any, index: number) =>
        card.transparent ? <TransparentCard {...card} key={index} /> : <Card {...card} action={() => {}} key={index} />
      )}
    </Container>
  )
}
export default MessageCardsWrapper

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 72px;
  column-gap: 23px;
  @media (max-width: 700px) {
    row-gap: 48px;
  }
`
