import { VoidFunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { FontSize, FontFamily, FontWeight, calculateDynamicFont } from 'constants/index'
import DashLine from 'assets/icons/dashLine.svg'
import { Diamond, Level2, Level3, Level4, Level5, Level6 } from 'assets/iconComponents'
import LevelItem from './LevelItem'
import CircleSlider from './CircleSlider'
import { useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import { definedLevel } from 'utils/definedLevel'

const VisitRightSide: VoidFunctionComponent<{ isMobile?: boolean }> = ({ isMobile }) => {
  const { t } = useTranslation<string>()
  const [definedLevelResult, setDefinedLevelResult] = useState({
    neededVisits: 0,
    levelNumber: 0,
    levelText: '',
    nextLevel: '',
  })
  const data = useAppSelector(selectData)

  useEffect(() => {
    const definedLevelResult = definedLevel(data?.visits_count)
    setDefinedLevelResult(definedLevelResult)
  }, [data?.visits_count])

  return (
    <Container>
      <TopContainer>
        <Text
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY,
            min: FontSize.EIGHTEEN,
          })}
          fontFamily={FontFamily.EXTRABOLD}
          fontWeight={FontWeight.EIGHT}
        >
          {t('youAreOn', { level: t(definedLevel(data.visits_count).levelText) })}
        </Text>
        <CircleSlider />
        {definedLevelResult?.neededVisits > 0 && (
          <Text className="mt-4" fontSize={FontSize.EIGHTEEN}>
            {t('youNeedToXlevel', {
              levelNumber: definedLevelResult?.neededVisits,
              levelText: t(definedLevelResult?.nextLevel),
            })}
          </Text>
        )}
      </TopContainer>

      <Text
        textAlign="left"
        fontSize={calculateDynamicFont({
          max: FontSize.EIGHTEEN,
          min: FontSize.SIXTEEN,
        })}
      >
        {t('visitRightSideMessage')}
        <br /> <br />
        {t('visitRightSideSubMessage')}
      </Text>
      <LevelStyled>
        {!isMobile && <DashLineStyled src={DashLine} alt="" />}
        <LevelsContainer>
          <LevelWrapper>
            <Diamond />
            <LevelItem
              title={t('diamond')}
              description={
                definedLevelResult?.neededVisits > 0 ? t('youNeedToThislevel', { number: 21 }) : t('levelsCongrats')
              }
            />
          </LevelWrapper>
          <LevelWrapper>
            <Level2 />
            <LevelItem title={t('platium')} description={t('youNeedToThislevel', { number: 15 })} />
          </LevelWrapper>
          <LevelWrapper>
            <Level3 />
            <LevelItem title={t('gold')} description={t('youNeedToThislevel', { number: 10 })} />
          </LevelWrapper>
          <LevelWrapper>
            <Level4 />
            <LevelItem title={t('silver')} description={t('youNeedToThislevel', { number: 6 })} />
          </LevelWrapper>
          <LevelWrapper>
            <Level5 />
            <LevelItem title={t('bronze')} description={t('youNeedToThislevel', { number: 2 })} />
          </LevelWrapper>
          <LevelWrapper>
            <Level6 />
            <LevelItem title={t('brass')} />
          </LevelWrapper>
        </LevelsContainer>
      </LevelStyled>
    </Container>
  )
}

export default VisitRightSide

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 350px;
  @media (max-width: 700px) {
    margin-top: 37px;
  }
`

const LevelStyled = styled.div`
  margin-top: 35px;
  position: relative;
  z-index: 9;
  display: flex;
  margin-bottom: 35px;
`
const DashLineStyled = styled.img`
  position: absolute;
  left: 22px;
  top: 22px;
  z-index: -9;
`
const LevelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`
const LevelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  @media (max-width: 700px) {
    div {
      max-width: 230px;
    }
  }
`

const TopContainer = styled.div`
  max-width: 280px;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 30px;
`
