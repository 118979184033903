import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { FontFamily, FontSize, FontWeight } from 'constants/index'
import { Colors } from 'constants/colors'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { EdenredIcon, SmartumIcon, EpassiIcon } from 'assets/iconComponents'
import { generateFieldUuid } from 'utils/functions'

interface Props {
  isMobile?: boolean
}

const CalturalAdvantage: VoidFunctionComponent<Props> = ({ isMobile }) => {
  const { t } = useTranslation<string>()
  const Images = [<EdenredIcon key={1} />, <SmartumIcon key={3} />, <EpassiIcon key={4} />]
  return (
    <Contanier isMobile={isMobile}>
      <TitleStyled isMobile={isMobile}>
        <Text
          textAlign={isMobile ? 'center' : 'left'}
          fontSize={FontSize.TWENTY_FOUR}
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          color={Colors.TOLOPEA}
        >
          {t('useCalturalBenefits')}
        </Text>
        <TextBotton
          id={generateFieldUuid('readMore', 'button')}
          textAlign={isMobile ? 'center' : 'left'}
          containerClassName="mt-3"
          size={FontSize.FOURTEEN}
          fontFamily={FontFamily.BOLD}
          onClick={() => window.open('https://museot.fi/kulttuuriedut')}
          text={t('readMore')}
        />
      </TitleStyled>
      {isMobile ? (
        <>
          <FlexRow>{Images.slice(0, 2)}</FlexRow>
          <FlexRow>{Images.slice(2, 4)}</FlexRow>
        </>
      ) : (
        <>{Images}</>
      )}
    </Contanier>
  )
}

export default CalturalAdvantage

const Contanier = styled.div<Props>`
  display: flex;
  margin: 105px 0px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: space-between;
  @media (max-width: 700px) {
    margin: 53px 0px 64px;
  }
  svg {
    width: 96px;
  }
`
const TitleStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  max-width: 228px;
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  margin-top: 24px;
  img {
    width: 120px;
    height: fit-content;
  }
`
