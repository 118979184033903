import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'

export const getUserTransactions: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  'web/user/transactions',
  async (page, { rejectWithValue, dispatch }) => {
    try {
      const response = await WebService.webUserTransactions('asc', 5, page)

      return response
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const getReceiptInfo: AsyncThunk<any, string, { rejectValue: string }> = createAsyncThunk(
  '/web/transaction/receipt-info',
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await WebService.webTransactionReceiptInfo(code)

      return response
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
