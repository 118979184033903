import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import Button from 'Components/UiKit/Buttons/Button'
import { FontWeight, FontFamily, FontSize, Colors, calculateDynamicFont } from 'constants/index'

const BuyCardWrapper = ({
  title,
  message,
  btnLabel,
  onClick,
  btnColor,
  icon,
  id,
}: {
  title: string
  message: string
  btnLabel: string
  onClick?: () => void
  btnColor?: Colors
  icon?: string
  id?: string
}) => {
  return (
    <CardWrapper>
      <IconWrapper src={icon} alt="" />
      <Text
        textAlign={'center'}
        fontWeight={FontWeight.EIGHT}
        fontFamily={FontFamily.EXTRABOLD}
        fontSize={calculateDynamicFont({
          min: FontSize.EIGHTEEN,
          max: FontSize.TWENTY_FOUR,
        })}
      >
        {title}
      </Text>
      <Text
        textAlign={'center'}
        fontWeight={FontWeight.NORMAL}
        fontFamily={FontFamily.NORMAL}
        fontSize={calculateDynamicFont({
          min: FontSize.SIXTEEN,
          max: FontSize.TWENTY,
        })}
      >
        {message}
      </Text>
      <CardBtn id={id} fontSize={FontSize.SIXTEEN} text={btnLabel} bgColor={btnColor} onClick={onClick} />
    </CardWrapper>
  )
}
export default BuyCardWrapper

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Colors.WHITE};
  border-radius: 16px;
  width: 596px;
  height: 493px;
  max-width: 100%;
  flex: 1 1 350px;
  & div {
    max-width: 391px;
    &:nth-child(2) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 700px) {
    height: 339px;
    & div {
      max-width: 90%;
    }
  }
`
const CardBtn = styled(Button)`
  width: 235px !important;
  height: 50px !important;
  margin-top: 40px;
  @media (max-width: 700px) {
    margin-top: 24px;
  }
`
const IconWrapper = styled.img`
  margin-bottom: 30px;
  @media (max-width: 700px) {
    width: 70px;
    height: 70px;
  }
`
