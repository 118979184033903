import { useTranslation } from 'react-i18next'
import { FontWeight, FontFamily, FontSize, Colors, calculateDynamicFont } from 'constants/index'
import { Container, BodyWrapper, BtnContainer } from './ModalStyledComponents'
import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { VoidFunctionComponent } from 'react'
import { toast } from 'react-toastify'

interface Props {
  handleClick: (coupon?: string) => void
  setVisible: () => void
}
const DeactivateUserModal: VoidFunctionComponent<Props> = ({ handleClick, setVisible }) => {
  const [t] = useTranslation()

  const handleSubmit = () => {
    handleClick()
    setVisible()
    toast.success(t('requestSentSuccess'))
  }
  return (
    <Container>
      <BodyWrapper>
        <Text
          fontFamily={FontFamily.NORMAL}
          fontWeight={FontWeight.SEVEN}
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY,
            min: FontSize.SIXTEEN,
          })}
        >
          {t('yourCustomerAccountWillBeDeleted')}
        </Text>
        <Text
          fontFamily={FontFamily.NORMAL}
          fontWeight={FontWeight.SEVEN}
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY,
            min: FontSize.SIXTEEN,
          })}
        >
          {t('deletingYourCustomerIntro')}
        </Text>
        <Text
          fontFamily={FontFamily.NORMAL}
          fontWeight={FontWeight.SEVEN}
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY,
            min: FontSize.SIXTEEN,
          })}
        >
          {t('sendRequestForDeletion')}
        </Text>
        <BtnWrapper
          text={t('iWantToDeleteMyAccount')}
          fontSize={FontSize.SIXTEEN}
          bgColor={Colors.FRENCH_ROSE}
          onClick={handleSubmit}
        />
        <TextBotton
          fontFamily={FontFamily.BOLD}
          size={FontSize.SIXTEEN}
          onClick={setVisible}
          text={t('noReturnHome')}
        />
      </BodyWrapper>
    </Container>
  )
}
export default DeactivateUserModal
const BtnWrapper = styled(BtnContainer)`
  max-width: 280px;
`
