import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont } from 'constants/index'
import { useTranslation } from 'react-i18next'
import LongArrow from 'assets/icons/longArrow.svg'

const TransparentCard = ({ questions, title }: { questions: any[]; title: string }) => {
  const [t] = useTranslation()
  return (
    <Container>
      <Title>
        <Text
          textAlign={'left'}
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          fontSize={calculateDynamicFont({
            max: FontSize.THIRTY_TWO,
            min: FontSize.TWENTY_EIGHT,
          })}
        >
          {t(title)}
        </Text>
      </Title>
      {questions.map((question, index) => (
        <div className="pointer" key={index}>
          <InfoText>{t(question)}</InfoText>
          <IconStyled src={LongArrow} />
        </div>
      ))}
    </Container>
  )
}

export default TransparentCard

const Container = styled.div`
  height: 452px;
  width: 600px;
  padding: 20px;
  flex: 1 1 452px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  background: transparent;
  border-radius: 16px;
  @media (max-width: 700px) {
    height: 500px;
    padding: 0px;
  }
`
const Title = styled.div`
  margin-bottom: 8px;
`

const InfoText = styled.span`
  color: ${props => props.color};
  font-size: ${FontSize.SIXTEEN};
  font-weight: ${FontWeight.NORMAL};
  font-family: ${FontFamily.NORMAL};
  text-align: center;
`
const IconStyled = styled.img`
  margin-left: 5px;
`
