import styled from 'styled-components'
import Button from 'Components/UiKit/Buttons/Button'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  //   align-items: center;
`
export const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 573px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`
export const BtnContainer = styled(Button)`
  max-width: 317px;
  width: 100%;
  height: 50px;
`
