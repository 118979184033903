import { Component } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import NextIcon from '../../../assets/icons/next.svg'
import PrevIcon from '../../../assets/icons/prev.svg'
import { FontFamily, FontWeight, calculateDynamicFont, FontSize } from 'constants/fonts'
import Text from '../Text'
import { generateFieldUuid } from 'utils/functions'

type Props = {
  title: string
  normalElements?: number
  isMobile?: boolean
}

export default class SlideShow extends Component<Props> {
  slider: Slider
  constructor(props: Props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }

  render() {
    const { normalElements, title, children, isMobile } = this.props
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      arrows: false,
      rows: 1,
      responsive: [
        {
          breakpoint: 4200,
          settings: {
            slidesToShow: normalElements + 1,
          },
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: normalElements,
          },
        },
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 3.2,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 2.2,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1.2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1.01,
          },
        },
      ],
    }

    return (
      <>
        <HeaderStyled>
          <Text
            textAlign="left"
            fontWeight={FontWeight.EIGHT}
            fontSize={calculateDynamicFont({ min: FontSize.TWENTY_FOUR, max: FontSize.FORTY_FOUR })}
            fontFamily={FontFamily.EXTRABOLD}
          >
            {title}
          </Text>
          {!isMobile && (
            <>
              <img
                src={PrevIcon}
                alt="prev"
                onClick={this.previous}
                className="mr-2 pointer"
                id={generateFieldUuid('prev', title)}
              />
              <img
                src={NextIcon}
                alt="next"
                onClick={this.next}
                className="pointer"
                id={generateFieldUuid('next', title)}
              />
            </>
          )}
        </HeaderStyled>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {children}
        </Slider>
      </>
    )
  }
}

const HeaderStyled = styled.div`
  display: flex;
`
