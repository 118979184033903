import TagManager from 'react-gtm-module'

const gtmEvent = (eventName: string, tagManagerArgs: any): any => {
  const eventTagManager = {
    dataLayer: {
      event: eventName,
    },
    dataLayerName: 'dataLayer',
  }
  TagManager.dataLayer(tagManagerArgs)
  TagManager.dataLayer(eventTagManager)
}

export const viewItem = (cardPrice: number, renewalAfterExpiry: number, giftPrice: number): void => {
  const tagManagerArgs = {
    dataLayer: [
      {
        item_name: 'Oma Museokortti -asiakassivu',
        item_id: 'id needed here',
        price: cardPrice,
        item_brand: 'Museokortti',
        item_category: 'Osta uusi Museokortti',
        item_category2: 'cardNew',
        item_variant: 'Museokortti itselleni',
        quantity: '1',
      },
      {
        item_name: 'Oma Museokortti -asiakassivu',
        item_id: 'id needed here',
        price: renewalAfterExpiry,
        item_brand: 'Museokortti',
        item_category: 'Voimassaolon jatkaminen',
        item_category2: 'cardRenewed',
        item_variant: 'Jatkan Museokorttini voimassaoloa',
        quantity: '1',
      },
      {
        item_name: 'Lahjaksi Museokortti',
        item_id: 'id needed here',
        price: giftPrice,
        item_brand: 'Museokortti',
        item_category: 'Osta lahjaksi',
        item_category2: 'cardGift',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_variant: 'Lahjaksi Museokortti',
        quantity: '1',
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('view_item', tagManagerArgs)
}

export const addToCardTag = (price: any): void => {
  const tagManagerArgs = {
    dataLayer: [
      {
        item_name: 'Oma Museokortti -asiakassivu',
        item_id: 'id needed here',
        price: price,
        item_brand: 'Museokortti',
        item_category: 'Voimassaolon jatkaminen',
        item_category2: 'cardRenewed',
        item_variant: 'Jatkan Museokorttini voimassaoloa',
        quantity: '1',
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('add_to_cart', tagManagerArgs)
}

export const beginCheckout = (price: number): void => {
  const tagManagerArgs = {
    dataLayer: [
      {
        item_name: 'Oma Museokortti -asiakassivu',
        item_id: 'id needed here',
        price: price,
        item_brand: 'Museokortti',
        item_category: 'Voimassaolon jatkaminen',
        item_category2: 'cardRenewed',
        item_variant: 'Jatkan Museokorttini voimassaoloa',
        quantity: '1',
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('begin_checkout', tagManagerArgs)
}

export const addPaymentInfo = (paymentType: string, price: number): void => {
  const tagManagerArgs = {
    dataLayer: [
      { payment_type: paymentType },
      {
        item_name: 'Oma Museokortti -asiakassivu',
        item_id: 'id needed here',
        price: price,
        item_brand: 'Museokortti',
        item_category: 'Voimassaolon jatkaminen',
        item_category2: 'cardRenewed',
        item_variant: 'Jatkan Museokorttini voimassaoloa',
        quantity: '1',
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('add_payment_info', tagManagerArgs)
}

export const purchase = (price: string, transactionId: string, coupon: string | undefined): void => {
  const tagManagerArgs = {
    dataLayer: [
      {
        currency: 'EUR',
        value: price,
        tax: 0.0,
        shipping: 0.0,
        affiliation: 'asiakassivu.museokortti.fi',
        transaction_id: transactionId,
        coupon: coupon,
      },
      {
        item_name: 'Oma Museokortti -asiakassivu',
        item_id: 'id needed here',
        price: price,
        item_brand: 'Museokortti',
        item_category: 'Voimassaolon jatkaminen',
        item_category2: 'cardRenewed',
        item_variant: 'Jatkan Museokorttini voimassaoloa',
        quantity: '1',
        item_coupon: coupon,
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('purchase', tagManagerArgs)
}
