const NordeaIcon = (props: any) => {
  return (
    <svg width="116" height="25" viewBox="0 0 116 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M105.774 5.28427C101.086 5.6252 97.3363 9.46062 96.9953 14.1483C96.6544 19.6884 101.001 24.2909 106.456 24.2909C108.416 24.2909 111.059 23.2681 112.252 21.2226V24.0352H115.917V15.0007C116.087 9.03446 111.314 4.85812 105.774 5.28427ZM111.996 15.0007C111.826 18.1542 109.098 20.5407 105.859 20.1145C103.388 19.7736 101.427 17.8133 101.086 15.3416C100.746 12.0176 103.302 9.20492 106.541 9.20492C109.439 9.20492 111.826 11.5062 111.996 14.404V15.0007Z"
        fill="#0000A0"
      />
      <path
        d="M85.6574 5.28432C80.6288 5.45478 76.3672 9.2902 76.3672 14.9155C76.3672 20.0294 80.5435 24.3762 85.9131 24.3762C89.6633 24.3762 93.3282 22.1601 94.7772 18.4952L91.1122 17.3872C90.4304 18.9214 88.811 20.1998 87.0211 20.5407C84.4642 21.0521 82.0777 19.4327 81.1401 17.7281L95.3738 13.8075C94.9476 10.0573 91.7088 5.11386 85.6574 5.28432ZM80.3731 14.3189C80.3731 12.3585 81.5663 10.0573 84.1232 9.11974C87.0211 8.01173 89.5781 9.46066 90.6861 11.5062L80.3731 14.3189Z"
        fill="#0000A0"
      />
      <path
        d="M54.3787 9.11962V5.19897C50.7989 5.19897 49.6057 6.98884 49.0091 7.92638V5.79559H44.918V14.4892V23.9499H49.0943C49.0943 18.3246 49.0943 18.4098 49.0943 15.0006C49.0943 10.9095 51.4808 9.29008 54.3787 9.11962Z"
        fill="#0000A0"
      />
      <path
        d="M16.7913 16.7052L4.34751 2.04541H0.0859375V23.9499H4.51797V9.29008L17.1322 24.0351H20.9676V2.04541H16.7913V16.7052Z"
        fill="#0000A0"
      />
      <path
        d="M73.8965 0.0852051H69.9758V7.24465C69.0383 6.05141 66.1404 5.02863 63.6687 5.28432C58.981 5.62525 55.2308 9.46066 54.8899 14.1484C54.5489 19.6884 58.8957 24.2909 64.3506 24.2909C66.3961 24.2909 69.2088 23.3534 70.1463 21.5635V23.95H73.8112V14.9155C73.8112 14.8302 73.8112 14.8302 73.8112 14.745C73.8112 14.6598 73.8112 14.6598 73.8112 14.5746L73.8965 0.0852051ZM69.8054 15.0007C69.6349 18.1543 66.9075 20.5408 63.6687 20.1146C61.197 19.7737 59.2367 17.8133 58.8957 15.3416C58.5548 12.0176 61.1118 9.20497 64.3506 9.20497C67.2484 9.20497 69.6349 11.5062 69.8054 14.4041V15.0007Z"
        fill="#0000A0"
      />
      <path
        d="M32.9834 5.19922C27.6991 5.19922 23.4375 9.46079 23.4375 14.7451C23.4375 20.0295 27.6991 24.2911 32.9834 24.2911C38.2678 24.2911 42.5293 20.0295 42.5293 14.7451C42.4441 9.46079 38.1825 5.19922 32.9834 5.19922ZM32.9834 20.2C30.0003 20.2 27.5286 17.7282 27.5286 14.7451C27.5286 11.762 30.0003 9.29033 32.9834 9.29033C35.9665 9.29033 38.4382 11.762 38.4382 14.7451C38.4382 17.8135 35.9665 20.2 32.9834 20.2Z"
        fill="#0000A0"
      />
    </svg>
  )
}

export default NordeaIcon
