const Level2 = (props: any) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" fill="#C069FB" />
      <path
        d="M18.5078 31H30.0898V28.373H25.2461C24.6113 28.373 24.0645 28.3926 23.4688 28.4316L23.4395 28.2852C27.7461 26.498 30.0215 24.1641 30.0215 21.1172C30.0215 18.4023 28.1465 17.0645 24.582 17.0645C20.4414 17.0645 18.459 18.9004 18.6543 22.5137L22.2969 22.8652C22.1602 20.6094 22.8633 19.5547 24.4453 19.5547C25.7148 19.5547 26.3203 20.1504 26.3203 21.4004C26.3203 23.7832 23.6738 26.0488 18.5078 28.6855V31Z"
        fill="white"
      />
    </svg>
  )
}
export default Level2
