// @flow
import { FunctionComponent } from 'react'
import { Header } from '../UiKit/Header'
import './dependencies/profile.css'
import { Footer } from '../UiKit/Footer'
import { useTranslation } from 'react-i18next'
import { TabsAndLogout } from './dependencies/tabsAndLogout'
import styled from 'styled-components'

export const Profile: FunctionComponent = () => {
  const { t } = useTranslation<string>()

  return (
    <div className={'w-100 alice-blue-background-color'}>
      <Container className={'w-100'}>
        <div className={'item flex-none'}>
          <Header title={t('customerInformationProfile')} subTitle={t('manageYourInformation')} />
        </div>
      </Container>
      <TabsContainer>
        <TabsAndLogout />
      </TabsContainer>
      <div className={'item flex-none item-top-left'}>
        <Footer />
      </div>
    </div>
  )
}

const Container = styled.div`
  padding: 0 60px;
  @media (max-width: 700px) {
    padding: 0 24px;
  }
`

const TabsContainer = styled.div`
  padding: 0 60px;
  @media (max-width: 700px) {
    padding: 0px;
  }
`
