import { AnyAction, AsyncThunk, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit'
import { ApiError, WebService } from 'api/main'
import { renderLang, tokenUtils } from 'utils'
import { handleApiCallError } from 'store/helper'
import returnLoginType from 'utils/checkLoginFormKey'
import { getAnnouncementAsync } from 'store/announcement/asyncActions'

export type AppLoginForm = {
  user: number | string
  password: string
}

export type AppLoginRequest = {
  code: number
  email?: string
  password: string
}

export type AppTokenResource = {
  token?: string
}

export const loginRequestAsync: AsyncThunk<AppTokenResource | ApiError | any, AppLoginForm, any> = createAsyncThunk(
  'login/loginRequest',
  async (body: AppLoginForm, { rejectWithValue, dispatch }) => {
    let newBody: any = {
      password: body.password,
    }

    const inputType = returnLoginType(body.user.toString())
    newBody[inputType] = body.user
    try {
      const response = await WebService.webUserLogin(newBody, renderLang())
      loginSuccess(dispatch, response.token)

      return response
    } catch (error: ApiError | any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const logoutRequestAsync: AsyncThunk<ApiError | any, void, any> = createAsyncThunk(
  'logout/logoutRequest',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await WebService.webAuthLogout()
      return result
    } catch (error: ApiError | any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const requestPasswordReset: AsyncThunk<ApiError | any, any, any> = createAsyncThunk(
  'password/reset/request',
  async (body, { rejectWithValue, dispatch }) => {
    let newBody: any = {
      lang: renderLang(),
    }
    const inputType = returnLoginType(body.user.toString())
    newBody[inputType] = body.user
    try {
      const result = await WebService.webUserPasswordReset(newBody)
      return result
    } catch (error: ApiError | any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const resetPasswordReset: AsyncThunk<ApiError | any, any, any> = createAsyncThunk(
  'password/reset',
  async (body, { rejectWithValue, dispatch }) => {
    let newBody: any = {
      password: body.password,
      token: body.token,
      password_confirmation: body.password_confirmation,
    }
    try {
      const result = await WebService.webUserPassword(newBody)
      if (result) body.resolve()
      return result
    } catch (error: ApiError | any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
export const deactivateRequestAsync: AsyncThunk<ApiError | any, void, any> = createAsyncThunk(
  'deactivate/deactivateRequest',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await WebService.webUserDeactivate()
      return result
    } catch (error: ApiError | any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

const loginSuccess = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, token: string) => {
  tokenUtils.setToken(token)
  dispatch(getAnnouncementAsync())
}
