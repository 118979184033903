import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { ProfileIcon, VisitsIcon, BuyIcon, HomeIcon, QuestionIcon } from 'assets/iconComponents'
import Text from 'Components/UiKit/Text'
import { Colors, FontSize } from 'constants/index'
import MediaQuery from 'react-responsive'
import { ResponsiveConstant } from 'constants/responsive-constant'
import { useHistory } from 'react-router'
import './SideBar.css'
import SwitchLanguage from './SwitchLanguage'
import { useTranslation } from 'react-i18next'
import { generateFieldUuid } from 'utils/functions'

type Props = {
  Image: any
  name: string
  mobileMode?: boolean
}

const SideBar: FunctionComponent = () => {
  const history = useHistory()
  const [t, { language }] = useTranslation()
  const [selectedPath, setSelectedPath] = useState('')

  useEffect(() => {
    setSelectedPath(history.location.pathname.replace('/', ''))
  }, [history.location.pathname])

  const returnMainIcons = (mobileMode: boolean = false) => {
    return (
      <>
        {GetSideBarElement({ Image: HomeIcon, name: 'home', mobileMode })}
        {GetSideBarElement({ Image: ProfileIcon, name: 'profile', mobileMode })}
        {/* {GetSideBarElement({ Image: MessagesIcon, name: 'messages', mobileMode })} */}
        {GetSideBarElement({ Image: VisitsIcon, name: 'visits', mobileMode })}
        {GetSideBarElement({ Image: BuyIcon, name: 'buy', mobileMode })}
      </>
    )
  }
  const GetSideBarElement: FunctionComponent<Props> = ({ Image, name, mobileMode = false }: Props) => {
    const selected = selectedPath === name
    const pageName = name !== 'FAQ' && name !== 'visits' ? 'page' : name === 'visits' ? 'visit' : ''
    return (
      <div
        id={generateFieldUuid(name, 'sidebar-item')}
        role="button"
        className={`vertical ${selected ? `${pageName}-selected` : ''} page no-print`}
        onClick={event => {
          document.getElementById('body').scrollTo({ top: 0, behavior: 'smooth' })
          window.scrollTo({ top: 0, behavior: 'smooth' })

          if (name === 'FAQ') {
            if (language === 'fi') {
              window.open('https://museot.fi/vastauksia/')
            } else window.open('https://museot.fi/faq/')
          } else {
            setSelectedPath(name)
            history.push('/' + name)
          }
        }}
        style={{ height: mobileMode ? 44 : 60 }}
      >
        <Image />
        <Text width="fit-content" fontSize={FontSize.TWELVE} color={Colors.FRENCH_ROSE} className="pt-1">
          {t(name)}
        </Text>
      </div>
    )
  }
  return (
    <Fragment>
      <MediaQuery query={ResponsiveConstant.isDesktopOrLaptop}>
        <div className={'vertical side-bar-desktop-limit gap-2 align-self-start'}>
          <div className={'item flex-1'} />
          {returnMainIcons()}
          <div className={'item flex-2'} />
          {GetSideBarElement({ Image: QuestionIcon, name: 'FAQ' })}
          <SwitchLanguage id={generateFieldUuid('switchLanguage', 'sidebar-item')} />
          <div className={'item flex-5'} />
        </div>
      </MediaQuery>
      <MediaQuery query={ResponsiveConstant.isTabletOrMobile}>
        <div className={'side-bar-mobile-limit'}>{returnMainIcons(true)}</div>
      </MediaQuery>
    </Fragment>
  )
}

export default SideBar
