import { supportedLanguages } from 'constants/index'
import { renderLang } from 'utils'
import { Redirect } from 'react-router-dom'

export const renderRoute = (Component: any, props: any, i18n: any) => {
  const lang = props.match.params.lang
  const currentLang = renderLang()
  if (!lang) {
    return (
      <Redirect
        to={{
          pathname: `${props.match.path}/${currentLang}`,
          search: props.history.location.search,
          state: props.history.location.state,
        }}
      />
    )
  } else {
    if (supportedLanguages.includes(lang)) {
      if (lang !== currentLang) {
        localStorage.setItem('i18nextLng', lang)
        i18n.changeLanguage(lang)
      }
      return <Component />
    } else {
      return <Redirect to={`/login/${currentLang}`} />
    }
  }
}
