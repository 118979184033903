import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { calculateDynamicFont, FontFamily, FontSize, FontWeight } from 'constants/index'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { Colors } from 'constants/colors'
import RateComponent from './RateComponent'
import { useHistory } from 'react-router'
import { displayDate } from 'utils/functions'

interface Props {
  date: string
  title: string
  buttonText: string
  rate: number
}

const LastVisitedItem: VoidFunctionComponent<Props> = ({ date, title, buttonText, rate }) => {
  const history = useHistory()

  return (
    <Contanier>
      <FlexRow>
        <LeftHorizontalStyled>
          <Text textAlign="left" fontSize={FontSize.TWELVE} color={Colors.GREY_CHATEAU}>
            {displayDate(date)}
          </Text>
        </LeftHorizontalStyled>
        <RightHorizontalStyled>{rate !== 0 ? <RateComponent rate={rate} /> : null}</RightHorizontalStyled>
      </FlexRow>

      <TextStyled>
        <Text
          fontSize={calculateDynamicFont({
            max: FontSize.SIXTEEN,
            min: FontSize.FOURTEEN,
          })}
          textAlign="left"
          fontWeight={FontWeight.SEVEN}
          fontFamily={FontFamily.BOLD}
        >
          {title}
        </Text>
      </TextStyled>
      <TextBotton
        textAlign="left"
        size={calculateDynamicFont({
          max: FontSize.FOURTEEN,
          min: FontSize.TWELVE,
        })}
        fontFamily={FontFamily.BOLD}
        onClick={() => history.push('/visits')}
        text={buttonText}
      />
    </Contanier>
  )
}

export default LastVisitedItem

const Contanier = styled.div`
  padding: 16px 24px;
  background: linear-gradient(182.52deg, ${Colors.WHITE} 49.74%, ${Colors.AZURE} 97.89%);
  box-shadow: 0px 10px 22px rgba(7, 27, 133, 0.1);
  border-radius: 12px;
  margin-bottom: 16px;
  position: relative;
`
const LeftHorizontalStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 6;
`
const RightHorizontalStyled = styled.div`
  display: flex;
  flex: 4;
  justify-content: flex-end;
`
const TextStyled = styled.div`
  margin-bottom: 8px;
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`
