import { ReactElement } from 'react'

function BuyIcon(props: any): ReactElement {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 15.6362H22" stroke="#FF598F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54475 22.0001C7.5489 22.0001 8.36293 21.1861 8.36293 20.1819C8.36293 19.1778 7.5489 18.3638 6.54475 18.3638C5.54059 18.3638 4.72656 19.1778 4.72656 20.1819C4.72656 21.1861 5.54059 22.0001 6.54475 22.0001Z"
        stroke="#FF598F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1815 22.0001C21.1856 22.0001 21.9996 21.1861 21.9996 20.1819C21.9996 19.1778 21.1856 18.3638 20.1815 18.3638C19.1773 18.3638 18.3633 19.1778 18.3633 20.1819C18.3633 21.1861 19.1773 22.0001 20.1815 22.0001Z"
        stroke="#FF598F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.45473 12H3.81836" stroke="#FF598F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2 2H5.63637V4.72728H20.1818L18.3637 12"
        stroke="#FF598F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.63672 8.36373H9.27319"
        stroke="#FF598F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BuyIcon
