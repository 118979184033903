import { ReactElement } from 'react'

function MessangerIcon(props: any): ReactElement {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <circle cx="16.5" cy="16" r="16" fill="#1D89FE" />
      <rect x="6.5" y="6" width="19.8636" height="19" fill="url(#pattern0)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_142_457" transform="translate(0 -0.00470223) scale(0.0344828 0.0360502)" />
        </pattern>
        <image
          id="image0_142_457"
          width="29"
          height="28"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAcCAYAAACdz7SqAAACMUlEQVRIDeWWsU8bMRTGP6NK7ULK0rIQpgqJ0M4UsTEQiY2KRsBUiYgVJf0DCmMH+AdIRTekRIIJAZVgomo7hwSJMiVZCEuTLmWp0ZdriM/2+ZKLxNI3+c7vfT8/3/PzifjHvxIPbAMPzGvh/h/oo263dyQGzL4AXo8KxJ8CE8NeZOkaqDaA7xWJL1dArRmuKMIKafAx8GFGIPUqXIwe+SKwcSrx+zbY3wmdHAE+vRGIPQkWsM00/wArexI/arZZILCQCCws9w4khotkLDVsZoXy+zHDfo0a1NLNCt2ci5ahLs6MqaWbAR1/DkyN6m7Rn6lFTdUMaOqluTI1gONCEc7qLNf9EbqmAU1oq/KHA8eXwPtDiYVd81h8qwDpPWl8R13TgLq2lhlkD7374aKOe3Ct4cHS+xKZabMedM2uOxKBb/9lx4pk5yE4uSNbYzaR/KK471T6DqnPRqb696BzG5h4BnxdFTh61zkKhIcBdU0DWv2lrskDbp1J5OZF68DHh7zDvz0vWjB6F5bcGeqaxvYe/5RIjnUqWEogZ2kUbPjczlId0AvFv2yAmqpZe29prZOF6hxlzCJLfvZXurG9FM4c+FcWBdaOWT/xA/neCuW9yAbQr1GDWrpZoXRiA+gHzFhq2CwQ2gbbglzveHlnD2QgkLFG9boEXXM8i4WiRP7c3Zd7grLn8jekcQuwSahWvgkHqf6hmbKJszmovx7qWBXrdmw9p90GR/VzFlJU0bC4O9YwwQ8k/1ldAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  )
}

export default MessangerIcon
