import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, Colors } from 'constants/index'
import { useTranslation } from 'react-i18next'
import { Context } from '..'
import { useContext } from 'react'
import PrevLongArrow from 'assets/iconComponents/PrevLongArrow'
import dayjs from 'dayjs'

interface MessageProps {
  message: any
}

const MobileMessage = ({ message }: MessageProps) => {
  const [t] = useTranslation()
  const { value, setValue } = useContext(Context)

  const handleBack = () => {
    setValue({
      ...value,
      showMessage: false,
    })
  }
  return (
    <Container>
      <Text
        textAlign={'left'}
        fontWeight={FontWeight.SEVEN}
        fontFamily={FontFamily.BOLD}
        fontSize={FontSize.SIXTEEN}
        color={Colors.FRENCH_ROSE}
        onClick={handleBack}
      >
        <PrevLongArrow style={{ transform: 'rotate(180deg', marginRight: 10 }} />
        {t('back')}
      </Text>
      <MessageWrapper>
        <Text textAlign={'left'} fontWeight={FontWeight.SEVEN} fontFamily={FontFamily.BOLD} fontSize={FontSize.SIXTEEN}>
          {message.title}
        </Text>
        <MessageTitle>
          <InitialWrapper>
            <Text
              fontWeight={FontWeight.SEVEN}
              fontFamily={FontFamily.BOLD}
              fontSize={FontSize.SIXTEEN}
              color={Colors.FRENCH_ROSE}
            >
              {'M'}
            </Text>
          </InitialWrapper>
          <InfoWrapper>
            <Text
              textAlign={'left'}
              fontWeight={FontWeight.EIGHT}
              fontFamily={FontFamily.EXTRABOLD}
              fontSize={FontSize.FOURTEEN}
            >
              {message.title}
            </Text>
            <Text textAlign={'left'} fontSize={FontSize.FOURTEEN}>
              {dayjs(message.date).format('dddd, MMMM D, YYYY, h:m a')}
            </Text>
          </InfoWrapper>
        </MessageTitle>
        <Text textAlign={'left'} fontSize={FontSize.SIXTEEN}>
          {message.mesage}
        </Text>
      </MessageWrapper>
    </Container>
  )
}
export default MobileMessage

const Container = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  margin-bottom: 39px;
`
const MessageWrapper = styled.div`
  height: 576px;
  width: 100%;
  border-radius: 16px;
  background: ${Colors.WHITE};
  padding: 36px 19px 0px;
`
const MessageTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 27px 0px;
  gap: 16px;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & div:last-child {
    opacity: 0.5;
  }
`
const InitialWrapper = styled.div`
  background: ${Colors.YELLOW};
  width: 48px;
  height: 48px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`
