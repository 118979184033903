const DanskeBankIcon = (props: any) => {
  return (
    <svg width="120" height="19" viewBox="0 0 120 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M120.001 0.00198555H77.0391V18.1099H120.001V0.00198555ZM119.533 0.458195V17.6428H77.4953V0.458195H119.533Z"
        fill="#03426A"
      />
      <path d="M0.00390625 18.1099H75.1838V0.00198555H0.00390625V18.1099Z" fill="#03426A" />
      <path d="M119.534 0.457991V17.6426H77.4961V0.457991H119.534Z" fill="white" />
      <path
        d="M25.9669 10.2779C25.9669 12.0594 25.011 12.9393 22.8711 12.9393H19.5037V6.26964H22.7625C25.3477 6.26964 25.9669 7.52969 25.9669 8.90923V10.2779ZM27.3465 10.2236V9.08301C27.3465 5.44405 24.4787 5.30285 22.5561 5.30285H18.1133V13.9277H22.9363C26.7273 13.9277 27.3465 11.8747 27.3465 10.2236Z"
        fill="white"
      />
      <path
        d="M34.8623 11.7986C34.8623 12.2983 34.8188 12.7546 34.2431 12.9609C33.9064 13.0913 33.4284 13.1348 32.6246 13.1348C31.8208 13.1348 31.3428 13.0588 31.0604 12.8741C30.8649 12.7546 30.7888 12.559 30.7888 12.1463C30.7888 11.6249 30.9192 11.3316 31.169 11.2121C31.4188 11.0926 31.7339 11.0709 32.7115 11.0709H34.8623V11.7986ZM36.1658 13.9277V10.5386C36.1658 9.89772 36.1658 9.45237 36.1115 9.1265C36.0463 8.78976 35.9377 8.55075 35.6661 8.34436C35.0361 7.85554 33.4936 7.84472 32.7332 7.84472C32.1575 7.84472 30.5933 7.90991 30.0176 8.39872C29.8221 8.56166 29.7786 8.75717 29.7786 8.99615C29.7786 9.14823 29.8003 9.35457 29.8112 9.62613H31.0821C31.1038 9.47406 31.1364 9.10473 31.2776 8.95266C31.4514 8.78972 31.7447 8.72459 32.6137 8.72459C33.6131 8.72459 34.3083 8.75717 34.6016 8.9201C34.8731 9.07218 34.8623 9.39804 34.8623 9.64788V10.2344H31.9294C31.1364 10.2344 30.224 10.2562 29.7243 10.9405C29.4962 11.2338 29.3984 11.614 29.3984 12.1028C29.3984 13.5367 30.2783 14.0146 32.5377 14.0146C33.7109 14.0146 34.4821 13.8299 34.9492 13.428L35.0687 13.9277H36.1658Z"
        fill="white"
      />
      <path
        d="M44.0292 13.9277V10.6255C44.0292 9.48497 43.9749 9.24597 43.7033 9.03958C43.4426 8.84405 42.9212 8.72459 42.1283 8.72459C41.4331 8.72459 40.7161 8.81149 40.0426 8.99615V13.9277H38.75V7.88815H39.9123L39.9992 8.32268C40.2599 8.18147 40.4554 8.07279 40.727 7.99675C41.1615 7.8664 41.7046 7.80123 42.4324 7.80123C43.258 7.80123 43.8989 7.93159 44.3877 8.14884C44.8656 8.35523 45.1263 8.74632 45.2241 9.20255C45.2893 9.51756 45.3218 9.7891 45.3218 10.4734V13.9277H44.0292Z"
        fill="white"
      />
      <path
        d="M53.6207 13.5585C53.0233 13.8626 52.0456 13.9929 50.7747 13.9929C49.8949 13.9929 49.1671 13.9277 48.6022 13.7757C47.9939 13.6344 47.6898 13.352 47.6572 12.8306C47.6355 12.5591 47.6572 12.3201 47.6898 11.9942H48.939C48.9824 12.2984 48.9824 12.3962 48.9933 12.4613C49.0367 12.7438 49.015 12.8958 49.3517 13.0045C49.6776 13.1022 50.199 13.1457 50.8616 13.1457C51.6872 13.1457 52.2629 13.0587 52.6431 12.8849C52.9147 12.7654 53.045 12.5482 53.045 12.1354C53.045 11.6574 52.9472 11.462 52.4476 11.3859C52.2955 11.3534 52.1434 11.3533 51.9805 11.3425C51.8936 11.3316 51.6112 11.3316 51.1549 11.3207C50.9377 11.3207 50.6878 11.299 50.3945 11.2882C49.1019 11.223 47.7332 11.1687 47.7332 9.65877C47.7332 8.29009 48.5696 7.81212 50.8725 7.81212C51.4591 7.81212 52.176 7.8556 52.6105 7.90991C53.1102 7.97509 53.4904 8.06195 53.7619 8.21403C54.0661 8.38783 54.1638 8.65943 54.1638 9.13739C54.1638 9.26774 54.1639 9.39808 54.1421 9.60447H52.8821L52.8277 9.3329C52.7626 9.00703 52.7408 8.94186 52.6322 8.86582C52.4476 8.72461 51.8936 8.65943 51.1115 8.65943C50.3294 8.65943 49.884 8.69198 49.5255 8.82233C49.1888 8.94182 49.0367 9.15909 49.0367 9.5936C49.0367 9.88688 49.1454 10.1041 49.2866 10.2019C49.6233 10.4083 51.4047 10.3866 52.013 10.3974C52.6648 10.4083 53.0885 10.4626 53.4578 10.5929C54.1204 10.821 54.3811 11.2555 54.3811 12.1354C54.3811 12.8415 54.153 13.2869 53.6207 13.5585Z"
        fill="white"
      />
      <path
        d="M61.596 13.9277L58.4785 11.0709H58.0222V13.9277H56.7188V5.30285H58.0222V10.2128H58.4785L61.0638 7.88815H62.5845L59.5756 10.5495L63.3232 13.9277H61.596Z"
        fill="white"
      />
      <path
        d="M69.7539 10.3215H65.5935C65.637 9.5611 65.7999 9.22441 66.0932 9.00716C66.3974 8.7899 66.9514 8.68123 67.766 8.68123C68.8958 8.68123 69.428 8.93109 69.6236 9.45249C69.6996 9.66974 69.7539 9.87612 69.7539 10.3215ZM70.9488 12.0378H69.667C69.6235 12.2224 69.6018 12.6895 69.428 12.8634C69.2325 13.0589 68.8414 13.1458 67.7986 13.1458C66.7667 13.1458 66.2018 13.0045 65.9086 12.7113C65.6913 12.494 65.5609 12.0378 65.5609 11.3317V11.1688H70.9814C70.9923 10.8755 71.014 10.7995 71.014 10.5605C71.014 9.56114 70.8728 9.00714 70.4383 8.5835C69.9494 8.07296 69.0044 7.82311 67.7009 7.82311C66.0389 7.82311 65.0178 8.20329 64.5942 9.03971C64.3444 9.51766 64.2031 10.1912 64.2031 11.0276C64.2031 12.3419 64.5507 13.1675 65.322 13.5803C65.7999 13.841 66.6255 13.993 67.6792 14.0039C68.559 14.0039 69.2651 13.9605 70.0363 13.7323C70.7858 13.4934 70.9705 13.0697 70.9705 12.7113C70.9705 12.6244 70.9705 12.3311 70.9488 12.0378Z"
        fill="white"
      />
      <path
        d="M88.0652 7.47537C88.0652 8.74629 87.3483 9.00703 85.871 9.00703H82.6013V6.26964H86.0339C87.3483 6.26964 88.0652 6.38911 88.0652 7.47537ZM89.7706 11.4945C89.7706 10.43 89.1623 9.64786 87.7067 9.41974V9.39806C88.8365 9.10477 89.4448 8.51816 89.4448 7.59484C89.4448 6.07408 88.7061 5.30285 86.6096 5.30285H81.2109V13.9277H86.8269C88.717 13.9277 89.7706 13.0696 89.7706 11.4945ZM88.3802 11.5923C88.3802 12.8197 87.5112 12.9393 86.1643 12.9393H82.6013V9.98462H86.3381C87.9348 9.98462 88.3802 10.4951 88.3802 11.5923Z"
        fill="#03426A"
      />
      <path
        d="M96.8241 11.7986C96.8241 12.2983 96.7915 12.7546 96.2158 12.9609C95.8791 13.0913 95.4011 13.1348 94.5973 13.1348C93.7934 13.1348 93.3155 13.0588 93.0331 12.8741C92.8375 12.7546 92.7615 12.559 92.7615 12.1463C92.7615 11.6249 92.8918 11.3316 93.1525 11.2121C93.3915 11.0926 93.7065 11.0709 94.695 11.0709H96.8241V11.7986ZM98.1385 13.9277V10.5386C98.1385 9.89772 98.1385 9.45237 98.0842 9.1265C98.019 8.78976 97.9104 8.55075 97.6388 8.34436C97.0088 7.85554 95.4663 7.84472 94.7059 7.84472C94.1302 7.84472 92.566 7.90991 91.9903 8.39872C91.7947 8.56166 91.7513 8.75717 91.7513 8.99615C91.7513 9.14823 91.773 9.35457 91.7839 9.62613H93.0548C93.0874 9.47406 93.1091 9.10473 93.2503 8.95266C93.4241 8.78972 93.7174 8.72459 94.5864 8.72459C95.5858 8.72459 96.281 8.75717 96.5743 8.9201C96.8458 9.07218 96.8241 9.39804 96.8241 9.64788V10.2344H93.9021C93.1091 10.2344 92.1966 10.2562 91.697 10.9405C91.4797 11.2338 91.3711 11.614 91.3711 12.1028C91.3711 13.5367 92.2509 14.0146 94.5104 14.0146C95.6944 14.0146 96.4548 13.8299 96.9219 13.428L97.0414 13.9277H98.1385Z"
        fill="#03426A"
      />
      <path
        d="M105.674 13.9277V10.6255C105.674 9.48497 105.619 9.24597 105.348 9.03958C105.087 8.84405 104.566 8.72459 103.773 8.72459C103.078 8.72459 102.361 8.81149 101.687 8.99615V13.9277H100.395V7.88815H101.557L101.655 8.32268C101.904 8.18147 102.1 8.07279 102.382 7.99675C102.806 7.8664 103.349 7.80123 104.077 7.80123C104.903 7.80123 105.543 7.93159 106.032 8.14884C106.51 8.35523 106.771 8.74632 106.879 9.20255C106.945 9.51756 106.966 9.7891 106.966 10.4734V13.9277H105.674Z"
        fill="#03426A"
      />
      <path
        d="M114.104 13.9277L110.986 11.0709H110.519V13.9277H109.227V5.30285H110.519V10.2128H110.986L113.572 7.88815H115.081L112.073 10.5495L115.82 13.9277H114.104Z"
        fill="#03426A"
      />
    </svg>
  )
}

export default DanskeBankIcon
