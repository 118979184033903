import { FunctionComponent, useEffect, useState } from 'react'
import Banner from 'assets/icons/buyBanner.png'
import { useHistory } from 'react-router'
import MobileBanner from 'assets/icons/buyMobileBanner.png'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import Button from 'Components/UiKit/Buttons/Button'
import { selectData } from 'store/user'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont, Colors, ModalContainer } from 'constants/index'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { handleCardRenewal, displayDate, returnPrice, isCardExpired, formatPrice } from 'utils/functions'
import CouponModal from 'Components/Modals/CouponModal'
import { selectPriceData } from 'store/price'
import { viewItem } from 'utils/tagManagerReports'

const BuyBanner: FunctionComponent = () => {
  const [t, { language }] = useTranslation()
  const userInfo = useAppSelector(selectData)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const priceData = useAppSelector(selectPriceData)
  const [showCouponModal, setShowCouponModal] = useState(false)
  const [hasVisits, setHasVisits] = useState(true)
  const [cardExpired, setCardExpired] = useState(false)

  let date = displayDate(userInfo.code.expires_at)

  useEffect(() => {
    if (userInfo.code.expires_at) setHasVisits(true)
    else setHasVisits(false)
    setCardExpired(isCardExpired(userInfo.code.expires_at))
  }, [userInfo])

  useEffect(() => {
    viewItem(priceData.card, priceData.renewal_after_expiry, priceData.digital_gift)
  }, [priceData.card, priceData.renewal_after_expiry, priceData.digital_gift])

  const handleClick = (coupon?: string) => {
    handleCardRenewal('renew', userInfo.code, language, dispatch, coupon)
    history.push('/card-renewal')
  }

  const priceValue = formatPrice(returnPrice(userInfo?.code.expires_at, priceData) / 100, false)
  return (
    <BannerContainer>
      <BannerWrapper>
        <TextWrapper>
          <Text
            textAlign={'left'}
            fontWeight={FontWeight.NORMAL}
            fontFamily={FontFamily.LIGHT}
            fontSize={calculateDynamicFont({
              max: FontSize.FORTY,
              min: FontSize.TWENTY,
            })}
            color={Colors.WHITE}
          >
            {hasVisits
              ? cardExpired
                ? t('buyRenewalCardExpired')
                : t('buyRenewalCard')
              : t('buyRenewalCardFirstVisit')}
          </Text>
          <Text
            textAlign={'left'}
            fontWeight={FontWeight.EIGHT}
            fontFamily={FontFamily.EXTRABOLD}
            fontSize={calculateDynamicFont({
              max: FontSize.FORTY_FOUR,
              min: FontSize.TWENTY_FOUR,
            })}
            color={Colors.WHITE}
          >
            {hasVisits
              ? cardExpired
                ? t('buyRenewalCardExpiredPrice', {
                    price: priceValue,
                  })
                : t('buyRenewalCardPrice', { price: priceValue })
              : t('buyRenewalCardFirstVisitPrice', {
                  price: priceValue,
                })}
          </Text>

          {hasVisits && (
            <ContinueBtn
              text={t('pleaseContinue')}
              fontSize={FontSize.SIXTEEN}
              bgColor={Colors.FRENCH_ROSE}
              onClick={() => setShowCouponModal(true)}
            />
          )}
        </TextWrapper>
        <BannerNotification>
          <Text
            textAlign={'center'}
            fontWeight={FontWeight.SEVEN}
            fontFamily={FontFamily.BOLD}
            fontSize={calculateDynamicFont({
              max: FontSize.SEVENTEEN,
              min: FontSize.FOURTEEN,
            })}
            color={Colors.WHITE}
          >
            {hasVisits
              ? cardExpired
                ? t('discountValidExpired')
                : t('discountValidDate', { date })
              : t('discountValidAfterFirstVisit')}
          </Text>
        </BannerNotification>
      </BannerWrapper>
      <ModalContainer isVisible={showCouponModal} setVisible={() => setShowCouponModal(false)}>
        {showCouponModal && <CouponModal handleClick={handleClick} />}
      </ModalContainer>
    </BannerContainer>
  )
}
export default BuyBanner

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 350px) {
    margin-top: 30px;
  }
`
const BannerWrapper = styled.div`
  background: url(${Banner});
  height: 324px;
  width: 100%;
  max-width: 100%;
  border-radius: 16px;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: 100% 100%;
  padding-left: 60px;
  padding-top: 30px;
  position: relative;
  @media (max-width: 700px) {
    background: url(${MobileBanner});
    height: 390px;
    background-size: 100% 100%;
    padding-left: 29px;
    padding-top: 60px;
  }
  @media (max-width: 350px) {
    padding-top: 75px;
  }
`
const TextWrapper = styled.div`
  max-width: 70%;
  @media (max-width: 350px) {
    max-width: 90%;
  }
`

const ContinueBtn = styled(Button)`
  width: 100% !important;
  height: 50px !important;
  max-width: 263px !important;
  margin-top: 50px;
  @media (max-width: 350px) {
    margin-top: 30px;
  }
`

const BannerNotification = styled.div`
  position: absolute;
  width: 148px;
  height: 148px;
  border-radius: 50%;
  background: ${Colors.ROYAL_BLUE};
  transform: rotate(-5.53deg);
  display: flex;
  align-items: center;
  justify-content: center;
  top: -30px;
  right: calc(30% - calc(100% / 1440) * 150);
  & div {
    max-width: 129px;
  }

  @media (max-width: 700px) {
    width: 122px;
    height: 122px;
    right: -15px;
    top: -40px;
    & div {
      max-width: 100px;
    }
  }
`
