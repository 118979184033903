import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, Colors, calculateDynamicFont } from 'constants/index'
import { isDesktopOrLaptop } from 'constants/responsive-constant'
import AllMethodsContainer from 'Components/AllMethods'

const PaymentMethodsWrapper = () => {
  const { t } = useTranslation<string>()
  const returnTextAlignment = () => {
    return isDesktopOrLaptop() ? 'center' : 'left'
  }
  return (
    <Container>
      <TextsContainer>
        <Text
          textAlign={returnTextAlignment()}
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          fontSize={calculateDynamicFont({
            min: FontSize.TWENTY_FOUR,
            max: FontSize.THIRTY_SIX,
          })}
        >
          {t('whatCanIPay')}
        </Text>
        <Text
          textAlign={returnTextAlignment()}
          fontWeight={FontWeight.NORMAL}
          fontFamily={FontFamily.NORMAL}
          fontSize={calculateDynamicFont({
            min: FontSize.SIXTEEN,
            max: FontSize.TWENTY_EIGHT,
          })}
        >
          {t('whatCanIPayMessage')}
        </Text>
        <Text
          textAlign={returnTextAlignment()}
          fontWeight={FontWeight.NORMAL}
          fontFamily={FontFamily.NORMAL}
          fontSize={calculateDynamicFont({
            min: FontSize.TWELVE,
            max: FontSize.SIXTEEN,
          })}
        >
          {t('whatCanIPaySubMessage')}
        </Text>
      </TextsContainer>
      <AllMethodsContainer />
    </Container>
  )
}
export default PaymentMethodsWrapper

const flexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Container = styled(flexContainer)`
  padding-top: 97px;
  gap: 88px;
  background: ${Colors.WHITE};
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  @media (max-width: 700px) {
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 80px;
    padding-bottom: 50px;
  }
`
const TextsContainer = styled(flexContainer)`
  max-width: 600px;
  gap: 12px;
  & div:nth-child(2) {
    margin-bottom: 28px;
    @media (max-width: 700px) {
      margin-bottom: 24px;
    }
  }
`
