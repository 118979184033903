import { useState, useEffect, VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Skeleton } from 'antd'
import DownArrow from 'assets/icons/downArrow.svg'
import Text from 'Components/UiKit/Text'
import { FontSize, FontFamily, FontWeight } from 'constants/fonts'
import VisiteItem from 'Components/Visited/VisiteItem/VisiteItem'
import { selectVisitsListData, selectVisitsListStatus } from 'store/visits/visitsList'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Pagination, Menu, Dropdown } from 'antd'
import { getVisitsAsync } from 'store/visits/visitsList/asyncActions'
import { Status } from 'models'
import { CardWrapper } from './VisiteItem'
import { selectData } from 'store/user'

const VisitedItems: VoidFunctionComponent<{ isMobile?: boolean }> = ({ isMobile }) => {
  const { t } = useTranslation()
  const visitsData = useAppSelector(selectVisitsListData)
  const visitsListStatus = useAppSelector(selectVisitsListStatus)
  const userInfo = useAppSelector(selectData)
  const dispatch = useAppDispatch()
  const [orderBy, setOrderBy] = useState<'asc' | 'desc'>('desc')
  const [data, setData] = useState([])
  const [page, setPage] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    if (visitsData && visitsData.data && visitsListStatus !== Status.PENDING) {
      setTotal(visitsData.meta.total)
      setData(visitsData.data)
    }
  }, [visitsData, page, visitsListStatus])

  useEffect(() => {
    dispatch(getVisitsAsync({ orderBy, page: page + 1 }))
  }, [dispatch, orderBy, page])

  const onPageChange = (pageNum: number): void => {
    setPage(pageNum - 1)
  }

  const onOrderChange = (e: any) => {
    setOrderBy(e.key)
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={onOrderChange} key="desc">
        {t('newestFirst')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={onOrderChange} key="asc">
        {t('oldestFirst')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Container>
      <VisitedHeader isMobile={isMobile}>
        {/* <div></div> */}
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <div className="d-flex align-items-center pointer">
            <Text
              className="mr-2"
              textAlign="left"
              width="fit-content"
              fontSize={FontSize.SIXTEEN}
              fontWeight={FontWeight.SEVEN}
              fontFamily={FontFamily.BOLD}
            >
              {orderBy === 'desc' ? t('newestFirst') : t('oldestFirst')}
            </Text>
            <FilterStyled>
              <img src={DownArrow} alt="fliter" />
            </FilterStyled>
          </div>
        </Dropdown>
      </VisitedHeader>
      {!visitsData?.data.length && visitsListStatus === Status.PENDING ? (
        <CardWrapper>
          <Skeleton active />
        </CardWrapper>
      ) : data && data.length ? (
        data.map((item: any, index: number) => (
          <VisiteItem
            comment={item?.rating?.data?.comment}
            rate={item?.rating?.data?.rating}
            name={item?.museum?.data?.name}
            ratingId={item?.rating?.data?.id}
            date={item?.created_at}
            orderBy={orderBy}
            userId={userInfo.id}
            visitId={item.id}
            museumId={item?.museum?.data?.id}
            key={index}
            isMobile={isMobile}
          />
        ))
      ) : (
        <Text className="mr-2" fontSize={FontSize.SIXTEEN}>
          {t('emptyVisits')}
        </Text>
      )}

      {data?.length > 0 && (
        <Pagination
          showLessItems
          className="ml-3 mr-3"
          defaultCurrent={1}
          pageSize={6}
          total={total}
          onChange={onPageChange}
        />
      )}
    </Container>
  )
}

export default VisitedItems

const Container = styled.div`
  padding-bottom: 10px;
  flex: 1 1 800px;
  .anticon-right,
  .anticon-left {
    vertical-align: unset;
  }
`
const VisitedHeader = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: end;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '24px' : '14px')};
  gap: 16px;
`
const FilterStyled = styled.div`
  cursor: pointer;
  height: 8px;
`
