import styled from 'styled-components'
import Logo from 'assets/icons/loginIcon.png'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont } from 'constants/index'
import { useTranslation } from 'react-i18next'
import { Login } from './Login'
import { useState } from 'react'
import { loginTypes } from 'constants/types'

const InputContainer = () => {
  const [t] = useTranslation()
  const [type, setType] = useState(loginTypes.LOGIN)

  return (
    <Container>
      <div className="d-flex mb-3 align-items-center">
        <img src={Logo} alt="logo" />
        <Text
          textAlign={'left'}
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY_EIGHT,
            min: FontSize.TWENTY,
          })}
          className="ml-2"
        >
          {t('yourCustomerInfo')}
        </Text>
      </div>
      {type === 'login' ? (
        <>
          <Text textAlign={'left'} fontSize={FontSize.TWENTY} className="mb-5" whiteSpace="break-spaces">
            {t('manageFromOnePlace')}
          </Text>
          <Text textAlign={'left'} fontSize={FontSize.TWENTY} className="mb-5">
            <div dangerouslySetInnerHTML={{ __html: t('campaignText') }} />
          </Text>
        </>
      ) : (
        <Text textAlign={'left'} fontSize={FontSize.TWENTY} className="mb-5" whiteSpace="pre-line">
          {t(`${type}Message`)}
        </Text>
      )}
      <Login setType={(type: loginTypes) => setType(type)} type={type} />
    </Container>
  )
}
export default InputContainer

const Container = styled.div`
  width: 431px;
  display: flex;
  flex-direction: column;
`
