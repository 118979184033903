import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'
import { priceResource } from './priceModel'

export const getPriceAsync: AsyncThunk<priceResource, void, { rejectValue: string }> = createAsyncThunk(
  'web/price/card/webshop',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webPriceCardWebShop()
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
