import { FunctionComponent } from 'react'
import { Header } from 'Components/UiKit/Header'
import { Footer } from 'Components/UiKit/Footer'
import { useTranslation } from 'react-i18next'
import BuyBanner from './Banner'
import BuyCards from './BuyCards'
import InfoBanner from './InfoBanner'
import PaymentMethodsWrapper from './PaymentMethodsWrapper'
import styled from 'styled-components'

const Buy: FunctionComponent = () => {
  const { t } = useTranslation<string>()

  return (
    <div className={'w-100 alice-blue-background-color'}>
      <BodyWrapper>
        <Header title={t('purchase')} subTitle={t('purchaseSubtitle')} />
        <BuyBanner />
        <BuyCards />
        <InfoBanner />
      </BodyWrapper>
      <PaymentMethodsWrapper />
      <div className={'item flex-none item-top-left'}>
        <Footer />
      </div>
    </div>
  )
}
export default Buy

const BodyWrapper = styled.div`
  padding-left: 62px;
  padding-right: 58px;
  width: 100%;
  @media (max-width: 700px) {
    padding-left: 26px;
    padding-right: 20px;
  }
`
