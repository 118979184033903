import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont } from 'constants/index'

const ModalTitleAndMessage = ({
  title,
  message,
  submessage,
}: {
  title: string
  message: string
  submessage?: string
}) => {
  return (
    <>
      <Text
        textAlign={'center'}
        fontWeight={FontWeight.EIGHT}
        width="fit-content"
        fontFamily={FontFamily.EXTRABOLD}
        fontSize={calculateDynamicFont({
          min: FontSize.TWENTY_FOUR,
          max: FontSize.THIRTY_TWO,
        })}
      >
        {title}
      </Text>
      <Text
        textAlign={'center'}
        fontWeight={FontWeight.NORMAL}
        fontFamily={FontFamily.NORMAL}
        fontSize={calculateDynamicFont({
          min: FontSize.EIGHTEEN,
          max: FontSize.TWENTY,
        })}
      >
        {message}
      </Text>
      {submessage && (
        <Text
          textAlign={'center'}
          fontWeight={FontWeight.NORMAL}
          fontFamily={FontFamily.NORMAL}
          fontSize={calculateDynamicFont({
            min: FontSize.EIGHTEEN,
            max: FontSize.TWENTY,
          })}
        >
          {submessage}
        </Text>
      )}
    </>
  )
}
export default ModalTitleAndMessage
