import { ReactElement } from 'react'

function QuestionIcon(props: any): ReactElement {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="#FF598F"
      />
      <path
        d="M12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19Z"
        fill="#FF598F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8815 7.01877C12.0655 6.94217 11.1008 7.10125 10.2563 7.47444L9.34162 7.87865L8.5332 6.04931L9.44787 5.6451C10.5834 5.14329 11.8897 4.91687 13.0684 5.02752C14.2227 5.13588 15.4499 5.59507 16.1198 6.6791C17.3736 8.70809 16.3612 10.8827 14.6865 12.3017C13.1685 13.588 12.9991 14.0069 12.9991 14.4998V15.4998H10.9991V14.4998C10.9991 12.9926 11.9117 12.0315 13.3936 10.7758C14.719 9.65279 14.9146 8.53345 14.4184 7.73044C14.2133 7.39847 13.7219 7.09766 12.8815 7.01877Z"
        fill="#FF598F"
      />
    </svg>
  )
}

export default QuestionIcon
