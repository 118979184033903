const Level3 = (props: any) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" fill="#F1DD2A" />
      <path
        d="M23.7969 31.2637C27.791 31.2637 29.8223 29.9062 29.8223 27.3086V27.2695C29.8223 25.4727 28.9043 24.3984 27.0391 23.9688V23.8027C28.6406 23.3535 29.4219 22.416 29.4219 20.9023V20.8633C29.4219 18.3242 27.498 17.0645 23.709 17.0645C19.7734 17.0645 17.7812 18.5293 17.752 21.6348L21.3359 22.0156C21.3164 20.209 22.1074 19.4082 23.6992 19.4082C25.1152 19.4082 25.8477 20.0332 25.8477 21.2148V21.2539C25.8477 22.377 25.1348 22.9434 23.7578 22.9824L22.3809 23.0215V24.9062L23.7773 24.9551C25.1738 24.9941 26.0527 25.5508 26.0527 26.8887V26.9277C26.0527 28.2461 25.3008 28.9102 23.8359 28.9102C22.1367 28.9102 21.2676 28.0215 21.209 26.0684L17.5859 26.459L17.498 26.4883C17.5762 29.7207 19.6953 31.2637 23.7969 31.2637Z"
        fill="white"
      />
    </svg>
  )
}
export default Level3
