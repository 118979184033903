import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'
import { ElementResource } from './elementModel'

export const getElement: AsyncThunk<Array<ElementResource>, void, { rejectValue: string }> = createAsyncThunk(
  'web/customer-portal/element',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await WebService.webCustomerPortalElementsList()

      return response.data
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
