import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, calculateDynamicFont, FontSize } from 'constants/index'
import { FunctionComponent } from 'react'
import { Space } from 'Components/UiKit/Space'
import styled from 'styled-components'

interface Props {
  image: string
  name: string
  userStatus: string
}

const PersonalInfo: FunctionComponent<Props> = ({ image, name, userStatus }) => {
  return (
    <Container className={'vertical'}>
      <SquareRoundImageContainer>
        {/* <img src="" alt="profile" className={'responsive-image rounded-image object-fit-cover'} /> */}
      </SquareRoundImageContainer>
      <div className={'item flex-2'}>
        <div className={'vertical justify-content-start'}>
          <Space height={16} />
          <div className={'item flex-none'}>
            <Text
              fontFamily={FontFamily.EXTRABOLD}
              textAlign={'center'}
              fontWeight={FontWeight.EIGHT}
              fontSize={calculateDynamicFont({ max: FontSize.TWENTY, min: FontSize.EIGHTEEN })}
            >
              {name}
            </Text>
          </div>
          <Space height={5} />
          <div className={'item flex-none'}>
            <Text textAlign={'center'} fontSize={calculateDynamicFont({ max: FontSize.TWENTY, min: FontSize.SIXTEEN })}>
              {userStatus}
            </Text>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default PersonalInfo

const Container = styled.div`
  order: 1;
  @media (max-width: 700px) {
    order: 3;
  }
`

const SquareRoundImageContainer = styled.div`
  width: 136px;
  height: 136px;
  border-radius: 50%;
`
