export enum ElementTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum RenewalTypes {
  Expired = 'expired',
  Valid = 'valid',
}

export enum loginTypes {
  LOGIN = 'login',
  RESET_REQUEST = 'resetRequest',
  RESET = 'reset',
}
