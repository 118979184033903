import { FunctionComponent, lazy, Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import TagManager from 'react-gtm-module'
import { paths as authPaths } from './Auth'
import { paths as mainPaths } from './Main'
import { LoginGuardRoute } from './Auth/guard'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { MainGuardRoute } from './Main/guard'
import { tokenUtils } from '../utils'
import { Status } from '../models'
import SideBar from '../Components/SideBar'
import SwitchLanguage from '../Components/SideBar/SwitchLanguage'
import MediaQuery from 'react-responsive'
import { ResponsiveConstant } from '../constants'
import languageIcon from '../assets/icons/icon-blob-3@2x.png'
import { useTranslation } from 'react-i18next'
import ShareUrl from 'Components/Visited/ShareUrl'
import { getUserInfoAsync } from 'store/user/asyncActions'
import { deleteUserInfo, selectUserStatus, setStatus as setUserStatus } from 'store/user'
import { getVisitsAsync } from 'store/visits/visitsList/asyncActions'
import { getNews } from 'store/news/asyncActions'
import { Spin } from 'antd'
import { selectLoginStatus, setStatus } from 'store/auth'
import { getAnnouncementAsync } from 'store/announcement/asyncActions'
import { getElement } from 'store/element/asyncActions'
import { getExhibition } from 'store/exhibition/asyncActions'
import Footer from 'Components/CustomComponents/Footer'
import { TAG } from '../constants'
import { getPriceAsync } from 'store/price/asyncActions'
import 'react-toastify/dist/ReactToastify.css'

const AuthRoutes = lazy(() => import('./Auth'))
const MainRoutes = lazy(() => import('./Main'))

function SwitchLanguagePackageInMobile(props: { i18n: any }) {
  return (
    <div className={'position-absolute top-0 end-0 language-icon'}>
      <img alt="" className={'position-absolute top-0 end-0 h-100 w-100'} src={languageIcon} />
      <div className={'position-absolute switch-language-container h-25 w-25'}>
        <SwitchLanguage mobileMode={true} />
      </div>
    </div>
  )
}

const Routes: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const userStatus = useAppSelector(selectUserStatus)
  const authStatus = useAppSelector(selectLoginStatus)
  const { i18n, ready } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    dispatch(getPriceAsync())
    dispatch(getAnnouncementAsync())
    initiateTagManager()
  }, [dispatch])

  useEffect(() => {
    if (location.pathname.includes('/share/')) {
      return null
    }
    const urlParams = new URLSearchParams(history.location.search)
    const token = tokenUtils.getToken()
    if (authStatus !== Status.PENDING) {
      if (token && !urlParams.has('token')) {
        if (userStatus === Status.IDLE) dispatch(getUserInfoAsync())
        if (authStatus !== Status.SUCCEEDED) dispatch(setStatus(Status.SUCCEEDED))
      } else {
        localStorage.removeItem('auth')
        dispatch(setStatus(Status.IDLE))
        dispatch(setUserStatus(Status.IDLE))
        dispatch(deleteUserInfo())
        let pathLng = history.location.pathname.split('/')
        let lng = pathLng[pathLng.length - 1].split('?')[0]
        history.push({
          pathname: `/login/${lng}`,
          state: { from: history.location },
          search: history.location.search,
        })
      }
    }
  }, [dispatch, history, authStatus, location.pathname, userStatus])

  useEffect(() => {
    if (userStatus === Status.SUCCEEDED) {
      dispatch(getVisitsAsync({ orderBy: 'desc', pageSize: 10 }))
      dispatch(getNews())
    }
  }, [dispatch, i18n.language, userStatus])

  useEffect(() => {
    if (userStatus === Status.SUCCEEDED) {
      dispatch(getExhibition())
      dispatch(getElement())
    }
  }, [dispatch, userStatus])

  const initiateTagManager = () => {
    let body = document.body
    let noScript = document.createElement('noscript')
    let iframeSrc = `<iframe
        src="https://www.googletagmanager.com/ns.html?id=${TAG}"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>`
    noScript.append(iframeSrc)
    body.appendChild(noScript)

    const tagManagerArgs = {
      gtmId: TAG,
      dataLayerName: 'dataLayer',
    }
    TagManager.initialize(tagManagerArgs)
  }

  const returnLoader = () => (
    <div className="d-flex p-5 h-100 w-100 align-items-center">
      <Spin size="large" className="w-100" />
    </div>
  )

  return (
    <>
      {ready && (
        <Suspense fallback={returnLoader()}>
          <Switch>
            <Route exact path="/">
              <Redirect to={{ pathname: `/home`, search: history.location.search }} />
            </Route>
            <Route path="/share/:levelNumber/:levelText/:commentText">
              <ShareUrl />
            </Route>
            <MainGuardRoute path={mainPaths}>
              <MediaQuery query={ResponsiveConstant.isTabletOrMobile}>
                <div
                  id="body"
                  className={
                    'item vertical position-relative overflow-y flex-1 align-items-start justify-content-start'
                  }
                >
                  {location.pathname !== '/receipt-form' && <SwitchLanguagePackageInMobile i18n={i18n} />}
                  {userStatus === Status.PENDING ? returnLoader() : <MainRoutes />}
                  <div className="w-100">
                    <Footer />
                  </div>
                </div>
                <SideBar />
              </MediaQuery>
              <MediaQuery query={ResponsiveConstant.isDesktopOrLaptop}>
                <div className="horizontal">
                  <SideBar />
                  <div
                    id="body"
                    className={'item overflow-y flex-1 align-items-start justify-content-start desktop-body'}
                  >
                    {userStatus === Status.PENDING ? returnLoader() : <MainRoutes />}
                  </div>
                </div>
                <Footer />
              </MediaQuery>
            </MainGuardRoute>
            <LoginGuardRoute path={authPaths}>
              <AuthRoutes />
            </LoginGuardRoute>
            <Route exact path={'/:lang'}>
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Suspense>
      )}
    </>
  )
}

export default Routes
