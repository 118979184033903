import { ReactElement } from 'react'

function VisitsIcon(props: any): ReactElement {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0004 3C9.37699 3 6.63672 4.98378 6.63672 8.36364C6.63672 9.10626 6.94801 10.0722 7.51928 11.1768C8.07965 12.2604 8.83827 13.3796 9.61484 14.4019C10.3888 15.4208 11.1652 16.3232 11.7492 16.972C11.8377 17.0702 11.9216 17.1625 12.0004 17.2484C12.0791 17.1625 12.163 17.0702 12.2515 16.972C12.8355 16.3232 13.6119 15.4208 14.3859 14.4019C15.1624 13.3796 15.9211 12.2604 16.4814 11.1768C17.0527 10.0722 17.364 9.10626 17.364 8.36364C17.364 4.98378 14.6237 3 12.0004 3ZM12.0004 18.7045L12.7102 19.4089C12.5225 19.5981 12.2669 19.7045 12.0004 19.7045C11.7338 19.7045 11.4782 19.5981 11.2905 19.4089L12.0004 18.7045ZM4.63672 8.36364C4.63672 3.68894 8.47281 1 12.0004 1C15.5279 1 19.364 3.68894 19.364 8.36364C19.364 9.58465 18.8798 10.8931 18.2579 12.0956C17.6251 13.3192 16.7928 14.5397 15.9785 15.6117C15.1615 16.6872 14.347 17.6336 13.7378 18.3101C13.4328 18.649 13.178 18.9215 12.9986 19.1103C12.9088 19.2047 12.8378 19.2782 12.7888 19.3287L12.7319 19.3869L12.7165 19.4025L12.7122 19.4069L12.7109 19.4082C12.7108 19.4083 12.7102 19.4089 12.0004 18.7045C11.2905 19.4089 11.2906 19.409 11.2905 19.4089L11.2885 19.4069L11.2842 19.4025L11.2688 19.3869L11.2119 19.3287C11.1629 19.2782 11.0919 19.2047 11.0021 19.1103C10.8227 18.9215 10.5679 18.649 10.2629 18.3101C9.65373 17.6336 8.8392 16.6872 8.02223 15.6117C7.20789 14.5397 6.3756 13.3192 5.7428 12.0956C5.12089 10.8931 4.63672 9.58465 4.63672 8.36364Z"
        fill="#FF598F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18164 8.36359C9.18164 6.80715 10.4434 5.54541 11.9998 5.54541C13.5563 5.54541 14.818 6.80715 14.818 8.36359C14.818 9.92003 13.5563 11.1818 11.9998 11.1818C10.4434 11.1818 9.18164 9.92003 9.18164 8.36359ZM11.9998 7.54541C11.548 7.54541 11.1816 7.91172 11.1816 8.36359C11.1816 8.81546 11.548 9.18177 11.9998 9.18177C12.4517 9.18177 12.818 8.81546 12.818 8.36359C12.818 7.91172 12.4517 7.54541 11.9998 7.54541Z"
        fill="#FF598F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53002 16.8115C7.62667 17.3553 7.26421 17.8744 6.72045 17.9711C5.3979 18.2061 4.36416 18.5174 3.68835 18.8502C3.3488 19.0174 3.14901 19.1662 3.04684 19.2727C3.04658 19.2729 3.04633 19.2732 3.04607 19.2735C3.09357 19.3234 3.17862 19.3979 3.32778 19.4941C3.71191 19.7418 4.33402 20.0026 5.19208 20.2366C6.89389 20.7007 9.30124 21.0001 12 21.0001C14.6988 21.0001 17.1061 20.7007 18.8079 20.2366C19.666 20.0026 20.2881 19.7418 20.6722 19.4941C20.8214 19.3979 20.9064 19.3233 20.9539 19.2735C20.9537 19.2732 20.9534 19.2729 20.9532 19.2727C20.851 19.1662 20.6512 19.0174 20.3116 18.8502C19.6358 18.5174 18.6021 18.2061 17.2795 17.9711C16.7358 17.8744 16.3733 17.3553 16.47 16.8115C16.5666 16.2677 17.0858 15.9053 17.6295 16.0019C19.0434 16.2532 20.2823 16.6065 21.1952 17.056C21.6499 17.2799 22.0734 17.5517 22.396 17.8877C22.7227 18.228 23 18.6953 23 19.2729C23 20.179 22.3392 20.7989 21.756 21.1749C21.1281 21.5798 20.2857 21.9066 19.3341 22.1662C17.4166 22.6891 14.824 23.0001 12 23.0001C9.17603 23.0001 6.58338 22.6891 4.66587 22.1662C3.71428 21.9066 2.8719 21.5798 2.24395 21.1749C1.66076 20.7989 1 20.179 1 19.2729C1 18.6953 1.27731 18.228 1.60401 17.8877C1.92655 17.5517 2.35006 17.2799 2.80483 17.056C3.71766 16.6065 4.95665 16.2532 6.37046 16.0019C6.91422 15.9053 7.43337 16.2677 7.53002 16.8115ZM21.0133 19.3481C21.0133 19.3481 21.0126 19.347 21.0117 19.3448C21.013 19.347 21.0134 19.3481 21.0133 19.3481ZM2.98665 19.3481C2.98658 19.3481 2.98706 19.347 2.98834 19.3447C2.98738 19.347 2.98673 19.3481 2.98665 19.3481Z"
        fill="#FF598F"
      />
    </svg>
  )
}

export default VisitsIcon
