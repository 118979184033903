import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont, Colors } from 'constants/index'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

interface MessageProps {
  message: any
}

const Message = ({ message }: MessageProps) => {
  const [t] = useTranslation()

  return (
    <Container>
      <TitleWrapper>
        <Text
          textAlign={'left'}
          fontWeight={FontWeight.SEVEN}
          fontFamily={FontFamily.BOLD}
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY,
            min: FontSize.SIXTEEN,
          })}
        >
          {t('allMessages')}
        </Text>
      </TitleWrapper>
      <MessageContainer>
        <MessageTitle>
          <Initial>
            <Text
              fontWeight={FontWeight.SEVEN}
              fontFamily={FontFamily.BOLD}
              fontSize={calculateDynamicFont({
                max: FontSize.TWENTY,
                min: FontSize.SIXTEEN,
              })}
              color={Colors.FRENCH_ROSE}
            >
              {'M'}
            </Text>
          </Initial>
          <Text
            textAlign={'left'}
            width="fit-content"
            fontWeight={FontWeight.SEVEN}
            fontFamily={FontFamily.BOLD}
            fontSize={calculateDynamicFont({
              max: FontSize.FOURTEEN,
              min: FontSize.FOURTEEN,
            })}
          >
            {message.title}
          </Text>
          <Text
            textAlign={'left'}
            width="fit-content"
            fontSize={calculateDynamicFont({
              max: FontSize.FOURTEEN,
              min: FontSize.FOURTEEN,
            })}
          >
            {dayjs(message.date).format('dddd, MMMM D, YYYY, h:m a')}
          </Text>
        </MessageTitle>
        <Text
          textAlign={'left'}
          fontSize={calculateDynamicFont({
            max: FontSize.SIXTEEN,
            min: FontSize.SIXTEEN,
          })}
        >
          {message.mesage}
        </Text>
      </MessageContainer>
    </Container>
  )
}

export default Message

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 59px;
  max-width: 653px;
`
const TitleWrapper = styled.div`
  margin-bottom: 42px;
`

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const MessageTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 36px;
  & > div:last-child {
    opacity: 0.5;
  }
`
const Initial = styled.div`
  background: ${Colors.YELLOW};
  width: 48px;
  height: 48px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`
