// @flow
import { FunctionComponent } from 'react'
import CheckImage from '../../../assets/icons/check.png'
import styled from 'styled-components'

interface Props {
  isChecked: boolean
  onToggle: () => void
  className?: string
  children?: React.ReactNode
}

export const CheckBox: FunctionComponent<Props> = ({ isChecked, onToggle, className, children }) => {
  return (
    <CheckBoxContainer onClick={onToggle} className={className}>
      {isChecked && <img alt="" className="responsive-image size" src={CheckImage} />}
    </CheckBoxContainer>
  )
}

const CheckBoxContainer = styled.div`
  width: 36px;
  margin-right: 10px;
  height: 36px;
  cursor: pointer;
  border-radius: 10px;
  background-color: white;
  padding: 1%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  border: 1px solid rgba(49, 44, 61, 0.2);
  .size {
    width: 1.5em;
    height: 1.5em;
    flex: 1;
  }
`
