const returnLoginType = (value: string): string => {
  let type = ''
  if (/^\d+$/.test(value)) {
    type = 'code'
  } else {
    type = 'email'
  }
  return type
}

export default returnLoginType
