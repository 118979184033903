import { FunctionComponent } from 'react'
import Text from 'Components/UiKit/Text'
import { FontSize, FontWeight, Colors } from 'constants/index'
import Button from 'Components/UiKit/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { ResponsiveContainer } from 'constants/responsive-constant'
import { generateFieldUuid } from 'utils/functions'
import styled from 'styled-components'

const UpdateAddressButton: FunctionComponent<Props> = ({ onClick }) => {
  const { t } = useTranslation<string>()
  return (
    <Container className={'medium-aquamarine-background-color address-change-card px-5 py-3'}>
      <Text
        width="fit-content"
        textAlign={'left'}
        color={Colors.WHITE}
        fontSize={FontSize.EIGHTEEN}
        fontWeight={FontWeight.EIGHT}
      >
        {t('addressChanged')}
      </Text>
      <BtnContainer
        id={generateFieldUuid('updateYourContactInformation', 'button')}
        text={t('updateYourContactInformation')}
        onClick={onClick}
        bgColor={Colors.TOLOPEA}
        fontSize={FontSize.THIRTEEN}
        fontWeight={FontWeight.NORMAL}
        size={'normal'}
      />
    </Container>
  )
}

export default UpdateAddressButton

interface Props {
  onClick: () => void
}

const Container = styled(ResponsiveContainer)`
  height: 80px;
  flex: none;
  justify-content: space-between;
  @media (max-width: 700px) {
    min-height: 231px;
    height: fit-content;
    padding: 45px 26px !important;
    & > div {
      text-align: center !important;
    }
  }
`
const BtnContainer = styled(Button)`
  width: 258px;
  height: 44px;
  border-radius: 48px;
  @media (max-width: 700px) {
    width: 100%;
  }
`
