import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import printLogo from 'assets/icons/printLogo.png'
import Button from 'Components/UiKit/Buttons/Button'
import { FontSize } from 'constants/index'
import { useLocation } from 'react-router'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getReceiptInfo } from 'store/userTransaction/asyncActions'
import { resetReceipt, selectReceiptData } from 'store/userTransaction'

const ReceiptForm: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const location = useLocation<{ state: string }>()
  const recepit = useAppSelector(selectReceiptData)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const { state } = location.state
    if (state) dispatch(getReceiptInfo(state))
    return () => {
      dispatch(resetReceipt())
    }
  }, [location, dispatch])

  const onSubmit = () => {
    window.print()
  }

  return (
    <Container id="ifmcontentstoprint" className={'vertical justify-content-between '}>
      <table className="no-print">
        <tr>
          <td style={{ width: 300 }}>
            <BtnContainer onClick={onSubmit} fontSize={FontSize.SIXTEEN} text={t('print')} className="mt-3 w-100" />
          </td>
        </tr>
      </table>
      <table cellSpacing="0" cellPadding="0" id="header" className="tableStyle">
        <tr>
          <td style={{ width: 300 }}>
            <img src={printLogo} alt="" className="img-max-size" />
            <span className="bold span-header">{t('receipt')}</span>
          </td>
          <td style={{ width: 300 }} align="right" className="bold td-date">
            {recepit?.date}
          </td>
        </tr>
      </table>
      <table cellSpacing="0" cellPadding="0" className="table-margin">
        <tr>
          <td style={{ width: 390 }}></td>
          <td align="left" style={{ width: 210 }}>
            <span className="span-number-style">
              {t('receiptNumber')}
              {recepit?.invoice_number}
            </span>
            <br />
            <span className="span-number-style">{t('receiptPayment')}</span>
          </td>
        </tr>
      </table>
      <table cellSpacing="0" cellPadding="0" className="table-margin">
        <tr>
          <td align="left" style={{ width: 600 }}>
            <span>{recepit?.name}</span>
            <br />
            <span>{recepit?.phone}</span>
            <br /> <br />
            <span>{recepit?.street}</span>
            <br />
            <span>
              {recepit?.zipcode} {recepit?.city}
            </span>
          </td>
        </tr>
      </table>
      <table cellSpacing="0" cellPadding="0" style={{ width: 600, border: 0 }} className="table-margin-120">
        <tr style={{ textAlign: 'left' }}>
          <th>{t('receiptCardNumber')}</th>
          <th>{t('productPersonal')}</th>
          <th>{t('card')}</th>
          <th>{t('count')}</th>
          <th>{t('vat')}</th>
          <th>{t('totalBefore')}</th>
        </tr>
        <tr>
          <td id="code">{recepit?.code}</td>
          <td>{t('museumCard')}</td>
          <td> {recepit?.price} &#8364;</td>
          <td>1</td>
          <td>0</td>
          <td>{recepit?.price} &#8364;</td>
        </tr>
      </table>

      <table cellSpacing="0" cellPadding="0" className="table-margin-20">
        <tr>
          <td style={{ width: 470 }}></td>
          <td style={{ width: 130 }} align="left">
            <span>
              {t('taxFree')} {recepit?.price} &#8364;
            </span>
            <br />
            <span>{t('vatTotal')} 0,00 &#8364;</span>
            <br />
            <span>
              {t('totalAfter')} {recepit?.price} &#8364;
            </span>
          </td>
        </tr>
      </table>
      <table cellSpacing="0" cellPadding="0" id="footer" className="footer-table">
        <tr>
          <td align="left" valign="top" style={{ width: 200 }}>
            <span>FMA Creations Oy</span>
            <br />
            <span>Annankatu 16 B 50</span>
            <br />
            <span>00120 Helsinki</span>
            <br />
          </td>
          <td align="left" valign="top" style={{ width: 200 }}>
            <span>{t('customerService')}:</span>
            <br />
            <span>museokortti@museot.fi</span>
            <br />
            <span>044 784 5745 (ark. klo 9 - 12)</span>
            <br />
          </td>
          <td align="left" valign="top" style={{ width: 200 }}>
            <span>{t('businessNumber')}: 2673987-4</span>
          </td>
        </tr>
      </table>
    </Container>
  )
}

const Container = styled.div`
  overflow: scroll;
  padding: 20px;
  table {
    min-width: 40%;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  th {
    font-weight: 600;
  }

  td {
    max-width: 600px;
  }

  .bold {
    font-weight: 700;
  }

  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }

  @page {
    size: auto; /* auto is the initial value */
    margin: 0mm; /* this affects the margin in the printer settings */
  }
  .tableStyle {
    padding: 50px 0px 20px 0px;
    border-bottom: 1px solid #000;
  }
  .span-header {
    font-size: 1.4em;
    margin-left: 25px;
  }
  .img-max-size {
    max-width: 220px;
  }
  .td-date {
    padding-top: 70px;
    font-size: 1.4em;
  }
  .table-margin {
    margin-top: 35px;
  }
  .span-number-style {
    font-size: 1em;
    margin-left: 25px;
  }
  .table-margin-20 {
    margin-top: 20px;
  }
  .footer-table {
    border-top: 1px solid red;
    margin-top: 150px;
    padding: 20px 0px 50px 0px;
  }
  .table-margin-120 {
    margin-top: 120px;
  }
  .margin-bottom {
    margin-bottom: 18px;
  }
`

export default ReceiptForm

const BtnContainer = styled(Button)`
  height: 62px;
  border: none;
`
