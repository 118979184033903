import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AppStore, GooglePlay } from 'assets/iconComponents'
import IphoneIcon from 'assets/icons/iphone.svg'
import Text from 'Components/UiKit/Text'
import { FontSize, FontWeight, Colors, FontFamily } from 'constants/index'
import Button from 'Components/UiKit/Buttons/Button'

const MobileFooter: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  return (
    <Container>
      <CardWrapper>
        <img className="mb-2" src={IphoneIcon} alt="app store" />
        <Text fontSize={FontSize.EIGHTEEN} fontWeight={FontWeight.EIGHT} fontFamily={FontFamily.EXTRABOLD}>
          {t('Haluatko juuri sinulle räätälöityä sisältöä?')}
        </Text>
        <BtnContainer fontSize={FontSize.THIRTEEN} text={t('Kerro meille itsestäsi')} onClick={() => {}} />
      </CardWrapper>
      <Text fontSize={FontSize.EIGHTEEN} fontWeight={FontWeight.EIGHT} fontFamily={FontFamily.EXTRABOLD}>
        {t('Lataa Museokortti-sovellus')}
      </Text>
      <ImagesStyled>
        <AppStore
          className="logo"
          onClick={() => window.open('https://apps.apple.com/us/app/museokortti/id1513240463')}
        />
        <GooglePlay
          className="logo"
          onClick={() => window.open('https://play.google.com/store/apps/details?id=fi.museot.app')}
        />
      </ImagesStyled>
    </Container>
  )
}

export default MobileFooter

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
`
const ImagesStyled = styled.div`
  flex-direaction: row;
  margin-top: 20px;
  .logo:first-child {
    margin-right: 13px;
  }
  @media (max-width: 350px) {
    .logo {
      height: 40px;
      width: 136px;
      &:first-child {
        margin-right: 0px;
      }
    }
  }
`
const CardWrapper = styled.div`
  width: 100$;
  height: 228px;
  background: ${Colors.WHITE};
  border-radius: 10px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  padding-left: 12px;
`

const BtnContainer = styled(Button)`
  width: 217px;
  margin-top: 16px;
  border: none;
`
