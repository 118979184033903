const NextLongArrow = (props: any) => {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 5H8.48182C9.3 5 9.70909 5 10.2545 5.13636V5C9.84545 4.59091 9.43636 4.45455 9.02727 4.18182L8.34545 3.63636V0.5L14.7545 6.22727V6.77273L8.34545 12.5V9.36364L9.16363 8.81818C9.57273 8.54545 9.84545 8.40909 10.3909 8.13636V8C9.70909 8 9.3 8 8.48182 8H0V5Z"
        fill="#FF598F"
      />
    </svg>
  )
}

export default NextLongArrow
