import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import Button from 'Components/UiKit/Buttons/Button'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont, Colors, ElementTypes } from 'constants/index'
import { useTranslation } from 'react-i18next'

interface CardProps {
  title: string
  message: string
  btnLabel: string
  background?: string
  action: () => void
}
const Card = ({ title, message, btnLabel, background, action }: CardProps) => {
  const [t] = useTranslation()
  return (
    <Container style={{ background: background ? Colors.ROYAL_BLUE : Colors.WHITE }}>
      <Wrapper>
        <Text
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          fontSize={calculateDynamicFont({
            max: FontSize.THIRTY_TWO,
            min: FontSize.TWENTY,
          })}
        >
          {t(title)}
        </Text>
        <Text
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY,
            min: FontSize.SIXTEEN,
          })}
        >
          {t(message)}
        </Text>
        <BtnContainer
          text={t(btnLabel)}
          fontSize={FontSize.THIRTEEN}
          buttonType={background ? ElementTypes.PRIMARY : ElementTypes.SECONDARY}
          bgColor={background ? Colors.DARK_PURPLE : Colors.FRENCH_ROSE}
          onClick={action}
        />
      </Wrapper>
    </Container>
  )
}
export default Card

const Container = styled.div`
  width: 600px;
  height: 452px;
  padding: 20px;
  flex: 1 1 452px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  @media (max-width: 700px) {
    height: 274px;
  }
`
const Wrapper = styled.div`
  max-width: 445px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`
const BtnContainer = styled(Button)`
  min-width: 218px;
  padding: 0 35px;
  width: fit-content;
`
