import InfoIcon from 'assets/icons/icon-notion-32.png'
import Text from 'Components/UiKit/Text'
import { Colors } from 'constants/index'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { selectData } from 'store/user'
import { useAppSelector } from 'store/hooks'
import { displayDate } from 'utils/functions'

const NotificationBanner: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const userInfo = useAppSelector(selectData)

  function isNotificationNecessary(): boolean {
    if (userInfo.has_standing_order && userInfo.standing_order_date) {
      const date = new Date(userInfo.standing_order_date)
      const today = new Date()
      const difference = date.getTime() - today.getTime()
      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      return days < 30
    }
  }
  if (isNotificationNecessary()) {
    return (
      <div className={'item flex-none'}>
        <div className={'horizontal royal-blue-background tab2-card'}>
          <div className={'item flex-none'}>
            <img alt="" src={InfoIcon} className={'responsive-image'} />
          </div>
          <div className={'item'}>
            <Text color={Colors.WHITE} textAlign={'left'}>
              {t('nextPaymentIsDue', { date: displayDate(userInfo.standing_order_date) })}
            </Text>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}
export default NotificationBanner
