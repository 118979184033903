import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Spin } from 'antd'
import { Colors } from 'constants/index'

interface ContainerStyledProps {
  onClick?: () => void
  key: number
  disabled?: boolean
  style: any
}

const PaymentCardsWrapper = ({
  className,
  cards,
  handleSubmit,
  loading,
}: {
  className: string
  cards: any[]
  handleSubmit?: ({ action, fields, method, group }: { action: string; fields: {}; method: {}; group: string }) => void
  loading: boolean
}) => {
  const [selectedCard, setSelectedCard] = useState(null)

  useEffect(() => {
    return () => {
      setSelectedCard(null)
    }
  }, [setSelectedCard])

  const handleSelectedChange = (index: number, card: { action_url: string; fields: {}; method: {}; group: '' }) => {
    handleSubmit({
      action: card.action_url,
      fields: card.fields,
      method: card.method,
      group: card.group,
    })
    setSelectedCard(index)
  }

  return (
    <CardsContainer className={className}>
      {cards.map((card, index) => {
        const Icon = card.icon
        return (
          <CardWrapper
            style={{ borderColor: selectedCard === index ? Colors.ROYAL_BLUE : Colors.LIGHT_GREY }}
            onClick={() => !loading && handleSelectedChange(index, card)}
            key={index}
            disabled={loading && selectedCard !== index ? true : false}
          >
            <Icon className="img" />
            {loading && selectedCard === index && <SpineStyled size="small" />}
          </CardWrapper>
        )
      })}
    </CardsContainer>
  )
}
export default PaymentCardsWrapper

const CardsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0px;
`
const CommonBox = css`
  width: 175px;
  height: 175px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CardWrapper = styled.div<ContainerStyledProps>`
  ${CommonBox};
  position: relative;
  border: 3px solid;
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.4' : '1')};
  @media (max-width: 700px) {
    width: 120px;
    height: 120px;
    & .img {
      transform: scale(0.7);
    }
  }
`
const SpineStyled = styled(Spin)`
  ${CommonBox};
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  .ant-spin-dot {
    width: 45px !important;
    height: 45px !important;
  }
  .ant-spin-dot-item {
    width: 25px !important;
    height: 25px !important;
    background-color: ${Colors.FRENCH_ROSE} !important;
  }
`
