import { useEffect } from 'react'
import ProfileSummaryCards from './ProfileSummaryCards'
import MergeOrChangePayment from './MergeOrChangePayment/MergeOrChangePayment'
import UpdateAddressButton from './UpdateAddressButton'
// import Interests from './Interests'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import {
  resetMessages,
  selectPaymentFormsError,
  selectPaymentFormsStatus,
  selectPaymentFormsSuccess,
} from 'store/payment'
import { toast } from 'react-toastify'
import { returnErrorMessageContent } from 'utils/functions'
import { Status } from 'models'
import styled from 'styled-components'

const ProfileSummary = ({ onChange }: { onChange?: any }) => {
  const mergeError = useAppSelector(selectPaymentFormsError)
  const successMessage = useAppSelector(selectPaymentFormsSuccess)
  const status = useAppSelector(selectPaymentFormsStatus)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (status === Status.FAILED) {
      toast.error(returnErrorMessageContent(mergeError)[0])
    }
    if (status === Status.SUCCEEDED) {
      toast.success(successMessage)
    }
    return () => {
      dispatch(resetMessages())
    }
  }, [mergeError, successMessage, dispatch, status])

  const handleContactInfoRedirect = () => {
    document.getElementById('body').scrollTo({ top: 0, behavior: 'smooth' })
    onChange('3')
  }
  return (
    <Container>
      <ProfileSummaryCards />
      <MergeOrChangePayment />
      <UpdateAddressButton onClick={handleContactInfoRedirect} />
      {/* <Interests onClick={() => {}} /> */}
    </Container>
  )
}
export default ProfileSummary

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 700px) {
    height: fit-content;
  }
`
