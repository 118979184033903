import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { getUserInfoAsync, updateUserSettingUnsubscribe, updateUserInfoAsync } from './asyncActions'
import { UserInfo } from './models/UserInfo'
import { Status } from 'models'

export interface UserState {
  data: UserInfo | null
  status: Status
  error: string | any
  updateStatus: Status
}

const initialState: UserState = {
  data: null,
  error: null,
  status: Status.IDLE,
  updateStatus: Status.IDLE,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload
    },
    deleteStatus: state => {
      state.status = Status.IDLE
    },
    deleteUserInfo: state => {
      state.data = null
    },
    deleteError: state => {
      state.error = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getUserInfoAsync.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.status = Status.SUCCEEDED
        state.updateStatus = Status.IDLE
      })
      .addCase(updateUserInfoAsync.fulfilled, (state, action) => {
        state.updateStatus = Status.SUCCEEDED
      })
      .addMatcher(isAnyOf(getUserInfoAsync.pending, updateUserSettingUnsubscribe.pending), state => {
        state.error = null
        state.status = Status.PENDING
      })
      .addMatcher(
        isAnyOf(updateUserInfoAsync.rejected, getUserInfoAsync.rejected, updateUserSettingUnsubscribe.rejected),
        (state, action) => {
          state.status = Status.FAILED
          state.error = action.payload ?? 'Unknown Error'
          state.updateStatus = Status.FAILED
        }
      )
  },
})

export const { setStatus, deleteStatus, deleteUserInfo, deleteError } = userSlice.actions

export const selectData = (state: RootState): UserState['data'] => state.user.data
export const selectUserError = (state: RootState): UserState['error'] => state.user.error
export const selectUserStatus = (state: RootState): UserState['status'] => state.user.status
export const selectUpdateUserStatus = (state: RootState): UserState['updateStatus'] => state.user.updateStatus

export default userSlice.reducer
