import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, Colors, ElementTypes, calculateDynamicFont } from 'constants/index'
import { Container, BodyWrapper, BtnContainer } from './ModalStyledComponents'
import ModalTitleAndMessage from './ModalTitleAndMessage'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateUserSettingUnsubscribe, getUserInfoAsync } from 'store/user/asyncActions'
import { selectData } from 'store/user'
import { handleCardRenewal, generateFieldUuid } from 'utils/functions'

interface StandingOrderModalProps {
  action: () => void
}

const StandingOrderModal = ({ action }: StandingOrderModalProps) => {
  const [t, { language }] = useTranslation()
  const userInfo = useAppSelector(selectData)
  const dispatch = useAppDispatch()
  const initialTitleState = {
    title: t('standingOrder'),
    message: t('standingOrderModalMessage'),
    submessage: t('standingOrderModalSubMessage'),
  }

  const handleClick = async () => {
    if (!userInfo.has_standing_order) handleOpenPaymentMethodModel()
    else {
      await dispatch(updateUserSettingUnsubscribe())
      dispatch(getUserInfoAsync())
    }
  }

  const handleOpenPaymentMethodModel = () => {
    handleCardRenewal('add_card', userInfo.code, language, dispatch)
    action()
  }
  return (
    <Container>
      <BodyWrapper>
        <ModalTitleAndMessage {...initialTitleState} />
        <Text
          textAlign={'center'}
          fontWeight={FontWeight.SEVEN}
          fontFamily={FontFamily.BOLD}
          fontSize={calculateDynamicFont({
            min: FontSize.EIGHTEEN,
            max: FontSize.TWENTY,
          })}
        >
          {!userInfo.has_standing_order ? t('standingOrderModalInfo') : t('standingOrderActivatedModalInfo')}
        </Text>
        <BtnContainer
          id={generateFieldUuid(
            !userInfo.has_standing_order ? 'standingOrderBtn' : 'deactivateStandingOrderBTn',
            'button'
          )}
          buttonType={ElementTypes.PRIMARY}
          text={!userInfo.has_standing_order ? t('standingOrderBtn') : t('deactivateStandingOrderBTn')}
          fontSize={FontSize.SIXTEEN}
          fontFamily={FontFamily.EXTRABOLD}
          fontWeight={FontWeight.EIGHT}
          bgColor={Colors.FRENCH_ROSE}
          onClick={handleClick}
        />
      </BodyWrapper>
    </Container>
  )
}
export default StandingOrderModal
