import { ReactElement } from 'react'

function HomeIcon(props: any): ReactElement {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 1L1 9V22H8V15H14V22H21V9L11 1Z"
        stroke="#FF598F"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HomeIcon
