import { combineReducers } from '@reduxjs/toolkit'
import commentReducer from './comment'
import ratingReducer from './rating'
import visitsListReducer from './visitsList'

export const visitsReducers = combineReducers({
  visitList: visitsListReducer,
  rating: ratingReducer,
  comment: commentReducer,
})
