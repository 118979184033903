import { Colors } from 'constants/index'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize } from 'constants/index'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectPriceData } from 'store/price'
import { useEffect } from 'react'
import { getPriceAsync } from 'store/price/asyncActions'
import { formatPrice } from 'utils/functions'

const LoginFooter = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const priceData = useAppSelector(selectPriceData)

  useEffect(() => {
    dispatch(getPriceAsync())
  }, [dispatch])

  return (
    <Container>
      <Item>
        <Text
          textAlign={'left'}
          fontWeight={FontWeight.SEVEN}
          fontFamily={FontFamily.BOLD}
          fontSize={FontSize.EIGHTEEN}
        >
          {t('extensionOfMuseumCard')}
        </Text>
        <Text textAlign={'left'} fontSize={FontSize.SIXTEEN} className="mt-2">
          {t('youCanRenew', { price: formatPrice(priceData?.renewal_before_expiry / 100, false) })}
        </Text>
      </Item>
      <Item>
        <Text
          textAlign={'left'}
          fontWeight={FontWeight.SEVEN}
          fontFamily={FontFamily.BOLD}
          fontSize={FontSize.EIGHTEEN}
        >
          {t('combiningMuseumCards')}
        </Text>
        <Text textAlign={'left'} fontSize={FontSize.SIXTEEN} className="mt-2">
          {t('youCanCombine')}
        </Text>
      </Item>
    </Container>
  )
}
export default LoginFooter

const Container = styled.div`
  width: 100%px;
  height: 307px;
  background: ${Colors.WHITE_GREY};
  display: flex;
  justify-content: center;
  gap: 48px;
  padding: 71px 117px 76px;
  flex-wrap: wrap;
  @media (max-width: 700px) {
    height: fit-content;
    padding: 20px;
  }
`
const Item = styled.div`
  width: 512px;
  flex: 1 1 500px;
`
