import { CSSProperties, FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import Text from '../Text'
import { Colors } from '../../../constants'
import { FontSize, FontWeight, FontFamily } from '../../../constants/fonts'
import LongArrow from '../../../assets/icons/longArrow.svg'

interface RightconStyledProps {
  rightIconStyle?: CSSProperties
}

interface Props extends RightconStyledProps {
  text: string
  color?: Colors
  size?: FontSize | string
  onClick?: () => void
  rightIcon?: ReactNode
  containerClassName?: string
  containerStyle?: CSSProperties
  longArrow?: boolean
  width?: string
  display?: string
  fontWeight?: FontWeight
  textAlign?: 'right' | 'left' | 'center'
  fontFamily?: FontFamily
  shortIcon?: boolean
  className?: CSSProperties
  id?: string
}

const TextBotton: FunctionComponent<Props> = ({
  text,
  onClick = () => {},
  size,
  color = Colors.FRENCH_ROSE,
  rightIconStyle,
  rightIcon,
  containerClassName,
  containerStyle,
  longArrow,
  width,
  display,
  fontWeight = FontWeight.SEVEN,
  textAlign = 'center',
  fontFamily = FontFamily.NORMAL,
  shortIcon,
  className,
  id,
}: Props) => {
  return (
    <ButtonContainerStyled
      className={`${containerClassName} ${className}`}
      style={{ ...containerStyle }}
      onClick={onClick}
      id={id}
    >
      <Text
        fontFamily={fontFamily}
        width={width}
        display={display}
        textAlign={textAlign}
        fontWeight={fontWeight}
        color={color}
        fontSize={size}
      >
        {text}
        {shortIcon && ' ›'}
      </Text>
      {rightIcon && <RightIconStyled style={{ ...rightIconStyle }}>{rightIcon}</RightIconStyled>}
      {longArrow && <IconStyled src={LongArrow} />}
    </ButtonContainerStyled>
  )
}

export default TextBotton

const ButtonContainerStyled = styled.button`
  background-color: ${Colors.TRANSPARENT};
  border: none;
  outline: none;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const RightIconStyled = styled.div<RightconStyledProps>`
  margin-left: 5px;
`
const IconStyled = styled.img<RightconStyledProps>`
  margin-left: 5px;
`
