import { useEffect, useState, VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import Text from 'Components/UiKit/Text'
import { FontFamily, FontSize, FontWeight, Colors, ModalContainer } from 'constants/index'
import { UserInfo } from 'store/user/models/UserInfo'
import { checkBtnAccess, displayDate, handleCardRenewal, isCardExpired } from 'utils/functions'
import CustomerCard from 'Components/UiKit/CustomerCard'
import { useDispatch } from 'react-redux'
import { logoutRequestAsync, deactivateRequestAsync } from 'store/auth/asyncActions'
import { useHistory } from 'react-router'
import NewsAndRelease from './NewsAndRelease'
import languageIcon from 'assets/icons/icon-blob-3@2x.png'
import CouponModal from 'Components/Modals/CouponModal'
import { definedLevel } from 'utils/definedLevel'
import { generateFieldUuid } from 'utils/functions'
import DeactivateUserModal from 'Components/Modals/DeactivateUserModal'

interface Props {
  userInfo: UserInfo
}
const Notice: VoidFunctionComponent<Props> = ({ userInfo }) => {
  const [t, { language }] = useTranslation<string>()
  const dispatch = useDispatch()
  const history = useHistory()
  const [showCouponModal, setShowCouponModal] = useState(false)
  const [definedLevelResult, setDefinedLevelResult] = useState({
    neededVisits: 0,
    levelNumber: 0,
    levelText: '',
    nextLevel: '',
  })
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)

  useEffect(() => {
    const definedLevelResult = definedLevel(userInfo?.visits_count)
    setDefinedLevelResult(definedLevelResult)
  }, [userInfo?.visits_count])

  function logout() {
    dispatch(logoutRequestAsync())
  }
  const deactivate = () => {
    dispatch(deactivateRequestAsync())
  }

  const redirectToProfile = () => {
    history.push('/profile')
  }

  const handleClick = (coupon?: string) => {
    handleCardRenewal('renew', userInfo.code, language, dispatch, coupon)
    history.push('/card-renewal')
  }

  return (
    <Container>
      <Wrapper>
        <img alt="" className={'position-absolute top-0 end-0 language-icon'} src={languageIcon} />
        <div className={'cardNumber flex-3 position-relative'}>
          <CustomerCard cardNumber={userInfo.code.code} />
        </div>
        <UserData>
          <Text
            id="somethinggg"
            fontSize={FontSize.SIXTEEN}
            fontWeight={FontWeight.SEVEN}
            fontFamily={FontFamily.BOLD}
            className="mb-2"
          >
            {userInfo.code.expires_at
              ? isCardExpired(userInfo.code.expires_at)
                ? t('cardExpired', { date: displayDate(userInfo.code.expires_at) })
                : t('cardValidUntil', { date: displayDate(userInfo.code.expires_at) })
              : t('noExpiryDateMessage')}
          </Text>
          {userInfo.has_standing_order && (
            <Text
              fontSize={FontSize.SIXTEEN}
              fontWeight={FontWeight.SEVEN}
              fontFamily={FontFamily.BOLD}
              className="mb-2"
            >
              {t('cardAutoRenewMessage', { date: userInfo.standing_order_date })}
            </Text>
          )}
          {checkBtnAccess(userInfo) && (
            <TextBotton
              size={FontSize.SIXTEEN}
              fontWeight={FontWeight.SEVEN}
              fontFamily={FontFamily.BOLD}
              onClick={() => setShowCouponModal(true)}
              text={t('renewYourMuseomCard')}
            />
          )}
          <SquareRoundImageContainer>
            {/* <img src={userInfo.photo} alt="profile" className={'responsive-image rounded-image object-fit-cover'} /> */}
          </SquareRoundImageContainer>
          <Text
            fontWeight={FontWeight.EIGHT}
            fontFamily={FontFamily.EXTRABOLD}
            fontSize={FontSize.FOURTEEN}
            className="mb-1"
          >
            {userInfo.firstname} {userInfo.lastname}
          </Text>
          <NameStyled>
            <Text fontSize={FontSize.SIXTEEN}>{`${t(definedLevelResult.levelText)}${t('slashVisitor')}`}</Text>
          </NameStyled>
          <TextBotton
            id={generateFieldUuid('customerInformation', 'home-right-side')}
            fontFamily={FontFamily.BOLD}
            size={FontSize.SIXTEEN}
            onClick={redirectToProfile}
            text={t('customerInformation')}
            containerClassName="mb-1"
          />
          <TextBotton
            id={generateFieldUuid('signOut', 'button')}
            fontFamily={FontFamily.BOLD}
            size={FontSize.SIXTEEN}
            onClick={logout}
            text={t('signOut')}
          />
          <TextBotton
            id={generateFieldUuid('signOut', 'button')}
            fontFamily={FontFamily.BOLD}
            size={FontSize.SIXTEEN}
            onClick={() => setShowDeactivateModal(true)}
            text={t('deleteYourCustomerAccount')}
          />
        </UserData>
        <NewsAndRelease />
      </Wrapper>
      <ModalContainer isVisible={showCouponModal} type={'combine'} setVisible={() => setShowCouponModal(false)}>
        <CouponModal handleClick={handleClick} />
      </ModalContainer>
      <ModalContainer isVisible={showDeactivateModal} type={'combine'} setVisible={() => setShowDeactivateModal(false)}>
        <DeactivateUserModal handleClick={deactivate} setVisible={() => setShowDeactivateModal(false)} />
      </ModalContainer>
    </Container>
  )
}

export default Notice

const Container = styled.div`
  background: ${Colors.WHITE};
  height: 100%;
  padding: 42px 40px;
  overflow-y: auto;
  max-width: 332px;
  flex: 1 1 200px;
  position: relative;
  .cardNumber {
    z-index: 99;
    position: relative;
    .customCard {
      width: 100%;
      height: 161px;
    }
  }
  .language-icon {
    width: 200px;
    height: 200px;
  }
`

const UserData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
`
const SquareRoundImageContainer = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin-top: 33px;
  margin-bottom: 10px;
`
const NameStyled = styled.div`
  margin-bottom: 12px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
`
