import styled from 'styled-components'
import InputContainer from './InputCard'
import LoginFooter from './LoginFooter'
import MuseumPageCard from './MuseumPageCard'
import ChangeLanguage from './ChangeLanguage'

const Login = () => {
  return (
    <Container>
      <BodyWrapper>
        <ChangeLanguage />
        <InputContainer />
        <MuseumPageCard />
      </BodyWrapper>
      <LoginFooter />
    </Container>
  )
}
export default Login

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: scroll;
  position: relative;
`
const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 80px 0px 50px;
  gap: 127px;
  flex-wrap: wrap;
  padding: 20px 117px;
  & > div {
    flex: 1 1 400px;
  }
  @media (max-width: 700px) {
    padding: 20px;
  }
`
