import { FunctionComponent, CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'
import Text from '../Text'
import { Colors, ElementTypes } from '../../../constants'
import { FontWeight, FontFamily } from 'constants/fonts'

function textColor(type: ElementTypes): Colors {
  switch (type) {
    case ElementTypes.PRIMARY:
    case ElementTypes.SECONDARY:
      return Colors.WHITE
    case ElementTypes.TERTIARY:
      return Colors.FRENCH_ROSE
    default:
      return Colors.WHITE
  }
}

function buttonBGColor(type: ElementTypes): Colors {
  switch (type) {
    case ElementTypes.PRIMARY:
      return Colors.FRENCH_ROSE
    case ElementTypes.SECONDARY:
      return Colors.ROYAL_BLUE
    case ElementTypes.TERTIARY:
      return Colors.TRANSPARENT
    default:
      return Colors.FRENCH_ROSE
  }
}

interface LeftIconStyledProps {
  leftIconStyle?: CSSProperties
}

interface ButtonContainerStyledProps {
  buttonType?: ElementTypes
  size?: 'normal' | 'small'
  bgColor?: Colors
}

interface Props extends ButtonContainerStyledProps, LeftIconStyledProps {
  text: string
  onClick: () => void
  leftIcon?: ReactNode
  containerClassName?: string
  fontClassName?: string
  containerStyle?: CSSProperties
  className?: string
  fontSize?: string
  fontWeight?: FontWeight
  fontFamily?: FontFamily
  disabled?: boolean
  isLoading?: boolean
  id?: string
  whiteSpace?: string
}

const Button: FunctionComponent<Props> = ({
  text,
  onClick,
  buttonType = ElementTypes.PRIMARY,
  size = 'small',
  bgColor,
  leftIconStyle,
  leftIcon,
  containerClassName,
  className,
  containerStyle,
  fontSize,
  fontWeight = FontWeight.EIGHT,
  fontFamily = FontFamily.EXTRABOLD,
  fontClassName,
  disabled,
  isLoading,
  id,
  whiteSpace,
}: Props) => {
  return (
    <ButtonContainerStyled
      className={containerClassName || className}
      style={{ ...containerStyle }}
      onClick={onClick}
      size={size}
      buttonType={buttonType}
      bgColor={bgColor}
      disabled={disabled}
      id={id}
    >
      {leftIcon && <LeftIconStyled style={{ ...leftIconStyle }}>{leftIcon}</LeftIconStyled>}
      {isLoading ? (
        <SpineStyled size="small" />
      ) : (
        <Text
          className={fontClassName}
          textAlign="center"
          fontWeight={fontWeight}
          fontFamily={fontFamily}
          color={textColor(buttonType)}
          fontSize={fontSize}
          whiteSpace={whiteSpace}
        >
          {text}
        </Text>
      )}
    </ButtonContainerStyled>
  )
}

export default Button

const ButtonContainerStyled = styled.button<ButtonContainerStyledProps>`
  background-color: ${({ buttonType, bgColor, disabled }): Colors =>
    disabled ? Colors.GREY_CHATEAU : bgColor ? bgColor : buttonBGColor(buttonType)};
  justify-content: center;
  align-items: center;
  height: ${({ size }): string => (size === 'normal' ? '50px' : '40px')};
  border-radius: ${({ size }): string => (size === 'normal' ? '30px' : '24px')};
  border-width: ${({ bgColor }): string => (bgColor ? '0' : '1px')};
  border-color: ${({ buttonType, disabled }): Colors =>
    disabled ? Colors.GREY_CHATEAU : buttonType === ElementTypes.SECONDARY ? Colors.ROYAL_BLUE : Colors.FRENCH_ROSE};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
`
const LeftIconStyled = styled.div<LeftIconStyledProps>`
  margin-right: 10px;
  justify-content: center;
  align-items: center;
`
const SpineStyled = styled(Spin)`
  .ant-spin-dot {
    width: 45px !important;
    height: 45px !important;
  }
  .ant-spin-dot-item {
    width: 25px !important;
    height: 25px !important;
    background-color: ${Colors.ALICE_BLUE} !important;
  }
`
