import { useEffect, useState } from 'react'
import { Collapse, Spin } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { FontFamily, FontSize, calculateDynamicFont, FontWeight } from 'constants/index'
import ArrowUp from 'assets/icons/ArrowUp.png'
import { selectPaymentFormsData, selectPaymentFormsGroups, selectPaymentFormsStatus } from 'store/payment'
import { CulturalVoucherCards, DebitCards, InternetBankCards } from 'Components/PaymentCards'
import PaymentCardsWrapper from 'Components/PaymentCards/PaymentCardsWrapper'
import { returnGroupCards, redirectPost, returnPaytrailGroupCards, returnPrice } from 'utils/functions'
import { selectData } from 'store/user'
import { webPaymentTransaction } from 'store/payment/asyncActions'
import { useHistory } from 'react-router'
import { Status } from 'models'
import { beginCheckout, addPaymentInfo } from 'utils/tagManagerReports'
import { selectPriceData } from 'store/price'

const { Panel } = Collapse

const CardRenewalOptions = () => {
  const { t } = useTranslation<string>()
  const userInfo = useAppSelector(selectData)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const paymentGroups = useAppSelector(selectPaymentFormsGroups)
  const paymentFormsStatus = useAppSelector(selectPaymentFormsStatus)
  const paymentForms = useAppSelector(selectPaymentFormsData)
  const [loading, setLoading] = useState(false)
  const priceData = useAppSelector(selectPriceData)

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [setLoading])

  useEffect(() => {
    beginCheckout(returnPrice(userInfo.code.expires_at, priceData))
  }, [priceData, userInfo])

  useEffect(() => {
    if (!paymentForms && paymentFormsStatus !== Status.PENDING) history.push('/home')
  }, [history, paymentForms, paymentFormsStatus])

  const handlePanels = () => {
    return [
      {
        key: 'panel2',
        header: 'internetBank',
        component: (
          <PaymentCardsWrapper
            handleSubmit={handleSubmit}
            className="justify-content-start flex-wrap"
            cards={returnPaytrailGroupCards(InternetBankCards, paymentGroups['paytrail' as keyof typeof paymentGroups])}
            loading={loading}
          />
        ),
      },
      {
        key: 'panel3',
        header: 'culturalVoucher',
        component: (
          <PaymentCardsWrapper
            handleSubmit={handleSubmit}
            className="justify-content-start flex-wrap"
            cards={returnGroupCards(CulturalVoucherCards, paymentGroups)}
            loading={loading}
          />
        ),
      },
      {
        key: 'panel1',
        header: 'debitCard',
        component: (
          <PaymentCardsWrapper
            handleSubmit={handleSubmit}
            className="justify-content-start flex-wrap"
            cards={returnGroupCards(DebitCards, paymentGroups)}
            loading={loading}
          />
        ),
      },
    ]
  }

  const handleSubmit = (selectedAction: any) => {
    setLoading(true)
    let requestBody: { [k: string]: any } = {}
    requestBody['customerInfo'] = [{ code: userInfo.code.user_id.toString() }]
    requestBody['method'] = selectedAction.group
    requestBody['transaction_id'] = paymentForms.transaction_id
    dispatch(webPaymentTransaction(requestBody)).then(() => {
      if (requestBody['method'] === 'smartum') {
        window.location.href = selectedAction.action
      } else {
        redirectPost(selectedAction.action, {
          ...selectedAction.fields,
          ...selectedAction.method,
        })
      }
    })
    addPaymentInfo(requestBody['method'], returnPrice(userInfo.code.expires_at, priceData))
  }

  return (
    <Container>
      {Object.keys(paymentGroups).length ? (
        <>
          <CollapseContainer
            ghost
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <img src={ArrowUp} alt="" style={{ transform: isActive ? 'rotate(0deg)' : 'rotate(180deg)' }} />
            )}
          >
            {handlePanels().map(panel => (
              <PanelContainer header={t(panel.header)} key={panel.key}>
                {panel.component}
              </PanelContainer>
            ))}
          </CollapseContainer>
        </>
      ) : (
        <Spin size="large" className="w-100" />
      )}
    </Container>
  )
}
export default CardRenewalOptions

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const CollapseContainer = styled(Collapse)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PanelContainer = styled(Panel)`
  width: 100%;
  background: #ffffff;
  border-radius: 16px !important;
  padding: 25px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .ant-collapse-header,
  .ant-collapse-header span {
    padding: 0px !important;
    font-size: ${calculateDynamicFont({
      max: FontSize.TWENTY_FOUR,
      min: FontSize.EIGHTEEN,
    })} !important;
    font-family: ${FontFamily.EXTRABOLD} !important;
    font-weight: ${FontWeight.EIGHT} !important;
  }
  & .ant-collapse-arrow {
    right: 0px !important;
    top: 25% !important;
  }
  & .ant-collapse-content-box {
    padding: 0px !important;
  }

  & .ant-collapse-content,
  .ant-collapse-header {
    max-width: 594px;
    width: 100%;
  }
  @media (max-width: 700px) {
    margin-bottom: 16px;
  }
`
