import { FunctionComponent } from 'react'
import { CheckBox } from '../../../../UiKit/CheckBox'
import Text from '../../../../UiKit/Text'
import { FontSize, FontWeight } from '../../../../../constants/fonts'
import { useTranslation } from 'react-i18next'

interface Props {
  value: boolean
  changeAction: any
}
const NewsLetterCheckbox: FunctionComponent<Props> = ({ value, changeAction }) => {
  const { t } = useTranslation<string>()
  return (
    <div className={'horizontal checkbox-in-profile'}>
      <div className={'item flex-none'}>
        <CheckBox isChecked={value} onToggle={changeAction}></CheckBox>
      </div>
      <div className={'item flex-1 justify-content-start'}>
        <Text
          fontFamily="RightGrotesk-bold"
          fontWeight={FontWeight.EIGHT}
          fontSize={FontSize.EIGHTEEN}
          className={'display-inline height-fit-content'}
          textAlign={'left'}
        >
          {t('orderMuseumCardNewsletter')}
        </Text>
      </div>
    </div>
  )
}

export default NewsLetterCheckbox
