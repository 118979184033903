import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { Provider } from 'react-redux'
import { store } from './store'
import { Router } from 'react-router'
import { browserHistory } from './routes/historyWrapper'
import Routes from './routes'
import './assets/css/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import { apiConfig } from 'api/config'
import GlobalStyle from './assets/fonts/fonts'
import Header from 'Components/CustomComponents/Header'
import Toast from 'Components/UiKit/Toast'
import Popup from './Components/Cookies'

apiConfig()

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Router history={browserHistory}>
          <Header />
          <Routes />
          <Toast />
        </Router>
        <Popup />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
