import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'
import { ExhibitionResource } from './exhibitionModel'

export const getExhibition: AsyncThunk<Array<ExhibitionResource>, void, { rejectValue: string }> = createAsyncThunk(
  'web/museum/exhibition',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await WebService.webMuseumExhibitionList()

      return response.data
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
