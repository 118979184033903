import { VFC } from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { useTranslation } from 'react-i18next'
import { Header } from 'Components/UiKit/Header'
import VisitedItems from './VisiteItem/VisiteItems'
import VisitRightSide from './VisitRightSide'

const MobileSize: VFC = () => {
  const { t } = useTranslation<string>()

  return (
    <Container>
      <div className={'item flex-none'}>
        <Header title={t('yourCustomerInfo')} subTitle={t('manageEverythingEasily')} />
      </div>
      <VisitedItems isMobile />
      <VisitRightSide isMobile />
    </Container>
  )
}

export default MobileSize

const Container = styled.div`
  width: 100%;
  background-color: ${Colors.ALICE_BLUE};
  padding: 0 25px;
`
