import { createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'
import { UserInfo } from './models/UserInfo'
import { UpdateUserInfo } from './models/UpdateUserInfo'

export type UserInfoResource = {
  data?: UserInfo
}

export const getUserInfoAsync = createAsyncThunk('web/user/info', async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await WebService.webUserInfo()

    return response
  } catch (error) {
    return handleApiCallError(error, rejectWithValue, dispatch)
  }
})

export const updateUserInfoAsync: AsyncThunk<any, UpdateUserInfo, { rejectValue: string }> = createAsyncThunk(
  'web/user/update',
  async (body: UpdateUserInfo, { rejectWithValue, dispatch }) => {
    try {
      const response = await WebService.webUserUpdateInfo(body)

      return response
    } catch (error) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const updateUserSettingUnsubscribe = createAsyncThunk(
  'web/user/update/unsubscribe',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await WebService.webUserSettingUnsubscribe()

      return response
    } catch (error) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
