const Level5 = (props: any) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" fill="#DD9F57" />
      <path
        d="M23.9434 31.2637C28.0254 31.2637 30.0762 29.7012 30.0762 26.6348V26.5957C30.0762 23.666 28.3086 22.1426 24.8418 22.1426C23.4648 22.1426 22.3418 22.4355 21.4238 22.9922L21.5996 19.8574H29.5195V17.3281H18.1719L17.791 25.4434L21.3262 25.5996C21.707 24.7305 22.5176 24.2715 23.8555 24.2715C25.5254 24.2715 26.3359 25.0332 26.3359 26.5957V26.6348C26.3359 28.1875 25.5156 28.9297 23.8164 28.9297C22.2246 28.9297 21.4043 28.3047 21.2969 27.0156L17.5957 27.3672C17.7715 29.9844 19.8516 31.2637 23.9434 31.2637Z"
        fill="white"
      />
    </svg>
  )
}
export default Level5
