const Level6 = (props: any) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" fill="#DD6F57" />
      <path
        d="M24.041 17.0645C19.7637 17.0645 17.6641 19.4961 17.6641 24.4277V24.8574C17.6641 29.1543 19.7246 31.2637 23.9727 31.2637C28.0938 31.2637 30.1055 29.7305 30.1055 26.7129V26.6836C30.1055 23.9004 28.416 22.4551 25.0957 22.4551C23.5234 22.4551 22.2051 22.8945 21.1895 23.6172V23.5977C21.1895 20.7754 22.1367 19.457 23.9141 19.457C25.3203 19.457 26.1309 20.1797 26.2578 21.6445L29.9785 21.2832C29.666 18.4707 27.6641 17.0645 24.041 17.0645ZM24.002 24.5352C25.7012 24.5352 26.5215 25.248 26.5215 26.6934V26.7129C26.5215 28.168 25.7109 28.8613 24.0508 28.8613C22.4297 28.8613 21.502 28.041 21.502 26.625V26.6055C21.502 25.2188 22.3125 24.5352 24.002 24.5352Z"
        fill="white"
      />
    </svg>
  )
}

export default Level6
