const OPIcon = (props: any) => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.99"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.0543 79.4646C59.3116 77.0492 69.3253 69.6867 74.8764 59.744C81.3725 48.1085 81.7091 34.1891 75.7853 22.1582C73.5925 17.7048 71.2542 14.5292 67.6103 11.0563C62.6531 6.33152 56.6173 2.9543 49.9768 1.18973C45.2239 -0.0732547 37.6997 -0.377637 33.0133 0.503477C17.4781 3.4243 4.98594 15.0155 1.03155 30.1784C0.0498924 33.9426 -0.321355 42.3022 0.315912 46.293C2.94492 62.7569 15.6591 76.0283 32.1342 79.5056C35.3458 80.1834 44.8177 80.1591 48.0543 79.4646Z"
        fill="#FF6A10"
      />
      <path
        opacity="0.99"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.5616 24.6627C19.8852 16.6568 8.04488 30.0643 8.71455 40.1439C8.78259 52.8615 22.4749 62.2905 35.5616 55.4011V71.3717L43.956 71.3409L44.0889 55.3372C60.5885 63.1182 71.5069 49.9923 71.3109 40.1477C71.1083 27.4496 58.3957 17.5665 44.0889 24.6626V8.62817H35.8034L35.5616 24.6627ZM26.691 30.696C40.1165 32.1986 37.6472 49.2997 26.6417 49.5594C13.6732 49.2402 13.7064 30.6942 26.691 30.696ZM54.2114 30.4284C66.7005 31.8337 65.2534 49.2141 53.6187 49.5602C39.3391 47.6891 42.25 30.0866 54.2114 30.4284Z"
        fill="white"
      />
    </svg>
  )
}

export default OPIcon
