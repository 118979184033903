export const Cards = [
  {
    transparent: false,
    title: 'whatDoYouThinkOfService',
    message: 'whatCouldWeDoBetter',
    btnLabel: 'giveFeedback',
    background: true,
  },
  {
    transparent: true,
    title: 'currentAffairs',
    questions: [
      'tenReasonsWhy',
      'museumCardGifts',
      'seeListOfMunicipalities',
      'effectOfCorona',
      'takeOnMuseumChallenge',
    ] as any[],
  },
  {
    transparent: true,
    title: 'newsLetterArchive',
    questions: [
      'tenReasonsWhy',
      'museumCardGifts',
      'seeListOfMunicipalities',
      'effectOfCorona',
      'takeOnMuseumChallenge',
    ] as any[],
  },
  {
    transparent: false,
    title: 'anyQuestions',
    message: 'ourFrequentlyAskedQeustions',
    btnLabel: 'frequentlyAskedQuestions',
    background: false,
  },
]
