import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import WhiteHeart from 'assets/icons/whiteHeart.svg'
import RedHeart from 'assets/icons/redHeart.svg'

interface Props {
  rate: number
}

const RateComponent: VoidFunctionComponent<Props> = ({ rate }) => {
  return (
    <>
      {[...Array(rate)].map((e, i) => (
        <HeartImg src={RedHeart} key={i} />
      ))}
      {[...Array(5 - rate)].map((e, i) => (
        <HeartImg src={WhiteHeart} key={i} />
      ))}
    </>
  )
}

export default RateComponent

const HeartImg = styled.img`
  width: 17.7px;
  height: 16px;
  margin-left: 2px;
  margin-right: 2px;
`
