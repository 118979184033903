import ModalComponent from 'Components/UiKit/Modal'
import styled from 'styled-components'

export enum ResponsiveConstant {
  isDesktopOrLaptop = '(min-width: 701px)',
  isTabletOrMobile = '(max-width: 700px)',
}

export const isDesktopOrLaptop = () => {
  return window.matchMedia(ResponsiveConstant.isDesktopOrLaptop).matches
}

export const ResponsiveContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

export const ModalContainer = styled(ModalComponent)`
  width: 100% !important;
  max-width: 720px;

  & .ant-modal-content {
    border-radius: 16px;
  }

  & .ant-modal-body {
    height: ${props => (props.type !== 'combine' ? '692px' : '625px')};
  }
`
