import MediaQuery from 'react-responsive'
import { ResponsiveConstant } from 'constants/responsive-constant'
import Inbox from './Inbox'
import MobileInbox from './MobileInbox'

const InboxContainer = () => {
  return (
    <>
      <MediaQuery query={ResponsiveConstant.isTabletOrMobile}>
        <MobileInbox />
      </MediaQuery>
      <MediaQuery query={ResponsiveConstant.isDesktopOrLaptop}>
        <Inbox />
      </MediaQuery>
    </>
  )
}
export default InboxContainer
