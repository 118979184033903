import { Status } from 'models'
import tokenUtils from 'utils/token'
import { setStatus } from './auth'
import { Dispatch } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import i18next from 'i18next'

export const handleApiCallError = (err: any, rejectWithValue: any, dispatch: Dispatch<any>) => {
  if (rejectWithValue(err).payload.status === 401 || rejectWithValue(err).payload.message === 'Type error') {
    tokenUtils.removeToken()
    toast.error(i18next.getDataByLanguage(i18next.language).translation['tokenExpired'])
    dispatch(setStatus(Status.IDLE))
  }
  if (err?.body?.error) {
    return rejectWithValue(err.body.error)
  } else if (err?.body?.errors) {
    return rejectWithValue(err.body.errors)
  } else if (err?.body?.message) {
    return rejectWithValue(err.body)
  }
  const message: any = { message: err.message }
  return rejectWithValue(message)
}
