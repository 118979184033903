import { ResponsiveContainer } from 'constants/responsive-constant'
import PersonalInfo from './Cards/PersonalInfo'
import ProfileCardNumber from './Cards/ProfileCardNumber'
import LevelStatusCard from './Cards/LevelStatusCard'
import { FunctionComponent } from 'react'
import { useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import { definedLevel } from 'utils/definedLevel'
import { useTranslation } from 'react-i18next'
import DefaultImag from 'assets/icons/museum-thumbnail.png'
import styled from 'styled-components'
import { Colors } from 'constants/index'

const ProfileSummaryCards: FunctionComponent = () => {
  const userData = useAppSelector(selectData)
  const { t } = useTranslation()

  return (
    <Container className="white-background-color">
      <PersonalInfo
        name={userData.firstname + ' ' + userData.lastname}
        image={userData.photo || DefaultImag}
        userStatus={t(definedLevel(userData.visits_count).levelText) + t('slashVisitor')}
      />
      <Spacer order={2} />
      <ProfileCardNumber />
      <Spacer order={4} />
      <LevelStatusCard />
    </Container>
  )
}

export default ProfileSummaryCards

const Container = styled(ResponsiveContainer)`
  border-radius: 16px;
  padding: 40px 46px;
  min-height: 340px;
  height: fit-content;
  gap: 40px;
  @media (max-width: 700px) {
    padding: 60px 33px;
  }
`
const Spacer = styled.div<{ order: number }>`
  height: 100%;
  width: 0px;
  @media (max-width: 700px) {
    height: 0px;
    width: 100%;
  }
  opacity: 0.15;
  border: 1px solid ${Colors.ROYAL_BLUE};
  order: ${({ order }) => order};
`
