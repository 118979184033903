import { FunctionComponent } from 'react'
import Text from 'Components/UiKit/Text'
import { calculateDynamicFont, FontWeight, FontFamily, FontSize } from 'constants/index'
import LevelCircle from './LevelCircle'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import { calculateNextLevelThreshold, definedLevel } from 'utils/definedLevel'
import { generateFieldUuid } from 'utils/functions'
import { useHistory } from 'react-router'
import styled from 'styled-components'

const LevelStatusCard: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const userData = useAppSelector(selectData)
  const history = useHistory()

  return (
    <Container className={'vertical'}>
      <LevelCircle count={userData.visits_count} />
      <Text
        textAlign={'center'}
        fontWeight={FontWeight.EIGHT}
        fontFamily={FontFamily.BOLD}
        fontSize={calculateDynamicFont({ max: FontSize.TWENTY, min: FontSize.EIGHTEEN })}
      >
        {t(definedLevel(userData.visits_count).levelText)}
      </Text>
      {calculateNextLevelThreshold(userData.visits_count) > 0 && (
        <Text textAlign={'center'} fontSize={calculateDynamicFont({ max: FontSize.TWENTY, min: FontSize.SIXTEEN })}>
          {t('youNeedXVisits', { visits: calculateNextLevelThreshold(userData.visits_count) })}
        </Text>
      )}
      <Btncontainer>
        <TextBotton
          id={generateFieldUuid('showVisits', 'button')}
          fontFamily={FontFamily.BOLD}
          fontWeight={FontWeight.SEVEN}
          size={calculateDynamicFont({ max: FontSize.EIGHTEEN, min: FontSize.SIXTEEN })}
          onClick={() => history.push('/visits')}
          text={t('showVisits') + ' ›'}
        />
        {/* <TextBotton
          fontFamily={FontFamily.BOLD}
          fontWeight={FontWeight.SEVEN}
          size={calculateDynamicFont({ max: FontSize.EIGHTEEN, min: FontSize.SIXTEEN })}
          onClick={() => {}}
          text={t('tellAFriend') + ' ›'}
        /> */}
      </Btncontainer>
    </Container>
  )
}

export default LevelStatusCard

const Container = styled.div`
  order: 5;
  gap: 16px;
  width: 30%;
  justify-content: start;
  flex: none;
  .fit-parent {
    width: 68px;
    height: 68px;
  }
  @media (max-width: 700px) {
    height: fit-content;
    width: 100%;
  }
`
const Btncontainer = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
