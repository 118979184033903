import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont, Colors } from 'constants/index'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import dayjs from 'dayjs'

interface MessageListProps {
  messages: any
  setSelectedMessage: (index: number) => void
}

const MessageList = ({ messages, setSelectedMessage }: MessageListProps) => {
  const [t] = useTranslation()
  const [selected, setSelected] = useState(0)

  return (
    <Container>
      <TitleWrapper>
        <Text
          textAlign={'left'}
          width="fit-content"
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY_FOUR,
            min: FontSize.EIGHTEEN,
          })}
        >
          {t('allMessages')}
        </Text>
        <Count>
          <Text
            textAlign={'center'}
            fontWeight={FontWeight.EIGHT}
            fontFamily={FontFamily.EXTRABOLD}
            fontSize={FontSize.TWELVE}
            color={Colors.WHITE}
          >
            8
          </Text>
        </Count>
      </TitleWrapper>
      <MessagesWrapper id="messageWrapper">
        {messages.map(
          (
            message: {
              read: boolean
              title: string
              date: string
            },
            index: number
          ) => (
            <MessageContainer
              id="messageContainer"
              key={index}
              className={selected === index ? 'selected' : ''}
              onClick={() => {
                setSelectedMessage(index)
                setSelected(index)
              }}
            >
              <Read className={message.read ? 'read' : ''} />
              <MessageWrapper>
                <Text
                  textAlign={'left'}
                  fontWeight={FontWeight.EIGHT}
                  fontFamily={FontFamily.EXTRABOLD}
                  fontSize={FontSize.FOURTEEN}
                >
                  {message.title}
                </Text>
                <Text textAlign={'left'} fontSize={FontSize.FOURTEEN}>
                  {dayjs(message.date).format('dddd, MMMM D, YYYY, h:m a')}
                </Text>
              </MessageWrapper>
            </MessageContainer>
          )
        )}
      </MessagesWrapper>
      <FadeOutElement />
    </Container>
  )
}

export default MessageList

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 365px;
  @media (max-width: 700px) {
    max-width: unset;
    height: fit-content;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  gap: 11px;
  margin-bottom: 32px;
`
const Count = styled.div`
  display: flex;
  justifiy-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: ${Colors.ROYAL_BLUE};
  border-radius: 10px;
  @media (max-width: 700px) {
    width: 39px;
    height: 28px;
  }
`
const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding-right: 29px;
  max-height: 500px;
  &::-webkit-scrollbar {
    width: 6px;
    display: initial;
  }
  &::-webkit-scrollbar-track {
    background: ${Colors.LIGHT_GREY};
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${Colors.ROYAL_BLUE};
    border-radius: 6px;
  }
  @media (max-width: 700px) {
    padding-right: 0px;
    max-height: unset;
    overflow-y: hidden;
    & div {
      line-height: 20px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
const MessageContainer = styled.div`
  display: flex;
  width: 330px;
  align-items: flex-start;
  padding: 16px 0px;
  cursor: pointer;
  gap: 19px;
  height: 80px;
  border-radius: 12px;
  &.selected {
    background: ${Colors.ALICE_BLUE};
  }
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & div:last-child {
    opacity: 0.5;
  }
`
const Read = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 4px;
  &.read {
    background: ${Colors.ROYAL_BLUE};
  }
`

const FadeOutElement = styled.div`
  position: absolute;
  z-index: 1;
  width: calc(100% - 6px);
  height: 60px;
  background: white;
  bottom: 0;
  opacity: 0.9;
`
