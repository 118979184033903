import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontWeight, FontFamily, FontSize, Colors, calculateDynamicFont } from 'constants/index'
import { Container, BodyWrapper, BtnContainer } from './ModalStyledComponents'
import Input from 'Components/UiKit/Input'
import ModalTitleAndMessage from './ModalTitleAndMessage'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { checkCouponCode } from 'store/payment/asyncActions'
import { CheckBox } from 'Components/UiKit/CheckBox'
import Text from 'Components/UiKit/Text'
import { toast } from 'react-toastify'
import { formatPrice, returnErrorMessageContent, returnPrice } from 'utils/functions'
import { selectPriceData } from 'store/price'
import { selectData } from 'store/user'
import { addToCardTag } from 'utils/tagManagerReports'

const CombineCardsModal = ({ handleClick }: { handleClick: (coupon?: string) => void }) => {
  const [t] = useTranslation()
  const [value, setValue] = useState('')
  const userData = useAppSelector(selectData)
  const [showCouponInput, setShowCouponInput] = useState(false)
  const dispatch = useAppDispatch()
  const priceData = useAppSelector(selectPriceData)

  const initialTitleState = {
    title: t('CouponHeader'),
    message: t('CouponMessage', { price: formatPrice(returnPrice(userData.code.expires_at, priceData) / 100) }),
  }

  const handleSubmit = () => {
    if (showCouponInput) {
      dispatch(checkCouponCode(value)).then(res => {
        if (res.meta.requestStatus === 'rejected') toast.error(returnErrorMessageContent(res.payload)[0])
        else handleClick(value)
      })
    } else handleClick()
    addToCardTag(returnPrice(userData.code.expires_at, priceData))
  }

  return (
    <Container>
      <BodyWrapper>
        <ModalTitleAndMessage {...initialTitleState} />
        <CheckboxContainer>
          <CheckboxWrapper
            isChecked={showCouponInput}
            onToggle={(): void => setShowCouponInput(!showCouponInput)}
          ></CheckboxWrapper>
          <Text
            fontFamily={FontFamily.BOLD}
            fontWeight={FontWeight.SEVEN}
            fontSize={calculateDynamicFont({
              max: FontSize.TWENTY,
              min: FontSize.SIXTEEN,
            })}
          >
            {t('CouponCheckboxTitle')}
          </Text>
        </CheckboxContainer>
        {showCouponInput && (
          <InputWrapper
            defaultValue={value}
            name={t('couponCode')}
            onChange={e => {
              setValue(e.target.value)
            }}
          />
        )}
        <BtnWrapper
          text={t('Continue')}
          fontSize={FontSize.SIXTEEN}
          bgColor={Colors.FRENCH_ROSE}
          onClick={handleSubmit}
        />
      </BodyWrapper>
    </Container>
  )
}
export default CombineCardsModal

const InputWrapper = styled(Input)`
  height: fit-content;
  padding: 0px;
  .item {
    flex: none;
    input {
      background: ${Colors.ALICE_BLUE};
      padding-left: 25px;
    }
    label {
      margin-left: 14px;
      margin-bottom: 9px;
    }
  }
`

const BtnWrapper = styled(BtnContainer)`
  max-width: 235px;
`

const CheckboxContainer = styled.div`
  width: 100%;
  height: 71px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  & div {
    width: fit-content;
  }
`

const CheckboxWrapper = styled(CheckBox)`
  background: #f3f7fa !important;
  width: 36px !important;
  height: 36px;
  flex: none !important;
  margin-right: 0px;
`
