const Level7 = (props: any) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" fill="#B3B3B3" />
      <path
        d="M20.8711 31H24.5137C24.748 25.7461 25.998 22.0156 28.3809 19.4277V17.3184H17.6191V20.0039H24.6211C22.3555 22.4453 21.1152 26.0684 20.8711 31Z"
        fill="white"
      />
    </svg>
  )
}

export default Level7
