const AktiaIcon = (props: any) => {
  return (
    <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.6233 32.585L16.2252 1.29608H11.2639L0 32.585H4.87204L7.46448 25.1649H19.98L22.6171 32.585H27.6233ZM18.5497 20.9635H8.8949L11.3979 13.8117C12.2025 11.4873 13.3199 7.91153 13.6775 6.57051C14.0352 7.91153 15.2867 11.532 16.0465 13.767L18.5497 20.9635Z"
        fill="#009430"
      />
      <path
        d="M51.4921 32.585L44.4298 16.9852L50.3745 9.11841H45.1449L40.273 15.9571C39.5131 17.0746 38.3061 18.8626 37.6802 19.8013C37.6802 18.639 37.7249 16.2255 37.7249 14.7951V-6.10352e-05L33.1211 2.41352V32.585H37.7249V25.6566L41.301 20.9186L46.4859 32.585H51.4921Z"
        fill="#009430"
      />
      <path
        d="M67.9394 27.9363C67.09 28.4281 65.749 28.8749 64.5423 28.8749C62.8436 28.8749 62.1286 28.2492 62.1286 26.0589V13.32H67.9394V9.1184H62.1286V-7.62939e-05L57.614 2.4135V9.1184H54.2617V13.32H57.614V26.8636C57.614 31.0651 59.6253 33.0767 62.8884 33.0767C64.721 33.0767 66.2854 32.6297 67.403 32.0039L67.9394 27.9363Z"
        fill="#009430"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M79.6911 3.12866C79.6911 1.47494 78.3502 0.133926 76.741 0.133926C75.0871 0.133926 73.7461 1.47494 73.7461 3.12866C73.7461 4.73794 75.0871 6.07881 76.741 6.07881C78.3502 6.07881 79.6911 4.73794 79.6911 3.12866ZM79.0209 32.585V9.11841H74.4168V32.585H79.0209Z"
        fill="#009430"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104 32.5849V16.4936C104 11.1744 100.424 8.62666 95.1946 8.62666C91.8871 8.62666 89.3839 9.34183 87.1491 10.7274L88.937 14.214C90.7696 13.1858 92.6466 12.7388 95.0604 12.7388C97.9214 12.7388 99.4858 14.1693 99.4858 16.717V18.4603C98.1892 17.8792 96.4461 17.5663 94.2558 17.5663C88.937 17.5663 84.9141 19.9353 84.9141 25.1649C84.9141 30.9311 89.2496 33.0767 93.0491 33.0767C95.5968 33.0767 97.8766 31.8698 99.4858 30.2606V32.5849H104ZM99.4858 25.9249C98.4126 27.3999 96.2674 28.9196 93.8089 28.9196C90.7696 28.9196 89.2943 27.3552 89.2943 25.0309C89.2943 22.8855 91.1717 21.5444 94.1219 21.5444C96.6248 21.5444 98.234 21.9467 99.4858 22.5725V25.9249Z"
        fill="#009430"
      />
    </svg>
  )
}

export default AktiaIcon
