import { Colors } from 'constants/index'
import { useState } from 'react'
import styled from 'styled-components'
import Message from './Message'
import MessageList from './MessageList'

const messages = [
  {
    read: true,
    title: 'Test',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test2',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: true,
    title: 'Test3',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test4',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: true,
    title: 'Test',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test2',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: true,
    title: 'Test3',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test4',
    date: new Date(),
    mesage: 'test test test test test',
  },
]

const Inbox = () => {
  const [selectedMessage, setSelectedMessage] = useState(messages[0])

  const handleClick = (index: number) => {
    setSelectedMessage(messages[index])
  }

  return (
    <Container>
      <MessageList messages={messages} setSelectedMessage={handleClick}></MessageList>
      <Message message={selectedMessage}></Message>
    </Container>
  )
}
export default Inbox

const Container = styled.div`
  height: 616px;
  width: 100%;
  border-radius: 16px;
  background: ${Colors.WHITE};
  padding: 40px 32px 15px;
  display: flex;
  margin-bottom: 62px;
`
