import { FunctionComponent, useEffect, useState } from 'react'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, Colors, ElementTypes, FontSize, calculateDynamicFont } from 'constants/index'
import Button from 'Components/UiKit/Buttons/Button'
import PaymentInfo from './paymentCards'
import styled from 'styled-components'

interface Props {
  buttonType: ElementTypes
  image: string
  header: string
  bgColor: Colors
  description: string
  buttonText: string
  onClick: () => void
  showBtn?: boolean
  type?: string
}

const MergeOrChangePaymentCard: FunctionComponent<Props> = ({
  buttonText,
  buttonType,
  image,
  header,
  description,
  onClick,
  bgColor,
  showBtn = true,
  type,
}) => {
  const [substr, setSubstr] = useState('')

  useEffect(() => {
    const matches = description.match(/\(([^)]+)\)/)
    setSubstr(matches ? matches[0] : description)
  }, [description])

  return (
    <Container className={'vertical white-background-color connect-and-update-cards'}>
      <Text
        fontFamily={FontFamily.BOLD}
        fontWeight={FontWeight.SEVEN}
        className="px-3"
        fontSize={calculateDynamicFont({ max: FontSize.TWENTY_FOUR, min: FontSize.TWENTY })}
      >
        {header}
      </Text>

      <Text className="px-3" fontSize={calculateDynamicFont({ max: FontSize.TWENTY, min: FontSize.FOURTEEN })}>
        {description.split(substr)[0]}
        <Text
          fontFamily={description.split(substr)[0] ? FontFamily.BOLD : FontFamily.NORMAL}
          fontWeight={description.split(substr)[0] ? FontWeight.SEVEN : FontWeight.NORMAL}
          display={'contents'}
        >
          {substr}
        </Text>
        {description.split(substr)[1]}
      </Text>
      {showBtn && type === 'payment' && <PaymentInfo />}
      {showBtn && (
        <BtnContainer
          bgColor={bgColor}
          onClick={onClick}
          text={buttonText}
          buttonType={buttonType}
          size="normal"
          fontSize={FontSize.SIXTEEN}
          fontWeight={FontWeight.NORMAL}
        />
      )}
      <img alt="credit-cards" className={'responsive-image object-fit-fill'} src={image} />
    </Container>
  )
}

export default MergeOrChangePaymentCard

const Container = styled.div`
  padding-top: 60px;
  justify-content: space-between;
  gap: 20px;
  img {
    height: fit-content;
  }

  @media (max-width: 700px) {
    padding-top: 30px;
    min-height: 329px;
    height: fit-content;
    &:last-child {
      margin-top: 30px;
    }
  }
`
const BtnContainer = styled(Button)`
  min-width: 235px;
  @media (max-width: 350px) {
    max-width: 235px;
  }
`
