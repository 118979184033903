// @flow
import * as React from 'react'
import { Fragment, FunctionComponent } from 'react'
import Text from '../Text'
import Button from '../Buttons/Button'
import { AppStore, GooglePlay } from 'assets/iconComponents'
import { calculateFont, FontWeight, FontFamily, Colors } from 'constants/index'
import MediaQuery from 'react-responsive'
import { ResponsiveConstant } from 'constants/responsive-constant'
import { useTranslation } from 'react-i18next'
import { generateFieldUuid } from 'utils/functions'

type Props = {}

export const Footer: FunctionComponent<Props> = () => {
  const { t } = useTranslation<string>()
  return (
    <Fragment>
      <MediaQuery query={ResponsiveConstant.isDesktopOrLaptop}>
        <div className={'horizontal white-background-color footer'}>
          <div className={'item flex-1'}>
            <Text
              textAlign={'left'}
              fontWeight={FontWeight.EIGHT}
              fontFamily={FontFamily.EXTRABOLD}
              fontSize={calculateFont({ coefficient: 0.8 })}
            >
              {t('wantPersonalizeContent')}
            </Text>
          </div>
          <div className={'item flex-1'}>
            <div className={'vertical gap-4'}>
              <div className={'item flex-1'}>
                <Text textAlign={'left'} fontWeight={FontWeight.NORMAL} fontSize={calculateFont({ coefficient: 0.5 })}>
                  {t('subscribeMuseumCardNewsletterAndReceiveInfo')}
                </Text>
              </div>
              <div className={'item flex-1'}>
                <Button
                  id={generateFieldUuid('tellUsAboutYourself', 'button')}
                  containerClassName={'w-100'}
                  fontWeight={FontWeight.NORMAL}
                  fontFamily={FontFamily.NORMAL}
                  text={t('tellUsAboutYourself')}
                  bgColor={Colors.FRENCH_ROSE}
                  onClick={() => window.open(t('customerServiceLink'))}
                />
              </div>
            </div>
          </div>
          <div className={'item flex-1'}>
            <div className={'vertical gap-4'}>
              <div className={'item flex-1'}>
                <Text
                  textAlign={'left'}
                  fontFamily={FontFamily.BOLD}
                  fontWeight={FontWeight.EIGHT}
                  fontSize={calculateFont({ coefficient: 0.5 })}
                >
                  {t('downloadMuseumCardApp')}
                </Text>
              </div>
              <div className={'item flex-1'}>
                <div className={'horizontal gap-4'}>
                  <div
                    id={generateFieldUuid('download-ios', 'link')}
                    className={'item flex-1 pointer'}
                    onClick={() => window.open('https://apps.apple.com/us/app/museokortti/id1513240463')}
                  >
                    <AppStore className="logo" />
                  </div>
                  <div
                    id={generateFieldUuid('download-android', 'link')}
                    onClick={() => window.open('https://play.google.com/store/apps/details?id=fi.museot.app')}
                    className={'item flex-1 pointer'}
                  >
                    <GooglePlay className="logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </Fragment>
  )
}
