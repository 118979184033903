import { useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { Colors, HeaderFooterSRC } from 'constants/index'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { htmlEntityDecode } from 'utils/functions'

export default function Footer() {
  const [footer, setFooter] = useState('')
  const [, { language }] = useTranslation()

  useEffect(() => {
    const lang = language || localStorage.getItem('i18nextLng') || 'fi'
    fetch(`${HeaderFooterSRC.footer}?lang=${lang}`)
      .then(function (response) {
        return response.text()
      })
      .then(function (data) {
        const head = document.head

        const pageStyleLink = document.createElement('link')
        pageStyleLink.type = 'text/css'
        pageStyleLink.rel = 'stylesheet'
        pageStyleLink.href = HeaderFooterSRC.footer_style
        head.appendChild(pageStyleLink)

        setFooter(data)
      })
  }, [language])

  return footer ? (
    <Container dangerouslySetInnerHTML={{ __html: htmlEntityDecode(footer) }} />
  ) : (
    <ClipLoader size={5} color={Colors.PRIMARY} />
  )
}

const Container = styled.div`
  @media print {
    display: none;
  }
`
