import { useEffect, useState, VoidFunctionComponent } from 'react'
import MediaQuery from 'react-responsive'
import { useHistory } from 'react-router'
import TagManager from 'react-gtm-module'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import { ResponsiveConstant } from '../../constants'
import { webPaymentSuccess, webPaymentTransaction, webPaymentCard, requestLogger } from 'store/payment/asyncActions'
import DesktopSize from './DesktopSize'
import MobileSize from './MobileSize'
import { selectPassResetSuccessMessage, deletePassResetMessages } from 'store/auth'
import { toast } from 'react-toastify'
import { getUserInfoAsync } from 'store/user/asyncActions'
import { useTranslation } from 'react-i18next'
import { purchase } from 'utils/tagManagerReports'

const Home: VoidFunctionComponent = () => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const userInfo = useAppSelector(selectData)
  const [paymentDone, setPaymentDone] = useState(false)
  const resetPassSuccessMessage = useAppSelector(selectPassResetSuccessMessage)

  const history = useHistory()

  useEffect(() => {
    let requestBody: { [k: string]: any } = {}
    let subscribe = localStorage.getItem('subscribe')
    const urlParams = new URLSearchParams(history.location.search)
    urlParams
      .toString()
      .split('&')
      .forEach(el => {
        requestBody[el.split('=')[0]] = el.split('=')[1]
      })

    if (userInfo) {
      if (requestBody['method' as keyof typeof requestBody] && !paymentDone) {
        if (requestBody.transaction_id) {
          if (requestBody.STATUS === 'SUCCESS' || requestBody.success || requestBody.status === 'success') {
            dispatch(requestLogger({ ...requestBody, source: 'custumor_portal', endpoint: 'payment/success' }))
            if (requestBody.method === 'eazybreak') {
              const transactionId = requestBody.transaction_id.split('?')[0]
              requestBody['id'] = requestBody['%3Fid']
              delete requestBody['%3Fid']
              requestBody['transaction_id'] = transactionId
            } else {
              Object.keys(requestBody).forEach((el: string) => {
                let newKey = el.replaceAll('-', '_')
                requestBody[newKey] = requestBody[el]
                if (newKey !== el) delete requestBody[el]
              })
              requestBody['status'] = requestBody.status || requestBody.STATUS?.toLowerCase()
            }

            delete requestBody.STATUS
            requestBody['source'] = 'customer_portal'
            dispatch(webPaymentSuccess(requestBody)).then(() => dispatch(getUserInfoAsync()))
            const tagManagerArgs = {
              dataLayer: {
                transactionId: localStorage.getItem('payment_reference'),
                transactionAffiliation: '',
                transactionTotal: parseInt(localStorage.getItem('price')) / 100,
                transactionTax: 0.0,
                transactionProducts: {
                  sku: `museokortti-renewal`,
                  name: 'museokortti',
                  category: 'renewal',
                  promotionalCode: localStorage.getItem('coupon'),
                  price: parseInt(localStorage.getItem('price')) / 100,
                  quantity: 1,
                },
              },
              dataLayerName: 'dataLayer',
            }
            const eventTagManager = {
              dataLayer: {
                event: 'cardRenewed',
              },
              dataLayerName: 'dataLayer',
            }
            TagManager.dataLayer(tagManagerArgs)
            TagManager.dataLayer(eventTagManager)
            purchase(
              localStorage.getItem('price'),
              localStorage.getItem('payment_reference'),
              localStorage.getItem('coupon')
            )
          } else {
            requestBody['auto_renewal'] = userInfo.valid_for_renewal
            requestBody['customerInfo'] = [{ code: userInfo.code.user_id.toString() }]
            requestBody['filing_code'] = requestBody['sph_order']
            dispatch(webPaymentTransaction(requestBody))
          }
        } else if (subscribe) {
          if (requestBody['STATUS'] !== 'CANCEL') {
            requestBody['userId'] = userInfo.code.user_id
            requestBody['sphTokenizationId'] = requestBody['sph-tokenization-id']
            requestBody['transactionType'] = 'add_card'
            dispatch(webPaymentCard(requestBody)).then(() => {
              history.push('/home')
              dispatch(getUserInfoAsync())
            })
          } else {
            toast.error(t('standingOrderCancel'))
          }
        }
        history.replace({
          search: '',
        })
        setPaymentDone(true)
      }
    }
    localStorage.removeItem('subscribe')
    localStorage.removeItem('payment_reference')
    localStorage.removeItem('coupon')
    localStorage.removeItem('price')
  }, [history, dispatch, userInfo, paymentDone, t])

  useEffect(() => {
    if (resetPassSuccessMessage) toast.success(resetPassSuccessMessage)
    dispatch(deletePassResetMessages())
  }, [dispatch, resetPassSuccessMessage])

  return (
    <>
      <MediaQuery query={ResponsiveConstant.isTabletOrMobile}>
        <MobileSize />
      </MediaQuery>
      <MediaQuery query={ResponsiveConstant.isDesktopOrLaptop}>
        <DesktopSize />
      </MediaQuery>
    </>
  )
}

export default Home
