import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { getExhibition } from './asyncActions'
import { Status } from 'models'
import { ExhibitionResource } from './exhibitionModel'

export interface ExhibitionState {
  data: Array<ExhibitionResource>
  status: Status
  error: string | any
}

const initialState: ExhibitionState = {
  data: null,
  error: null,
  status: Status.IDLE,
}

export const exhibitionSlice = createSlice({
  name: 'exhibition',
  initialState,
  reducers: {
    reset: (state: ExhibitionState) => {
      state = initialState
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getExhibition.pending, state => {
        state.error = null
        state.status = Status.PENDING
      })
      .addCase(getExhibition.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = Status.SUCCEEDED
      })
      .addCase(getExhibition.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const { reset } = exhibitionSlice.actions

export const selectExhibitionData = (state: RootState): ExhibitionState['data'] => state.exhibition.data
export const selectExhibitionError = (state: RootState): ExhibitionState['error'] => state.exhibition.error
export const selectExhibitionStatus = (state: RootState): ExhibitionState['status'] => state.exhibition.status

export default exhibitionSlice.reducer
