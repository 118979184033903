const Level4 = (props: any) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" fill="#D6D6D6" />
      <path
        d="M24.2266 31H27.8691V28.1973H29.5V25.8926H27.8691V17.3281H23.0742L17.2832 26.2148V28.1973H24.2266V31ZM20.6328 25.8926L23.2012 21.8594C23.5332 21.2832 23.8457 20.707 24.1289 20.1211L24.3242 20.1406C24.2656 20.8633 24.2266 21.6641 24.2266 22.3379V25.8926H20.6328Z"
        fill="white"
      />
    </svg>
  )
}
export default Level4
