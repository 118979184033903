import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { calculateDynamicFont, FontFamily, FontSize, FontWeight } from 'constants/index'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import { definedLevel } from 'utils/definedLevel'
import { generateFieldUuid } from 'utils/functions'
import LevelCircle from 'Components/Profile/dependencies/Tabs/ProfileSummary/Cards/LevelCircle'
import { useHistory } from 'react-router'

const UserLevel: VoidFunctionComponent = () => {
  const { t } = useTranslation<string>()
  const { visits_count } = useAppSelector(selectData)
  const { levelText, neededVisits } = definedLevel(visits_count)
  const history = useHistory()

  const redirectToVisits = () => {
    history.push('/visits')
  }

  return (
    <Contanier>
      <div className={'level-icon'}>
        <LevelCircle count={visits_count} />
      </div>
      <HeaderContainer>
        <Text
          textAlign="left"
          fontSize={calculateDynamicFont({
            max: FontSize.EIGHTEEN,
            min: FontSize.FOURTEEN,
          })}
          fontWeight={FontWeight.BOLD}
        >
          {t('youOn')} {t(levelText)}
          {t('level')}
        </Text>
        {neededVisits > 0 && (
          <Text
            textAlign="left"
            fontSize={calculateDynamicFont({
              max: FontSize.FOURTEEN,
              min: FontSize.TWELVE,
            })}
          >
            {t(`youNeedXVisits`, { visits: neededVisits })}
          </Text>
        )}
        <HorizontalStyled>
          <TextBotton
            id={generateFieldUuid('showLevels', 'link')}
            size={calculateDynamicFont({
              max: FontSize.FOURTEEN,
              min: FontSize.TWELVE,
            })}
            fontFamily={FontFamily.BOLD}
            onClick={redirectToVisits}
            text={t('showLevels')}
          />
          {/* <TextBotton
            size={calculateDynamicFont({
              max: FontSize.FOURTEEN,
              min: FontSize.TWELVE,
            })}
            fontFamily={FontFamily.BOLD}
            onClick={() => {}}
            text={`${t('tellAFriend')} ›`}
          /> */}
        </HorizontalStyled>
      </HeaderContainer>
    </Contanier>
  )
}

export default UserLevel

const Contanier = styled.div`
  order: 1;
  display: flex;
  margin-bottom: 35px;
  .level-icon {
    margin-right: 17px;
    width: 68px;
    height: 68px;
  }
  @media (max-width: 700px) {
    order: 2;
  }
`
const HorizontalStyled = styled.div`
  display: flex;
  gap: 12px;
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
