import { FunctionComponent } from 'react'
// import Language from '/assets/icons/Frame-3.png'
// import worldWhite from '/assets/icons/world-white.png'
import { GlobeIcon } from 'assets/iconComponents'
import Text from 'Components/UiKit/Text'
import { FontWeight, Colors, FontSize, FontFamily } from 'constants/index'
import './SideBar.css'
import { Popover } from 'antd'
import { Languages } from 'constants/language'
import { useTranslation } from 'react-i18next'
import { DownOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { changeLang } from 'utils/functions'

interface Props {
  mobileMode?: boolean
  id?: string
}
const LangItem = ({ text }: { text: Languages }) => {
  const { i18n } = useTranslation()
  const history = useHistory()

  function handleChangeLang() {
    changeLang(text, i18n, history)
  }

  return (
    <Text
      onClick={handleChangeLang}
      color={Colors.FRENCH_ROSE}
      className={'pointer'}
      fontFamily={i18n.language === text ? FontFamily.BOLD : FontFamily.NORMAL}
      fontWeight={i18n.language === text ? FontWeight.SEVEN : FontWeight.NORMAL}
    >
      {text}
    </Text>
  )
}
const SwitchLanguage: FunctionComponent<Props> = ({ mobileMode = false, id }) => {
  const { i18n } = useTranslation()

  return (
    <>
      <div className={'pointer no-print'} id={id}>
        <Popover
          content={
            <div className={'vertical'}>
              {(Object.values(Languages) as Array<Languages>).map((lang: Languages, index: number) => {
                return (
                  <div className={'item'} key={index}>
                    <LangItem text={lang} />
                  </div>
                )
              })}
            </div>
          }
          trigger="click"
          placement="bottom"
        >
          <div className={'vertical'}>
            <GlobeIcon className={mobileMode ? 'white-globe' : ''} />
            <Text fontSize={FontSize.TWELVE} color={mobileMode ? Colors.WHITE : Colors.FRENCH_ROSE}>
              {i18n.language.toUpperCase()}
            </Text>
            <DownOutlined style={{ fontSize: '16px', color: mobileMode ? Colors.WHITE : Colors.FRENCH_ROSE }} />
          </div>
        </Popover>
      </div>
    </>
  )
}

export default SwitchLanguage
