import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, calculateDynamicFont, FontSize, ModalContainer } from 'constants/index'
import { FunctionComponent, useState } from 'react'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { useTranslation } from 'react-i18next'
import CustomerCard from 'Components/UiKit/CustomerCard'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { handleCardRenewal } from 'utils/functions'
import { selectData } from 'store/user'
import { Space } from 'Components/UiKit/Space'
import styled from 'styled-components'
import { displayDate } from 'utils/functions'
import { useHistory } from 'react-router'
import CouponModal from 'Components/Modals/CouponModal'

const ProfileCardNumber: FunctionComponent = () => {
  const [t, { language }] = useTranslation<string>()
  const userData = useAppSelector(selectData)
  const dispatch = useAppDispatch()
  const [showCouponModal, setShowCouponModal] = useState(false)
  const history = useHistory()

  const handleClick = (coupon?: string) => {
    handleCardRenewal('renew', userData.code, language, dispatch, coupon)
    history.push('/card-renewal')
  }

  return (
    <Container className={'vertical'}>
      <CardWrapper className={'item flex-3 position-relative'}>
        <CustomerCard cardNumber={userData.code.code} />
      </CardWrapper>
      <div className={'item flex-2'}>
        <div className={'vertical justify-content-start'}>
          <Space height={20} />
          <div className={'item flex-none flex-wrap'}>
            <Text
              width={'fit-content'}
              display={'inline'}
              textAlign={'center'}
              fontFamily={FontFamily.BOLD}
              fontWeight={FontWeight.SEVEN}
              fontSize={calculateDynamicFont({
                max: FontSize.TWENTY,
                min: FontSize.SIXTEEN,
              })}
            >
              {userData.code.expires_at
                ? t('cardValidUntil', { date: displayDate(userData.code.expires_at) })
                : t('noExpiryDateMessage')}
            </Text>
          </div>
          <Space height={5} />

          {userData.code.expires_at && (
            <div className={'item flex-none'}>
              <TextBotton
                fontFamily={FontFamily.BOLD}
                fontWeight={FontWeight.SEVEN}
                size={calculateDynamicFont({
                  max: FontSize.EIGHTEEN,
                  min: FontSize.SIXTEEN,
                })}
                onClick={() => setShowCouponModal(true)}
                text={t('renewCard') + ' ›'}
              />
            </div>
          )}
        </div>
      </div>
      <ModalContainer isVisible={showCouponModal} setVisible={() => setShowCouponModal(false)}>
        {showCouponModal && <CouponModal handleClick={handleClick} />}
      </ModalContainer>
    </Container>
  )
}

export default ProfileCardNumber

const Container = styled.div`
  order: 3;
  @media (max-width: 700px) {
    order: 1;
  }
`

const CardWrapper = styled.div`
  flex: none;
  .customCard {
    width: 217px;
    height: 140px;
  }
`
