import { FunctionComponent, useEffect, useState } from 'react'
import MergeOrChangePaymentCard from './MergeOrChangePaymentCard'
import { Colors, ElementTypes, ModalContainer, ResponsiveContainer } from 'constants/index'
import ConnectCardsImage from 'assets/icons/connect_cards.png'
import UpdatePaymentImage from 'assets/icons/update_payment.png'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import { handleCardRenewal, cardNumberPattern, padStartCode } from 'utils/functions'
import PaymentMethodModal from 'Components/Modals/PaymentMethodModal'
import CombineCardsModal from 'Components/Modals/CombineCardsModal'
import styled from 'styled-components'
import { getPaymentCards } from 'store/payment/asyncActions'

const MergeOrChangePayment: FunctionComponent = () => {
  const [t, { language }] = useTranslation<string>()
  const [showPaymentModal, setPaymentModal] = useState(false)
  const [showCombineCardsModal, setShowCombineCardsModal] = useState(false)
  const userData = useAppSelector(selectData)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getPaymentCards())
  }, [dispatch])

  const handleClick = () => {
    handleCardRenewal('add_card', userData.code, language, dispatch)
    localStorage.setItem('subscribe', 'true')
    setPaymentModal(true)
  }

  const closeModals = () => {
    setPaymentModal(false)
    setShowCombineCardsModal(false)
  }

  return (
    <Container className={'connect-and-update-horizontal-container'}>
      <MergeOrChangePaymentCard
        header={t('combineTwoCards')}
        description={t('receivedAnotherMuseumCardAsGift', {
          cardNumber: cardNumberPattern(padStartCode(userData.code.code.toString())),
        })}
        image={ConnectCardsImage}
        buttonText={t('combineTwoCards')}
        buttonType={ElementTypes.PRIMARY}
        bgColor={Colors.FRENCH_ROSE}
        onClick={() => {
          setShowCombineCardsModal(true)
        }}
        type="merge"
      />
      <MergeOrChangePaymentCard
        header={t('updatePaymentInformation')}
        description={t('byUpdatingYourPaymentInformation')}
        image={UpdatePaymentImage}
        buttonType={ElementTypes.SECONDARY}
        bgColor={Colors.ROYAL_BLUE}
        buttonText={t('updatePaymentInformation')}
        onClick={handleClick}
        showBtn={userData.has_standing_order}
        type="payment"
      />
      <ModalContainer
        isVisible={showPaymentModal || showCombineCardsModal}
        type={showCombineCardsModal ? 'combine' : ''}
        setVisible={closeModals}
      >
        {showPaymentModal ? <PaymentMethodModal /> : <CombineCardsModal />}
      </ModalContainer>
    </Container>
  )
}

export default MergeOrChangePayment

const Container = styled(ResponsiveContainer)`
  @media (max-width: 700px) {
    height: fit-content;
    gap: unset;
  }
`
