import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import InfoIcon from 'assets/icons/info.svg'
import CloseIcon from 'assets/icons/close.svg'
import { FontSize, FontWeight, FontFamily, Colors } from 'constants/index'
import { Offer } from 'api/main'

interface Props {
  setVisibleAnnouncement: (value: boolean) => void
  text: Array<Offer>
  isMobile?: boolean
}

const HomeHeader: VoidFunctionComponent<Props> = ({ text, setVisibleAnnouncement, isMobile }: Props) => {
  const handleClose = () => {
    setVisibleAnnouncement(false)
  }

  return (
    <YellowBox>
      <div className="d-flex align-items-center">
        <InfoImg src={InfoIcon} />
        {text?.map((item: Offer, index) => {
          return <TextStyle key={index} dangerouslySetInnerHTML={{ __html: item.text }} isMobile={isMobile} />
        })}
      </div>

      <img onClick={handleClose} src={CloseIcon} alt="close" />
    </YellowBox>
  )
}

export default HomeHeader

const YellowBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 61px;
  justify-content: space-between;
  background-color: ${Colors.LASER_LEMON};
  @media (max-width: 700px) {
    padding: 0 25px;
  }
`
const InfoImg = styled.img`
  margin-right: 16px;
`
const TextStyle = styled.div<{ isMobile?: boolean }>`
  text-align: left;
  font-weight: ${({ isMobile }) => (isMobile ? FontWeight.SEVEN : FontWeight.NORMAL)};
  font-family: ${({ isMobile }) => (isMobile ? FontFamily.BOLD : FontFamily.NORMAL)};
  font-size: ${({ isMobile }) => (isMobile ? FontSize.THIRTEEN : FontSize.SIXTEEN)};
  line-height: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
`
