import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'
import { renderLang } from 'utils'
import { paymentForm, paymentCard } from './paymentFormModel'

export const getPaymentForms: AsyncThunk<any, paymentForm, { rejectValue: string }> = createAsyncThunk(
  'web/payment/forms',
  async (body: paymentForm, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webPaymentForms(body)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const webPaymentCard: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  'web/payment/card',
  async (body: paymentCard, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webPaymentCard(body)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const webPaymentSuccess: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  'web/payment/success',
  async (body: any, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webPaymentSuccess(body)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const webPaymentTransaction: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  'web/transaction',
  async (body: any, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webTransactionUpdate(body.transaction_id, body)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const museumCodeMerge: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  'web/card/merge',
  async (body: any, { rejectWithValue, dispatch }) => {
    try {
      let params = {
        museum_id: 22256,
        source: 'customer_portal',
        code: body.code,
        gift_code: body.gift_code,
      }
      return await WebService.webCodeMerge(params, renderLang())
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const checkCouponCode: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  'web/coupon/check',
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      let params = {
        from: 'customer_portal',
        coupon_code: code,
      }
      return await WebService.webCouponCheck(params, renderLang())
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)

export const getPaymentCards = createAsyncThunk('web/payment/cards', async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await WebService.webUserPaymentCards()

    return response
  } catch (error) {
    return handleApiCallError(error, rejectWithValue, dispatch)
  }
})

export const requestLogger: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  'web/util/logger',
  async (body: any, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.requestLogger(body)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
