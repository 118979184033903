import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Header } from 'Components/UiKit/Header'
import VisitRightSide from './VisitRightSide'
import { Colors } from 'constants/colors'
import { Footer } from 'Components/UiKit/Footer'
import VisiteItems from './VisiteItem/VisiteItems'

const DesktopSize: VoidFunctionComponent = () => {
  const { t } = useTranslation<string>()

  return (
    <Container>
      <ContentContainer>
        <div>
          <Header title={t('visits')} subTitle={t('allYourVisits')} />
        </div>
        <BodyContainer>
          <VisiteItems />
          <VisitRightSide />
        </BodyContainer>
      </ContentContainer>
      <div className={'item flex-none item-top-left'}>
        <Footer />
      </div>
    </Container>
  )
}

export default DesktopSize

const Container = styled.div`
  width: 100%;
  background: ${Colors.ALICE_BLUE};
`
const ContentContainer = styled.div`
  padding: 0px 60px 0px 65px;
`
const BodyContainer = styled.div`
  display: flex;
  gap: 32px;
`
