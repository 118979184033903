import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import MessageList from './MessageList'
import { Context } from '..'
import { Pagination } from 'antd'
import { FontWeight, FontFamily, FontSize, Colors } from 'constants/index'
import PrevLongArrow from 'assets/iconComponents/PrevLongArrow'
import NextLongArrow from 'assets/iconComponents/NextLongArrow'

const messages = [
  {
    read: true,
    title: 'Test1',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test2',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: true,
    title: 'Test3',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test4',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: true,
    title: 'Test5',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test6',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: true,
    title: 'Test7',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test8',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: true,
    title: 'Test9',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test10',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test11',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: true,
    title: 'Test12',
    date: new Date(),
    mesage: 'test test test test test',
  },
  {
    read: false,
    title: 'Test13',
    date: new Date(),
    mesage: 'test test test test test',
  },
]

const MobileInbox = () => {
  const [data, setData] = useState([])
  const { value, setValue } = useContext(Context)

  useEffect(() => {
    setData(messages.slice((value.current - 1) * 6, value.current * 6))
  }, [value])

  const itemRender = (current: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return (
        <button>
          <PrevLongArrow style={{ transform: 'rotate(180deg' }} />
        </button>
      )
    }
    if (type === 'next') {
      return (
        <button>
          <NextLongArrow />
        </button>
      )
    }
    return originalElement
  }

  const handleClick = (index: number) => {
    setValue({
      current: 1,
      message: messages[index],
      showMessage: true,
    })
  }

  const onPageChange = (e: number) => {
    setValue({
      ...value,
      current: e,
    })
  }

  return (
    <Container>
      <MessageList messages={data} setSelectedMessage={handleClick}></MessageList>
      <Pagination
        defaultCurrent={1}
        total={messages.length}
        pageSize={6}
        onChange={onPageChange}
        itemRender={itemRender}
      />
    </Container>
  )
}
export default MobileInbox

const Container = styled.div`
  height: 690px;
  width: 100%;
  border-radius: 16px;
  background: ${Colors.WHITE};
  padding: 32px 16px 10px;
  margin-bottom: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .ant-pagination {
    margin-top: 21px;
    display: flex;
    & li,
    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      border: none;
      color: ${Colors.FRENCH_ROSE};
      width: 16px;
      min-width: unset;
      font-size: ${FontSize.THIRTEEN};
      font-family: ${FontFamily.BOLD};
      font-weight: ${FontWeight.SEVEN};
    }
    & a {
      padding: 0px;
    }

    & .ant-pagination-prev,
    .ant-pagination-next {
      span {
        vertical-align: unset;
      }
      button {
        padding: 0px;
        background: none;

        svg {
          width: 11px;
          height: 9px;
        }
        &:disabled {
          path {
            fill: ${Colors.GREY_CHATEAU};
          }
          opacity: 0.5;
        }
      }
    }
    & .ant-pagination-prev {
      margin-right: 32px;
    }
    & .ant-pagination-next {
      margin-left: 32px;
    }
    & .ant-pagination-item-active {
      a {
        color: ${Colors.DARK_PURPLE} !important;
      }
      border-bottom: 2px solid ${Colors.DARK_PURPLE} !important;
      border-radius: 0px;
    }
  }
`
