import { useEffect, useState, FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ObjectSchema } from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Button from 'Components/UiKit/Buttons/Button'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { loginRequestAsync, requestPasswordReset, resetPasswordReset } from 'store/auth/asyncActions'
import { ObjectShape } from 'yup/lib/object'
import { AppLoginForm } from 'store/auth/asyncActions'
import { selectLoginError, selectLoginStatus } from 'store/auth'
import Input from 'Components/UiKit/Input'
import styled from 'styled-components'
import { Colors, FontFamily, FontSize, FontWeight } from 'constants/index'
import Text from 'Components/UiKit/Text'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { loginTypes } from 'constants/types'
import { selectPassResetFailMessage, deletePassResetMessages } from 'store/auth'
import { removeSpaces, returnErrorMessageContent, generateFieldUuid } from 'utils/functions'
import { Status } from 'models'

const loginSchema = (t: TFunction): ObjectSchema<ObjectShape> =>
  Yup.object().shape({
    password: Yup.string().when('type', {
      is: loginTypes.LOGIN,
      then: Yup.string().required(t('required')),
    }),
    user: Yup.string().when('type', {
      is: loginTypes.LOGIN,
      then: Yup.string().required(t('required')),
    }),
    password_confirmation: Yup.string().when('type', {
      is: loginTypes.RESET,
      then: Yup.string()
        .required(t('required'))
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
  })

interface Props {
  setType: (type: loginTypes) => void
  type: loginTypes
}

export const Login: FunctionComponent<Props> = ({ setType, type }) => {
  const [t] = useTranslation<string>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const loginError = useAppSelector(selectLoginError)
  const resetPassFailMessage = useAppSelector(selectPassResetFailMessage)
  const status = useAppSelector(selectLoginStatus)

  const [token, setToken] = useState('')
  const [displayRequestSendMessage, setRequestSendMessage] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: { user: '', password: '', type: loginTypes.LOGIN, password_confirmation: '' },
    resolver: yupResolver(loginSchema(t)),
  })

  useEffect(() => {
    if (resetPassFailMessage) toast.error(returnErrorMessageContent(resetPassFailMessage)[0])
    dispatch(deletePassResetMessages())
  }, [dispatch, resetPassFailMessage])
  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search)
    if (urlParams.get('token')) {
      setToken(urlParams.get('token'))
      setType(loginTypes.RESET)
      setValue('type', loginTypes.RESET)
    }
  }, [history, setValue, setType])

  useEffect(() => {
    if (displayRequestSendMessage) {
      toast.success(t('requestSent'))
      setRequestSendMessage(false)
    }
  }, [displayRequestSendMessage, t])

  useEffect(() => {
    if (loginError) {
      if (loginError.message) toast.error(loginError.message)
      else toast.error(returnErrorMessageContent(loginError)[0])
    }
  }, [loginError])

  const onSubmit = handleSubmit(async (data: AppLoginForm) => {
    const params = { ...data, user: removeSpaces(data.user.toString()).toLowerCase() }
    if (type === loginTypes.LOGIN) dispatch(loginRequestAsync(params))
    else if (type === loginTypes.RESET_REQUEST)
      dispatch(requestPasswordReset(params)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') setRequestSendMessage(true)
      })
    else dispatch(resetPasswordReset({ ...params, token, resolve: () => setType(loginTypes.LOGIN) }))
  })

  return (
    <div className="w-100">
      <div className="w-100">
        {type !== loginTypes.RESET && (
          <>
            <InputContainer
              id={generateFieldUuid('username')}
              type="text"
              placeholder={t('username')}
              {...register('user')}
              control={control}
            />
            {errors.user && <Error>{errors.user?.message}</Error>}
          </>
        )}
        {type !== loginTypes.RESET_REQUEST && (
          <>
            <InputContainer
              id={generateFieldUuid('password')}
              type="password"
              placeholder={t('password')}
              className="mt-3"
              {...register('password')}
              control={control}
            />
            {errors.password && <Error>{errors.password?.message}</Error>}
          </>
        )}
        {type === loginTypes.RESET && (
          <>
            <InputContainer
              id={generateFieldUuid('password_confirmation')}
              type="password"
              placeholder={t('password_confirmation')}
              className="mt-3"
              {...register('password_confirmation')}
              control={control}
            />
            {errors.password_confirmation && <Error>{errors.password_confirmation.message}</Error>}
          </>
        )}
        <BtnContainer
          onClick={onSubmit}
          id={generateFieldUuid('login', 'button')}
          fontSize={FontSize.SIXTEEN}
          text={type === loginTypes.LOGIN ? t('submit') : type === loginTypes.RESET ? t('reset') : t('resetRequest')}
          className="mt-3 w-100"
          disabled={status === Status.PENDING}
          isLoading={status === Status.PENDING}
        />
        {type !== loginTypes.RESET && (
          <Text
            id={generateFieldUuid('forgotPassword', 'link')}
            fontWeight={FontWeight.SEVEN}
            fontFamily={FontFamily.BOLD}
            className="pointer mt-2"
            fontSize={FontSize.EIGHTEEN}
            color={Colors.FRENCH_ROSE}
            onClick={() => {
              let newType = type === loginTypes.LOGIN ? loginTypes.RESET_REQUEST : loginTypes.LOGIN
              setValue('type', newType)
              setType(newType)
            }}
          >
            {type === loginTypes.LOGIN ? t('forgotPassword') : t('back')}
          </Text>
        )}
      </div>
    </div>
  )
}

const InputContainer = styled(Input)`
  padding: 0px;
  input {
    box-shadow: none;
    border: 1px solid ${Colors.LIGHT_BLUE};
  }
`
const BtnContainer = styled(Button)`
  height: 62px;
  border: none;
`

const Error = styled.div`
  color: ${Colors.RED};
  font-weight: ${FontWeight.SEVEN};
  font-family: ${FontFamily.NORMAL};
  font-size: ${FontSize.TWELVE};
`
