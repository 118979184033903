import Text from '../../../../UiKit/Text'
import { useTranslation } from 'react-i18next'
import { calculateFont, FontWeight } from '../../../../../constants/fonts'
import { FunctionComponent } from 'react'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'

const Footer: FunctionComponent = () => {
  const { t } = useTranslation<string>()

  return (
    <div className={'horizontal'}>
      <div className={'item flex-1'}>
        <Text fontWeight={FontWeight.NORMAL} fontSize={calculateFont({ coefficient: 0.4 })}>
          {t('wantToDeleteTheAccount')}
          &nbsp;
          <TextBotton
            containerClassName={'fit-content display-contents'}
            fontWeight={FontWeight.NORMAL}
            display={'contents'}
            width={'fit-content'}
            size={calculateFont({ coefficient: 0.4 })}
            onClick={() => window.open(`https://museot.fi/asiakaspalvelu/`)}
            text={t('contactOurCustomerService')}
          />
        </Text>
      </div>
    </div>
  )
}

export default Footer
