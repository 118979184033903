import { VoidFunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Header } from 'Components/UiKit/Header'
import HomeHeader from './DesktopSize/HomeHeader'
import Notice from './DesktopSize/Notice'
import Order from './DesktopSize/Order'
import LevelAndVisited from './DesktopSize/LevelAndVisited'
import CalturalAdvantage from './DesktopSize/CalturalAdvantage'
import Subscribe from './DesktopSize/Subscribe'
import HomeRightSide from './DesktopSize/HomeRightSide'
import { Colors } from 'constants/colors'
import NewsSlider from './DesktopSize/NewsSlider'
import ExhibitionSlider from './DesktopSize/ExhibitionSlider'
import { Footer } from 'Components/UiKit/Footer'
import { useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import { selectAnnouncementData } from 'store/announcement'
import { selectPriceData } from 'store/price'
import { returnPrice } from 'utils/functions'

const DesktopSize: VoidFunctionComponent = () => {
  const [t, { language }] = useTranslation<string>()
  const [visibleAnnouncement, setVisibleAnnouncement] = useState(true)
  const userData = useAppSelector(selectData)
  const announcementData = useAppSelector(selectAnnouncementData)
  const priceData = useAppSelector(selectPriceData)

  return (
    <Container>
      <LeftSide>
        {announcementData &&
          visibleAnnouncement &&
          announcementData?.data.filter(item => item.text_language === language).length > 0 && (
            <HomeHeader text={announcementData?.data} setVisibleAnnouncement={setVisibleAnnouncement} />
          )}
        <BodyWrapper>
          <Header title={t('yourCustomerInfo')} subTitle={t('manageEverythingEasily')} />
          <Notice price={returnPrice(userData?.code.expires_at, priceData)} />
          <NewsSlider name={userData.firstname} />
          <CardWrapper>
            <Order />
            <LevelAndVisited />
          </CardWrapper>
          <CalturalAdvantage />
          <Subscribe />
          <ExhibitionSlider />
        </BodyWrapper>
        <div className={'item flex-none item-top-left'}>
          <Footer />
        </div>
      </LeftSide>
      <HomeRightSide userInfo={userData} />
    </Container>
  )
}

export default DesktopSize

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`
const LeftSide = styled.div`
  flex: 8;
  background: ${Colors.ALICE_BLUE};
  overflow-y: auto;
`

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
`
const BodyWrapper = styled.div`
  padding: 0px 60px;
  width: 100%;
`
