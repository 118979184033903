import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Text from 'Components/UiKit/Text'
import { FontFamily, FontSize, FontWeight } from 'constants/index'
import LastVisitedItem from './LastVisitedItem'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { selectVisitsListData } from 'store/visits/visitsList'
import { useAppSelector } from 'store/hooks'
import { generateFieldUuid } from 'utils/functions'

const LastVisited: VoidFunctionComponent = () => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const visitsData = useAppSelector(selectVisitsListData)
  const visits = visitsData?.data.slice(0, 3)?.map((item: any) => {
    return {
      rate: item.rating?.data?.rating || 0,
      date: item?.created_at || '',
      title: item?.museum?.data?.name,
    }
  })

  return (
    <Contanier>
      <TextStyled>
        <Text
          textAlign="left"
          fontSize={FontSize.EIGHTEEN}
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
        >
          {t('recentVisits')}
        </Text>
      </TextStyled>
      {visits?.map((item: any, index: number) => (
        <LastVisitedItem
          key={index}
          rate={item.rate}
          date={item.date}
          title={item.title}
          buttonText={t('viewAllTheReviews')}
        />
      ))}
      <BtnContainer
        size={FontSize.FOURTEEN}
        fontFamily={FontFamily.BOLD}
        onClick={() => history.push('/visits')}
        text={t('showAllVisitsAndReviews')}
        id={generateFieldUuid('showAllVisitsAndReviews', 'link')}
      />
    </Contanier>
  )
}

export default LastVisited

const Contanier = styled.div`
  order: 2;
  @media (max-width: 700px) {
    order: 1;
  }
`
const TextStyled = styled.div`
  margin-bottom: 21px;
`

const BtnContainer = styled(TextBotton)`
  @media (max-width: 700px) {
    margin-top: 29px;
    margin-bottom: 40px;
  }
`
