const SaastopankkiIcon = (props: any) => {
  return (
    <svg width="72" height="67" viewBox="0 0 72 67" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.2061 36.3145C38.0367 40.7357 36.125 46.0073 36.125 51.5839H35.8442C35.8442 45.9893 33.9666 40.7176 30.7952 36.3145H21.3491C26.0631 39.9834 28.9136 45.5901 28.9136 51.5839C28.9136 57.6198 26.0411 63.2425 21.293 66.8994H50.7224C45.9703 63.2405 43.0978 57.6158 43.0978 51.5839C43.0978 45.5841 45.9442 39.9794 50.6542 36.3145H41.2061Z"
        fill="#00963F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5943 17.2833L17.5903 17.5902L17.2794 17.5942C7.91352 17.7306 0 26.1597 0 35.9989C0 40.9918 1.9518 45.6978 5.49834 49.2503C9.05291 52.7989 13.7689 54.7527 18.7818 54.7527C20.6273 54.7527 22.5069 54.4277 24.3704 53.846C24.4025 53.4869 24.567 51.5853 24.567 51.5853C24.567 49.3988 24.1056 47.3607 23.207 45.3487C21.8429 46.0167 20.3986 46.3818 18.7818 46.3818C16.0096 46.3818 13.4018 45.2986 11.436 43.3327C9.47617 41.3609 8.39696 38.7591 8.39696 35.9989C8.39696 33.2187 9.47215 30.6109 11.432 28.6611C13.3878 26.7053 15.9955 25.6281 18.7778 25.6281C21.3876 25.6281 23.0365 26.1256 24.6232 26.6833C24.6232 26.6833 26.8679 27.5699 28.0353 28.0333C27.6602 27.0784 26.6853 24.6151 26.6853 24.6151C26.1257 23.0224 25.6302 21.3795 25.6302 18.7677C25.6302 15.9975 26.7114 13.3877 28.6772 11.4219C30.629 9.46407 33.2268 8.38486 35.995 8.38486C38.7753 8.38486 41.381 9.46407 43.3348 11.4219C45.2986 13.3917 46.3758 15.9975 46.3758 18.7677C46.3758 21.3494 45.9004 22.9441 45.3187 24.6131C45.3187 24.6151 44.4341 26.8658 43.9707 28.0333C44.9255 27.6561 47.3748 26.6833 47.3748 26.6833C49.0337 26.1035 50.6205 25.6281 53.2362 25.6281C56.0185 25.6281 58.6222 26.7053 60.57 28.6631C62.5299 30.6149 63.6131 33.2227 63.6131 35.9989C63.6131 38.7612 62.5299 41.3649 60.564 43.3327C58.6042 45.2986 56.0005 46.3818 53.2242 46.3818C51.6034 46.3818 50.1611 46.0167 48.8051 45.3487C47.9024 47.3447 47.439 49.3827 47.439 51.5853C47.439 51.5853 47.6075 53.4869 47.6416 53.846C49.4971 54.4277 51.3747 54.7527 53.2242 54.7527C58.2431 54.7527 62.9611 52.7989 66.5037 49.2503C70.0482 45.7138 72 41.0078 72 35.9989C72 26.1597 64.0885 17.7306 54.7247 17.5942L54.4198 17.5902L54.4157 17.2833C54.2793 7.91547 45.8483 -6.10352e-05 35.995 -6.10352e-05C26.1618 -6.10352e-05 17.7347 7.91547 17.5943 17.2833Z"
        fill="#00963F"
      />
    </svg>
  )
}

export default SaastopankkiIcon
