import { getPaymentForms } from 'store/payment/asyncActions'
import { resetAll } from 'store/payment'
import { RenewalTypes } from '../constants'
import { Dispatch } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { ENV } from '../constants'
import { priceResource } from 'store/price/priceModel'

export function getRandomColor() {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find(key => object[key] === value)
}

export const cardNumberPattern = (value: string) => value.replace(/^(.{4})(.{4})(.*)$/, '$1 $2 $3')

export const padStartCode = (value: string): string => value.padStart(12, '0')

export const checkExpired = (date: string) => {
  const expiredDate = dayjs(date).format('YYYY-MM-DD')
  const today = dayjs().format('YYYY-MM-DD')
  return expiredDate < today
}

export const returnGroupCards = (cards: any[], paymentGroups: {}) => {
  let returnCards: any = []
  cards
    .filter(card => Object.keys(paymentGroups).includes(card.group))
    .forEach(card => {
      let paymentGroup = paymentGroups[card.group as keyof typeof paymentGroups]
      let item = { ...card }
      let action = paymentGroup['action'] || paymentGroup['action_url'] || paymentGroup
      item['action_url'] = action
      if (item.group === 'paytrail') {
        let cardMethod = paymentGroup['methods'][item.id]
        item['method'] = cardMethod
      }
      if (item.group !== 'smartum') item['fields'] = paymentGroup['fields']
      returnCards.push(item)
    })
  return returnCards
}

export const returnPaytrailGroupCards = (cards: any[], paymentGroup: any) => {
  let returnCards: any[] = []
  cards.forEach(card => {
    let item = { ...card }
    item['action_url'] = paymentGroup['methods'][item.id]?.action_url
    item['fields'] = paymentGroup['methods'][item.id]?.fields
    returnCards.push(item)
  })
  return returnCards
}

export const redirectPost = (url: string, data: any): void => {
  const form = document.createElement('form')
  document.body.appendChild(form)
  form.method = 'post'
  form.action = url
  for (const name in data) {
    if (data.hasOwnProperty(name)) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = name
      input.value = data[name]
      form.appendChild(input)
    }
  }
  form.submit()
}

export const returnErrorMessageContent = (data: any): any => {
  if (!!data) {
    return Object.keys(data).map(val => {
      if (typeof val === 'string') return data[val] + `\n`
      else return data[val][0] + `\n`
    })
  } else return ''
}

export const handleCardRenewal = (
  type: string,
  userInfo: any,
  language: string,
  dispatch: Dispatch<any>,
  coupon: string = ''
) => {
  let params: any = {
    type: type,
    source: 'customer_portal',
    user_id: userInfo.user_id,
    contact_lang: language,
    environment: ENV,
  }
  if (type === 'renew') {
    params['amount'] = '1'
    params['renewal_type'] = checkExpired(userInfo.expires_at) ? RenewalTypes.Expired : RenewalTypes.Valid
    if (coupon) params['coupon_code'] = coupon
    localStorage.setItem('coupon', coupon)
  }

  dispatch(resetAll())
  dispatch(getPaymentForms(params))
}

export const checkBtnAccess = (userData: any) => {
  return userData.valid_for_renewal && userData.code.expires_at ? true : false
}
export const checkMergeBtnAccess = (userInfo: any) => {
  return userInfo.valid_for_renewal && userInfo.code.expires_at !== '0000-00-00 00:00:00' ? true : false
}
export const displayDate = (date: any) => {
  return dayjs(date).format('DD.M.YYYY')
}

export const formatCardNumber = (number: string): string => {
  let formattedText = number.split(' ').join('')
  if (!!formattedText) {
    formattedText = formattedText.match(new RegExp('.{1,4}', 'g')).join(' ')
  }
  return formattedText
}
export const removeSpaces = (value: string): string => value.replace(/\s/g, '')

export const htmlEntityDecode = (str: string): string => {
  if (typeof str === 'string') {
    str = str.replace('&nbsp;', ' ')
    str = str.replace(/\\n/g, '  ')
    str = str.replace(/<br>/g, '\n')
    str = str.replace(/&quot;/g, "''")
    str = str.replace(/&#228;/g, 'ä')
    str = str.replace(/&#226;/g, 'ö')
    str = str.replace(/&rdquo;/g, '"')
    str = str.replace(/&ndash;/g, '-')
    str = str.replace(//g, '€')
    str = str.replace(/&gt;/g, '>')
    str = str.replace('', '')
    return str.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec)
    })
  }
  return str
}

export const isCardExpired = (expires_at: string | undefined): boolean => {
  return !!(expires_at && dayjs(expires_at).diff(dayjs(dayjs().format('YYYY-MM-DD') + ' 00:00:00')) < 0)
}

export const returnPrice = (expires_at: string | undefined, priceData: priceResource) => {
  return expires_at && isCardExpired(expires_at) ? priceData?.renewal_after_expiry : priceData?.renewal_before_expiry
}

export const generateFieldUuid = (name: string, secondPart: any = 'field'): string => {
  return `${name}-${secondPart.replace(/\s/g, '')}`
}

export const changeLang = (lang: any, i18n: any, history: any) => {
  i18n.changeLanguage(lang)
  let path = history.location.pathname.split('/')
  path[path.length - 1] = lang
  history.push(path.join('/'))
}

export const formatPrice = (value: number, hasSign = true): string =>
  (value % 1 === 0 ? `${value}` : `${value.toFixed(2).replace('.', ',')}`) + `${hasSign ? ' ' : ''}`
