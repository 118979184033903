import styled from 'styled-components'
import { useAppSelector } from 'store/hooks'
import { selectPaymentCards } from 'store/payment'
import Text from 'Components/UiKit/Text'
import { Colors } from 'constants/index'
import { useTranslation } from 'react-i18next'

const UserPaymentCards = () => {
  const [t] = useTranslation()

  const cards = useAppSelector(selectPaymentCards)
  return (
    <Container>
      {cards?.map((card: any, index: number) => (
        <CardContainer key={index}>
          <CardItem>
            <Text textAlign="left" width="60%">
              {t('paymentCardType')}
            </Text>
            <Text textAlign="right" width="40%" color={Colors.FRENCH_ROSE}>
              {card.type}
            </Text>
          </CardItem>
          <CardItem>
            <Text textAlign="left">{t('paymentCard')}</Text>
            <Text textAlign="right" color={Colors.FRENCH_ROSE}>{`**** **** **** ${card.card_ending}`}</Text>
          </CardItem>
          <CardItem>
            <Text textAlign="left">{t('validUntil')}</Text>
            <Text textAlign="right" color={Colors.FRENCH_ROSE}>{`${card.expiry_month}/${card.expiry_year}`}</Text>
          </CardItem>
        </CardContainer>
      ))}
    </Container>
  )
}

export default UserPaymentCards

const ColumFlex = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Container = styled(ColumFlex)`
  margin-top: 20px;
`
const CardContainer = styled(ColumFlex)`
  gap: 10px;
  max-width: 300px;
  background: white;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
`

const CardItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
