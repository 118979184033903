import NotificationBanner from './NotificationBanner'
import ReceiptsList from './ReceiptsList'
import { FunctionComponent } from 'react'
import Text from 'Components/UiKit/Text'
import { calculateFont, FontWeight, FontFamily } from 'constants/index'
import { useTranslation } from 'react-i18next'
import { Space } from 'Components/UiKit/Space'
import ContactCustomerSupport from 'Components/ContactCustomerSupport'

const Receipts: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  return (
    <div className={'vertical justify-content-between py-5'}>
      <div className={'item flex-none'}>
        <Text
          textAlign={'left'}
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          fontSize={calculateFont({ coefficient: 1.0 })}
        >
          {t('receipts')}
        </Text>
      </div>
      <NotificationBanner />
      <div className={'item flex-none justify-content-start align-items-start '}>
        <ReceiptsList />
      </div>
      <ContactCustomerSupport />
      <Space height={20} />
    </div>
  )
}
export default Receipts
