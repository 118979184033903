import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { Status } from 'models'
import { getNews, subscribeToNews } from './asyncActions'

export interface PriceState {
  data: any[] | null
  status: Status
  error: string | any
  subscribeMessage: string | any
}

const initialState: PriceState = {
  data: [],
  error: null,
  status: Status.IDLE,
  subscribeMessage: null,
}

export const newsSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    reset: state => {
      state = initialState
    },
    resetMessages: state => {
      state.subscribeMessage = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getNews.pending, state => {
        state.error = null
        state.status = Status.PENDING
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.data = action.payload.data || []
        state.status = Status.SUCCEEDED
      })
      .addCase(subscribeToNews.fulfilled, (state, action) => {
        state.subscribeMessage = Status.SUCCEEDED
        state.status = Status.SUCCEEDED
      })
      .addCase(subscribeToNews.rejected, (state, action) => {
        state.subscribeMessage = action.payload
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
      .addCase(getNews.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const { reset, resetMessages } = newsSlice.actions

export const selectNewsData = (state: RootState): PriceState['data'] => state.news.data
export const selectNewsError = (state: RootState): PriceState['error'] => state.news.error
export const selectNewsStatus = (state: RootState): PriceState['status'] => state.news.status
export const selectSubscribeNewsMessage = (state: RootState): PriceState['subscribeMessage'] =>
  state.news.subscribeMessage

export default newsSlice.reducer
