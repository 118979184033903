const HandelsbankenIcon = (props: any) => {
  return (
    <svg width="118" height="13" viewBox="0 0 118 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.48066 6.54915V11.8745H0V0H2.48066V4.49846H7.11142V0H9.59222V11.8745H7.11142V6.54915H2.48066Z"
        fill="#003399"
      />
      <path
        d="M18.8054 10.1545C18.8054 11.1965 19.037 11.4282 19.3181 11.5273V11.8745H16.8208C16.6885 11.4609 16.6555 11.3288 16.6058 10.8327C16.0765 11.3783 15.3324 12.1061 13.7612 12.1061C12.4381 12.1061 11.082 11.362 11.082 9.5427C11.082 7.82285 12.1735 6.94611 13.6454 6.7312L15.7292 6.41688C16.093 6.36732 16.5395 6.21849 16.5395 5.7223C16.5395 4.74659 15.6134 4.69703 15.0181 4.69703C13.8603 4.69703 13.662 5.40813 13.5957 5.92084H11.3632C11.6278 3.22513 13.4966 2.84473 15.2994 2.84473C16.5397 2.84473 18.8054 3.22513 18.8054 5.39161V10.1545ZM16.5397 7.59113C16.3081 7.773 15.9278 7.92182 14.9685 8.07065C14.1084 8.21963 13.3973 8.43468 13.3973 9.39387C13.3973 10.2041 14.0258 10.4523 14.4725 10.4523C15.4977 10.4523 16.5397 9.79094 16.5397 8.71596V7.59113Z"
        fill="#003399"
      />
      <path
        d="M28.6617 11.8747H26.3464V6.53267C26.3464 5.8878 26.3134 4.7963 24.8415 4.7963C23.816 4.7963 23.0223 5.49088 23.0223 6.83062V11.8747H20.707V3.0763H22.9231V4.36634H22.9563C23.2704 3.83711 23.932 2.84473 25.6023 2.84473C27.3223 2.84473 28.6619 3.85363 28.6619 5.78853V11.8747H28.6617Z"
        fill="#003399"
      />
      <path
        d="M38.6831 11.8745H36.4173V10.7663H36.3843C35.822 11.7588 34.7966 12.1059 33.8374 12.1059C31.2243 12.1059 30.1328 9.79076 30.1328 7.37603C30.1328 4.33311 31.8528 2.84454 33.7877 2.84454C35.2761 2.84454 35.9707 3.65505 36.3347 4.21717H36.3678V0H38.6832V11.8745H38.6831ZM34.4162 10.2041C35.8881 10.2041 36.4669 8.98024 36.4669 7.67384C36.4669 6.26816 36.07 4.84582 34.4823 4.84582C33.0104 4.84582 32.5142 6.15237 32.5142 7.54168C32.5143 8.58332 32.9278 10.2041 34.4162 10.2041Z"
        fill="#003399"
      />
      <path
        d="M48.1431 9.29461C47.5313 11.4115 45.7781 12.1061 44.2731 12.1061C41.7925 12.1061 39.8906 10.9155 39.8906 7.29362C39.8906 6.23501 40.2544 2.84473 44.1078 2.84473C45.8443 2.84473 48.2424 3.67161 48.2424 7.69054V8.10399H42.2059C42.2722 8.76552 42.4045 10.2541 44.2733 10.2541C44.9183 10.2541 45.5798 9.92325 45.7617 9.29475H48.1431V9.29461ZM45.8608 6.59875C45.7285 5.17641 44.8189 4.74644 44.0748 4.74644C42.9833 4.74644 42.4043 5.44102 42.272 6.59875H45.8608Z"
        fill="#003399"
      />
      <path d="M51.995 11.8745H49.6797V0H51.995V11.8745Z" fill="#003399" />
      <path
        d="M59.0258 5.75534C58.9265 5.19307 58.7279 4.59776 57.471 4.59776C56.2142 4.59776 56.0487 5.07729 56.0487 5.45769C56.0487 6.84684 61.5559 5.83809 61.5559 9.0797C61.5559 11.643 59.0754 12.1061 57.7026 12.1061C55.7015 12.1061 53.535 11.4115 53.4688 9.06303H55.8503C55.8503 9.80731 56.3134 10.3531 57.7688 10.3531C58.9594 10.3531 59.2407 9.77413 59.2407 9.44343C59.2407 7.97153 53.7831 9.0797 53.7831 5.78853C53.7831 4.4985 54.5934 2.84473 57.3387 2.84473C59.0586 2.84473 61.1094 3.27469 61.2914 5.75549H59.0258V5.75534Z"
        fill="#003399"
      />
      <path
        d="M65.3272 4.21717H65.3602C65.7241 3.6549 66.4188 2.84454 67.9071 2.84454C69.8421 2.84454 71.5621 4.33296 71.5621 7.37603C71.5621 9.79076 70.4706 12.1059 67.8575 12.1059C66.8983 12.1059 65.8729 11.7587 65.3107 10.7663H65.2776V11.8745H63.0117V0H65.3272V4.21717ZM67.2125 4.84567C65.6247 4.84567 65.2279 6.26801 65.2279 7.67369C65.2279 8.9801 65.8067 10.2039 67.2786 10.2039C68.767 10.2039 69.1806 8.58332 69.1806 7.54138C69.1806 6.15223 68.6843 4.84567 67.2125 4.84567Z"
        fill="#003399"
      />
      <path
        d="M80.1452 10.1545C80.1452 11.1965 80.3766 11.4282 80.6579 11.5273V11.8745H78.1605C78.0284 11.4609 77.9952 11.3288 77.9455 10.8327C77.4163 11.3783 76.6721 12.1061 75.1011 12.1061C73.778 12.1061 72.4219 11.362 72.4219 9.5427C72.4219 7.82285 73.5132 6.94611 74.9851 6.7312L77.0692 6.41688C77.4329 6.36732 77.8794 6.21849 77.8794 5.7223C77.8794 4.74659 76.9533 4.69703 76.3581 4.69703C75.2002 4.69703 75.002 5.40813 74.9356 5.92084H72.7029C72.9676 3.22513 74.8363 2.84473 76.6392 2.84473C77.8794 2.84473 80.1453 3.22513 80.1453 5.39161V10.1545H80.1452ZM77.8793 7.59113C77.6478 7.773 77.2674 7.92182 76.3082 8.07065C75.4481 8.21963 74.737 8.43468 74.737 9.39387C74.737 10.2041 75.3657 10.4523 75.8122 10.4523C76.8375 10.4523 77.8794 9.79094 77.8794 8.71596V7.59113H77.8793Z"
        fill="#003399"
      />
      <path
        d="M90.0017 11.8747H87.6864V6.53267C87.6864 5.8878 87.6532 4.7963 86.1814 4.7963C85.156 4.7963 84.3622 5.49088 84.3622 6.83062V11.8747H82.0469V3.0763H84.2629V4.36634H84.2961C84.6103 3.83711 85.2717 2.84473 86.9421 2.84473C88.662 2.84473 90.0017 3.85363 90.0017 5.78853V11.8747Z"
        fill="#003399"
      />
      <path
        d="M94.3661 11.8745H92.0508V0H94.3661V6.41684L97.2767 3.07611H100.121L97.0121 6.48292L100.204 11.8745H97.4257L95.3254 8.10365L94.3661 9.09603V11.8745Z"
        fill="#003399"
      />
      <path
        d="M108.573 9.29461C107.961 11.4115 106.208 12.1061 104.703 12.1061C102.222 12.1061 100.32 10.9155 100.32 7.29362C100.32 6.23501 100.684 2.84473 104.538 2.84473C106.274 2.84473 108.672 3.67161 108.672 7.69054V8.10399H102.635C102.702 8.76552 102.834 10.2541 104.703 10.2541C105.348 10.2541 106.009 9.92325 106.191 9.29475H108.573V9.29461ZM106.291 6.59875C106.158 5.17641 105.249 4.74644 104.504 4.74644C103.413 4.74644 102.834 5.44102 102.702 6.59875H106.291Z"
        fill="#003399"
      />
      <path
        d="M117.998 11.8747H115.682V6.53267C115.682 5.8878 115.649 4.7963 114.178 4.7963C113.152 4.7963 112.358 5.49088 112.358 6.83062V11.8747H110.043V3.0763H112.259V4.36634H112.292C112.606 3.83711 113.268 2.84473 114.938 2.84473C116.658 2.84473 117.998 3.85363 117.998 5.78853V11.8747Z"
        fill="#003399"
      />
    </svg>
  )
}

export default HandelsbankenIcon
