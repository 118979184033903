export const definedLevel = (count: number): any => {
  let levelNumber = ''
  let levelText = ''
  let levelColor = ''
  let nextLevel = ''
  let levelMaxNumber = 0
  let neededVisits = 0

  if (count <= 1) {
    levelNumber = '6'
    levelText = 'brass'
    levelColor = '#DD6F57'
    levelMaxNumber = 1
    nextLevel = 'bronze'
    neededVisits = levelMaxNumber - count + 1
  } else if (count <= 5) {
    levelNumber = '5'
    levelText = 'bronze'
    levelColor = '#DD9F57'
    levelMaxNumber = 5
    nextLevel = 'silver'
    neededVisits = levelMaxNumber - count + 1
  } else if (count <= 9) {
    levelNumber = '4'
    levelText = 'silver'
    levelColor = '#D6D6D6'
    levelMaxNumber = 9
    nextLevel = 'gold'
    neededVisits = levelMaxNumber - count + 1
  } else if (count <= 14) {
    levelNumber = '3'
    levelText = 'gold'
    levelColor = '#F1DD2A'
    levelMaxNumber = 14
    nextLevel = 'platinum'
    neededVisits = levelMaxNumber - count + 1
  } else if (count <= 20) {
    levelNumber = '2'
    levelText = 'platinum'
    levelColor = '#C069FB'
    levelMaxNumber = 20
    nextLevel = 'diamond'
    neededVisits = levelMaxNumber - count + 1
  } else {
    levelNumber = '1'
    levelText = 'diamond'
    levelColor = '#57DD9D'
    levelMaxNumber = 21
  }
  return { levelNumber, levelText, levelColor, levelMaxNumber, neededVisits, nextLevel }
}

export const calculateNextLevelThreshold = (count: number): any => {
  let nextLevelThreshold = 0
  if (count < 2) {
    nextLevelThreshold = 2 - count
  } else if (count < 6) {
    nextLevelThreshold = 6 - count
  } else if (count < 10) {
    nextLevelThreshold = 10 - count
  } else if (count < 15) {
    nextLevelThreshold = 15 - count
  } else if (count < 21) {
    nextLevelThreshold = 21 - count
  }
  return nextLevelThreshold
}
