import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../index'
import {
  getPaymentForms,
  museumCodeMerge,
  webPaymentTransaction,
  webPaymentSuccess,
  webPaymentCard,
  getPaymentCards,
} from './asyncActions'
import { Status } from 'models'
import { paymentFormsResponse } from './paymentFormModel'

export interface PaymentFormState {
  data: paymentFormsResponse | null
  status: string
  error: string | any
  groups: object
  successMessage: string | any
  cards: Array<any>[]
}

const initialState: PaymentFormState = {
  data: null,
  groups: {},
  status: Status.IDLE,
  error: null,
  successMessage: null,
  cards: [],
}

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetAll: state => {
      state.data = null
      state.groups = {}
      state.status = Status.IDLE
      state.error = null
      state.successMessage = null
    },
    resetMessages: state => {
      state.error = null
      state.successMessage = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getPaymentForms.pending, state => {
        state.error = null
        state.status = Status.PENDING
      })
      .addCase(getPaymentForms.fulfilled, (state, action) => {
        let formsByKey: { [k: string]: any } = {}
        localStorage.setItem('payment_reference', action.payload.payment_reference)
        localStorage.setItem('price', action.payload.price)
        state.data = action.payload
        action.payload.payment_forms?.forEach((form: any) => {
          Object.keys(form).forEach(key => {
            formsByKey[key] = form[key]
          })
        })
        state.groups = formsByKey
        state.status = Status.SUCCEEDED
        state.error = null
      })
      .addCase(webPaymentTransaction.fulfilled, (state, action) => {
        state.status = Status.FAILED
        state.error = 'paymentFailed'
      })
      .addCase(getPaymentCards.fulfilled, (state, action) => {
        state.cards = action.payload.data
      })
      .addCase(museumCodeMerge.pending, state => {
        state.error = null
        state.status = Status.PENDING
      })
      .addMatcher(
        isAnyOf(webPaymentCard.fulfilled, webPaymentSuccess.fulfilled, museumCodeMerge.fulfilled),
        (state, action) => {
          state.status = Status.SUCCEEDED
          state.error = null
          state.successMessage = action.payload.message || 'success'
        }
      )
      .addMatcher(
        isAnyOf(
          getPaymentForms.rejected,
          webPaymentTransaction.rejected,
          webPaymentCard.rejected,
          webPaymentSuccess.rejected,
          museumCodeMerge.rejected
        ),
        (state, action) => {
          state.status = Status.FAILED
          state.error = action.payload ?? 'Unknown Error'
        }
      )
  },
})

export const { resetAll, resetMessages } = paymentSlice.actions

export const selectPaymentFormsData = (state: RootState): PaymentFormState['data'] => state.payment.data
export const selectPaymentFormsGroups = (state: RootState): PaymentFormState['groups'] => state.payment.groups
export const selectPaymentFormsError = (state: RootState): PaymentFormState['error'] => state.payment.error
export const selectPaymentFormsSuccess = (state: RootState): PaymentFormState['successMessage'] =>
  state.payment.successMessage
export const selectPaymentFormsStatus = (state: RootState): PaymentFormState['status'] => state.payment.status
export const selectPaymentCards = (state: RootState): PaymentFormState['cards'] => state.payment.cards

export default paymentSlice.reducer
