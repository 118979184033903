import { FunctionComponent } from 'react'
import { definedLevel } from '../../../../../../utils/definedLevel'
import { levels } from './Levels'

interface Props {
  count: number
}

const LevelCircle: FunctionComponent<Props> = ({ count }) => {
  const { levelNumber } = definedLevel(count)
  const Icon = levels[levelNumber as keyof typeof levels].icon
  return (
    <div className={'fit-parent'}>
      <Icon className={'responsive-image'} />
    </div>
  )
}

export default LevelCircle
