import { VoidFunctionComponent } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import Home from 'Components/Home'
import { Profile } from '../../Components/Profile'
import Buy from 'Components/Buy'
import Visited from '../../Components/Visited'
import CardRenewal from 'Components/CardRenewal'
import Messages from 'Components/Messages'
import ReceiptForm from 'Components/Profile/dependencies/Tabs/Receipts/ReceiptForm'
import { useTranslation } from 'react-i18next'
import { renderRoute } from 'utils/functionComponents'
import { renderLang } from 'utils'

const mainRoutes: RouteProps[] = [
  {
    path: '/home',
    children: Home,
  },
  {
    path: '/profile',
    children: Profile,
  },
  {
    path: '/buy',
    children: Buy,
  },
  {
    path: '/visits',
    children: Visited,
  },
  {
    path: '/card-renewal',
    children: CardRenewal,
  },
  {
    path: '/messages',
    children: Messages,
  },
  {
    path: '/payments/return',
    render: props => (
      <Redirect
        to={{
          pathname: `/home/${renderLang()}`,
          search: props.location.search,
        }}
      />
    ),
  },
  { path: '/receipt-form', children: ReceiptForm },
]

export const paths = [mainRoutes.map(route => route.path)].flat().filter(path => path) as string[]

const MainRoutes: VoidFunctionComponent = () => {
  const [, i18n] = useTranslation()

  return (
    <Switch>
      {mainRoutes.map(({ path, children, render }, index) => (
        <Route
          key={index}
          exact
          path={[`${path}`, `${path}/:lang`]}
          render={(props: any) => (render ? render(props) : renderRoute(children, props, i18n))}
        />
      ))}
    </Switch>
  )
}

export default MainRoutes
