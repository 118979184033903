import Login from 'Components/Auth'
import { Route, RouteProps, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FunctionComponent } from 'react'
import ShareUrl from 'Components/Visited/ShareUrl'
import { renderRoute } from 'utils/functionComponents'

const authPageRoutes: RouteProps[] = [
  {
    exact: true,
    path: '/login',
    children: Login,
  },
  {
    path: '/share/:levelNumber/:levelText/:commentText3',
    children: ShareUrl,
  },
]

export const paths = [authPageRoutes.map(route => route.path)].flat().filter(path => path) as string[]

const AuthRoutes: FunctionComponent = () => {
  const [, i18n] = useTranslation()

  return (
    <Switch>
      {authPageRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            exact
            path={[`${route.path}`, `${route.path}/:lang`]}
            render={(props: any) => renderRoute(route.children, props, i18n)}
          />
        )
      })}
    </Switch>
  )
}
export default AuthRoutes
