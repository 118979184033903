import { useEffect, VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { FontSize, FontWeight, FontFamily, Colors, calculateDynamicFont } from 'constants/index'
import Button from 'Components/UiKit/Buttons/Button'
import { ElementTypes } from 'constants/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'
import { subscribeToNews } from 'store/news/asyncActions'
import { resetMessages, selectNewsStatus, selectSubscribeNewsMessage } from 'store/news'
import { Status } from 'models'
import { toast } from 'react-toastify'
import { returnErrorMessageContent } from 'utils/functions'

interface Props {
  isMobile?: boolean
}

const CalturalAdvantage: VoidFunctionComponent<Props> = ({ isMobile }) => {
  const { t } = useTranslation<string>()
  const userInfo = useAppSelector(selectData)
  const dispatch = useAppDispatch()
  const subscribeMessage = useAppSelector(selectSubscribeNewsMessage)
  const newsStatus = useAppSelector(selectNewsStatus)

  useEffect(() => {
    if (subscribeMessage) {
      if (newsStatus === Status.SUCCEEDED) toast.success(t('newsSubscribed'))
      else if (newsStatus === Status.FAILED) toast.error(returnErrorMessageContent(subscribeMessage)[0])
      dispatch(resetMessages())
    }
  }, [dispatch, newsStatus, subscribeMessage, t])

  const handleSubscribe = () => {
    dispatch(subscribeToNews())
  }

  return (
    <Contanier isMobile={isMobile}>
      <TitleStyled>
        <Text
          textAlign="left"
          fontSize={FontSize.TWENTY_FOUR}
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          color={Colors.TOLOPEA}
        >
          {t('subscribeMuseumCardNewsletter')}
        </Text>
      </TitleStyled>
      <DetailStyled>
        <Text
          textAlign="left"
          fontSize={calculateDynamicFont({
            max: FontSize.EIGHTEEN,
            min: FontSize.SIXTEEN,
          })}
        >
          {t('subscribeMuseumCardNewsletterAndReceiveInfo')}
        </Text>
        {!userInfo.subscribed && (
          <ButtonStyled
            fontSize={FontSize.THIRTEEN}
            fontWeight={FontWeight.EIGHT}
            text={t('subscribeNewsletter')}
            onClick={handleSubscribe}
            buttonType={ElementTypes.TERTIARY}
          />
        )}
      </DetailStyled>
    </Contanier>
  )
}

export default CalturalAdvantage

const Contanier = styled.div<Props>`
  display: flex;
  margin-bottom: 104px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  column-gap: 24px;
  row-gap: 12px;
  @media (max-width: 700px) {
    margin-bottom: 64px;
  }
`
const DetailStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const TitleStyled = styled.div`
  max-width: 276px;
  width: 100%;
`
const ButtonStyled = styled(Button)`
  width: 165px;
  border: 2px solid #ff598f;
  padding: 0px;
`
