// @flow
import * as React from 'react'
import { FunctionComponent } from 'react'
import Text from '../Text'
import { FontSize, FontWeight, FontFamily, calculateDynamicFont } from 'constants/index'

type Props = {
  title: string
  subTitle: string
  containerClassName?: string
}

export const Header: FunctionComponent<Props> = ({ title, subTitle }: Props) => {
  return (
    <div className={`vertical align-items-start header-height  header-wrapper`}>
      <div className={'item flex-none justify-content-start mb-2'}>
        <Text
          textAlign={'left'}
          width="90%"
          fontSize={calculateDynamicFont({
            max: FontSize.THIRTY_TWO,
            min: FontSize.EIGHTEEN,
          })}
          fontFamily={FontFamily.EXTRABOLD}
          fontWeight={FontWeight.EIGHT}
        >
          {title}
        </Text>
      </div>
      <div className={'item flex-none justify-content-start'}>
        <Text
          textAlign={'left'}
          width="90%"
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY,
            min: FontSize.SIXTEEN,
          })}
          fontWeight={FontWeight.NORMAL}
          fontFamily={FontFamily.NORMAL}
        >
          {subTitle}
        </Text>
      </div>
    </div>
  )
}
