import { VoidFunctionComponent } from 'react'
import 'react-multi-carousel/lib/styles.css'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { FontSize, FontWeight, FontFamily } from 'constants/index'
import locationIcon from 'assets/icons/location.svg'
import { Colors } from 'constants/colors'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { useAppSelector } from 'store/hooks'
import { selectExhibitionData } from 'store/exhibition'
import SlideShow from 'Components/UiKit/SlideShow'
import { ExhibitionResource } from 'store/exhibition/exhibitionModel'
import { Link } from 'react-router-dom'

interface SliderContainerProps {
  image?: string
  isMobile?: boolean
}

interface Props {
  isMobile?: boolean
}

export const ExhibitionSlider: VoidFunctionComponent<Props> = ({ isMobile }) => {
  const { t, i18n } = useTranslation<string>()
  const exhibitionData = useAppSelector(selectExhibitionData)

  const handleRedirectClick = (item: any) => {
    window.open(`https://museot.fi/exhibitions/?nayttely_id=${item.id.toString()}`)
  }
  return (
    <Contanier>
      <SlideShow title={t('exhibitionsForYou')} normalElements={3} isMobile={isMobile || !exhibitionData?.length}>
        {exhibitionData?.map((item: ExhibitionResource) => (
          <SliderContainer key={item.museum_id}>
            <BackgroundContainer
              image={item.image_url}
              key={item.museum_id}
              isMobile={isMobile}
              onClick={() => handleRedirectClick(item)}
            >
              <CaptionContainer>
                <Text
                  width="fit-content"
                  textAlign="left"
                  fontSize={FontSize.TWELVE}
                  color={Colors.WHITE}
                  fontFamily={FontFamily.BOLD}
                  fontWeight={FontWeight.BOLD}
                >
                  {t('exhibition')}
                </Text>
              </CaptionContainer>
            </BackgroundContainer>
            <TextStyled>
              <TitleWrapper
                textAlign="left"
                fontSize={FontSize.FOURTEEN}
                fontWeight={FontWeight.EIGHT}
                fontFamily={FontFamily.EXTRABOLD}
                isMobile={isMobile}
                onClick={() => handleRedirectClick(item)}
              >
                {item.name[i18n.language] || item.name.fi}
              </TitleWrapper>
              <DescriptionWrapper textAlign="left" fontSize={FontSize.FOURTEEN}>
                {item.image_caption[i18n.language] || item.name.fi}
              </DescriptionWrapper>
            </TextStyled>
            <LocationStyled>
              <LocationIconStyled>
                <img src={locationIcon} alt="location" />
              </LocationIconStyled>
              <Link
                to={{ pathname: `https://museot.fi/exhibitions/?nayttely_id=${item.id.toString()}` }}
                target="_blank"
              >
                <TextBotton
                  textAlign="left"
                  size={FontSize.FOURTEEN}
                  fontWeight={FontWeight.SEVEN}
                  fontFamily={FontFamily.BOLD}
                  onClick={() => {}}
                  text={item.museum_city[i18n.language] || item.name.fi}
                />
              </Link>
            </LocationStyled>
          </SliderContainer>
        ))}
      </SlideShow>
    </Contanier>
  )
}

export default ExhibitionSlider

const Contanier = styled.div`
  position: relative;
  .slick-slide {
    width: 255px !important;
  }
`
const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 312px;
  margin-bottom: 80px;
  cursor: pointer;
`
const CaptionContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 0;
  z-index: 99;
  height: 28px;
  width: 75px;
  background-color: ${Colors.HELIOTROPE};
  display: flex;
  align-items: center;
  justify-content: center;
`
const BackgroundContainer = styled.div<SliderContainerProps>`
  height: 162px;
  border-radius: 6px;
  position: relative;
  margin-top: ${({ isMobile }) => (isMobile ? '24px' : '35px')};
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
`
const TextStyled = styled.div`
  margin-left: 22px;
  padding-top: 16px;
  margin-right: 20px;
`

const LocationIconStyled = styled.div`
  margin-right: 12px;
  img {
    width: 14px;
    height: 18px;
    max-width: unset;
  }
`
const LocationStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 13px;
  margin-left: 20px;
`

const DescriptionWrapper = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
const TitleWrapper = styled(Text)<SliderContainerProps>`
  height: ${({ isMobile }) => (isMobile ? 'fit-content' : '40px')};
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
