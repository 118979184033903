import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { FontSize, FontWeight, FontFamily } from 'constants/index'
import { useTranslation } from 'react-i18next'
import Arrow from 'assets/icons/longArrow.svg'
import { useAppSelector } from 'store/hooks'
import { selectNewsData } from 'store/news'
import React from 'react'
import { htmlEntityDecode, generateFieldUuid } from 'utils/functions'

interface props {
  isMobile?: boolean
}
const NewsAndRelease = ({ isMobile }: props) => {
  const [t] = useTranslation()
  const news = useAppSelector(selectNewsData)

  const returnReleases = (el: any) => {
    return (
      <ArticleStyled
        id={generateFieldUuid('news', el.id?.toString())}
        onClick={() => window.open(`https://museot.fi/uutiset.php?aid=${el.id}`)}
      >
        <InfoText isMobile={isMobile}>{htmlEntityDecode(t(el.title))}</InfoText>
        <IconStyled src={Arrow} className="pointer" />
      </ArticleStyled>
    )
  }
  return (
    <ContentWrapper>
      <Text
        textAlign="left"
        fontSize={FontSize.EIGHTEEN}
        fontWeight={FontWeight.EIGHT}
        fontFamily={FontFamily.EXTRABOLD}
      >
        {t('news')}
      </Text>
      {news.map((el: any, index: number) => (
        <React.Fragment key={index}>{el.title && returnReleases(el)}</React.Fragment>
      ))}
    </ContentWrapper>
  )
}
export default NewsAndRelease
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 76px;
  margin-bottom: 65px;
`
const ArticleStyled = styled.div`
  margin-top: 20px;
  cursor: pointer;
`

const InfoText = styled.span<props>`
  color: ${props => props.color};
  font-size: ${({ isMobile }) => (isMobile ? FontSize.SIXTEEN : FontSize.FOURTEEN)};
  font-weight: ${FontWeight.NORMAL};
  font-family: ${FontFamily.NORMAL};
  text-align: center;
`
const IconStyled = styled.img`
  margin-left: 5px;
  margin-top: 6px;
`
