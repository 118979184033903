import { CalculateCellValue, getClass } from './ReceiptsItem'
import { FunctionComponent, useEffect, useState } from 'react'
import { columns } from './ReceitsConts'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectUserTransactionData } from 'store/userTransaction'
import { getUserTransactions } from 'store/userTransaction/asyncActions'
import { Pagination } from 'antd'
import MediaQuery from 'react-responsive'
import { ResponsiveConstant } from 'constants/responsive-constant'
import Text from 'Components/UiKit/Text'
import { calculateDynamicFont, FontFamily, FontSize, FontWeight } from 'constants/index'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { displayDate, formatPrice } from 'utils/functions'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import { useHistory } from 'react-router'

const ReceiptsList: FunctionComponent = () => {
  const [t] = useTranslation()
  const userTransactionData = useAppSelector(selectUserTransactionData)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(getUserTransactions(page))
  }, [dispatch, page])

  const onPageChange = (pageNum: number): void => {
    setPage(pageNum)
  }

  const returnColumn = (
    width: string,
    text: string,
    textAlign: 'left' | 'center' | 'right' = 'left',
    type: string = 'header'
  ) => {
    return (
      <Text
        width={width}
        textAlign={textAlign}
        fontSize={calculateDynamicFont({
          max: FontSize.TWENTY,
          min: FontSize.FOURTEEN,
        })}
        fontFamily={type === 'header' ? FontFamily.BOLD : FontFamily.NORMAL}
        fontWeight={type === 'header' ? FontWeight.SEVEN : FontWeight.NORMAL}
      >
        {text}
      </Text>
    )
  }

  const handleClick = (code: string) => {
    history.push('/receipt-form', { state: code })
  }
  return (
    <>
      <MediaQuery query={ResponsiveConstant.isDesktopOrLaptop}>
        <div className={'vertical gap-3'}>
          <div className={'item flex-none'}>
            <div className={'vertical'}>
              <RowWrapper>
                {returnColumn('20%', t('date'))}
                {returnColumn('40%', t('description'))}
                {returnColumn('10%', t('amount'), 'center')}
                {returnColumn('30%', t('paymentStatus'), 'right')}
              </RowWrapper>
              {userTransactionData?.data?.map((row: any, index: number) => (
                <RowWrapper className={getClass(index)} key={index}>
                  {returnColumn('20%', displayDate(row.created_at), 'left', 'cell')}
                  {returnColumn('40%', t(row.type), 'left', 'cell')}
                  {returnColumn('10%', formatPrice(row.amount), 'center', 'cell')}
                  <Text
                    width="30%"
                    textAlign={'right'}
                    fontSize={calculateDynamicFont({
                      max: FontSize.TWENTY,
                      min: FontSize.FOURTEEN,
                    })}
                  >
                    {t(row.status)} &nbsp;{' '}
                    <TextBotton
                      text={t('showReceipt')}
                      size={calculateDynamicFont({
                        max: FontSize.TWENTY,
                        min: FontSize.FOURTEEN,
                      })}
                      containerClassName="status-button"
                      onClick={() => handleClick(row.filing_code)}
                    />
                  </Text>
                </RowWrapper>
              ))}
            </div>
          </div>
          <div className={'item flex-none justify-content-start'}>
            <Pagination
              showLessItems
              defaultCurrent={1}
              total={userTransactionData?.meta?.total}
              pageSize={5}
              onChange={onPageChange}
            />
          </div>
        </div>
      </MediaQuery>
      <MediaQuery query={ResponsiveConstant.isTabletOrMobile}>
        <div className={'vertical gap-3 py-4'}>
          {userTransactionData?.data.map((row: any, index: number) => {
            return (
              <div className={'item vertical flex-1 p-4 ' + getClass(index)} key={index}>
                {columns.map((column, columIndex) => {
                  return (
                    <div className="item flex-1 py-2" key={columIndex}>
                      <div className={'horizontal'}>
                        <Text className="tablet-column-title" textAlign={'left'} fontWeight={FontWeight.NORMAL}>
                          {column.title}
                        </Text>
                        <CalculateCellValue
                          cellValue={row[column.key]}
                          columnKey={column.key}
                          isReponsive={true}
                          onClick={() => handleClick(row.filing_code)}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
          <div className={'item flex-none'}>
            <Pagination
              defaultCurrent={1}
              total={userTransactionData?.meta?.total}
              pageSize={5}
              onChange={onPageChange}
            />
          </div>
        </div>
      </MediaQuery>
    </>
  )
}

export default ReceiptsList

const RowWrapper = styled.div`
  padding: 0px 53px 0px 43px;
  display: flex;
  height: 108px;
  align-items: center;
  width: 100%;
  & > div {
    min-width: 120px;
  }
  @media (max-width: 900px) {
    padding: 0px 10px;
  }
`
