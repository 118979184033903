import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'

export type VisitsBodyModel = {
  orderBy?: string
  page?: number
}

export const getVisitsAsync: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  'web/user/visits',
  async ({ orderBy, page }: VisitsBodyModel, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.userVisits(orderBy, 6, page)
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
