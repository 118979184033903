import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import Heart from 'assets/iconComponents/Heart'
import { Colors } from 'constants/colors'
import { useDispatch } from 'react-redux'
import { addRatingAsync, updateRatingAsync } from 'store/visits/rating/asyncActions'
import { getVisitsAsync } from 'store/visits/visitsList/asyncActions'

interface Props {
  rate: number
  ratingId?: number
  orderBy?: 'asc' | 'desc'
  userId?: number
  visitId?: number
  museumId?: number
  onChange: (rate: number) => void
}

const RateComponent: VoidFunctionComponent<Props> = ({
  rate,
  onChange,
  ratingId,
  orderBy,
  userId,
  visitId,
  museumId,
}) => {
  const dispatch = useDispatch()
  const handleRating = async (rate: any): Promise<void> => {
    onChange(rate + 1)
    if (ratingId) {
      const updateRatingBody = {
        ratingId,
        requestBody: {
          rating: rate + 1,
        },
      }
      await dispatch(updateRatingAsync(updateRatingBody))
      dispatch(getVisitsAsync({ orderBy }))
    } else {
      const addRatingBody = {
        requestBody: {
          visit_id: visitId,
          museum_id: museumId,
          rating: rate + 1,
          user_id: userId,
        },
      }
      await dispatch(addRatingAsync(addRatingBody))
      dispatch(getVisitsAsync({ orderBy }))
    }
  }

  return (
    <Container>
      {[...Array(rate)].map((_, i) => (
        <Heart onClick={() => handleRating(i)} className="cursor-pointer" key={i} />
      ))}
      {[...Array(5 - rate)].map((_, i) => (
        <Heart onClick={() => handleRating(i + rate)} className="cursor-pointer" color={Colors.WHITE} key={i} />
      ))}
    </Container>
  )
}

export default RateComponent

const Container = styled.div`
  svg {
    margin-right: 12px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
`
