import { useState, useEffect, VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'Components/UiKit/Text'
import { calculateDynamicFont, FontFamily, FontSize, FontWeight, ModalContainer } from 'constants/index'
// import Button from 'Components/UiKit/Buttons/Button'
import TextBotton from 'Components/UiKit/Buttons/TextBotton'
import GuysIcon from 'assets/icons/girlCard.svg'
import { Colors } from 'constants/colors'
import { useHistory } from 'react-router'
import { handleCardRenewal, checkBtnAccess } from 'utils/functions'
import { selectData } from 'store/user'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import PaymentMethodModal from 'Components/Modals/PaymentMethodModal'
import CombineCardsModal from 'Components/Modals/CombineCardsModal'
import StandingOrderModal from 'Components/Modals/StandingOrderModal'
import CouponModal from 'Components/Modals/CouponModal'
import {
  resetMessages,
  selectPaymentFormsError,
  selectPaymentFormsStatus,
  selectPaymentFormsSuccess,
} from 'store/payment'
import { toast } from 'react-toastify'
import { returnErrorMessageContent, generateFieldUuid } from 'utils/functions'
import { Status } from 'models'

const Order: VoidFunctionComponent = () => {
  const [t, { language }] = useTranslation()
  const [showPaymentModal, setPaymentModal] = useState(false)
  const [showCombineCardsModal, setShowCombineCardsModal] = useState(false)
  const [showStandingOrderModal, setShowStandingOrderModal] = useState(false)
  const [showCouponModal, setShowCouponModal] = useState(false)
  const mergeError = useAppSelector(selectPaymentFormsError)
  const successMessage = useAppSelector(selectPaymentFormsSuccess)
  const status = useAppSelector(selectPaymentFormsStatus)
  const userInfo = useAppSelector(selectData)
  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (status === Status.FAILED) {
      if (mergeError === 'paymentFailed') {
        toast.error(t(mergeError))
      } else toast.error(returnErrorMessageContent(mergeError)[0])
      if (localStorage.getItem('subscribe')) localStorage.removeItem('subscribe')
    }
    if (status === Status.SUCCEEDED && successMessage) {
      if (localStorage.getItem('subscribe')) {
        toast.success(t('standingOrderSuccessMessage'))
        localStorage.removeItem('subscribe')
      } else toast.success(t(successMessage))
    }
    return () => {
      dispatch(resetMessages())
    }
  }, [mergeError, successMessage, dispatch, status, t])

  const handleClick = (coupon?: string) => {
    handleCardRenewal('renew', userInfo.code, language, dispatch, coupon)
    history.push('/card-renewal')
  }

  const closeModals = () => {
    localStorage.removeItem('subscribe')
    setPaymentModal(false)
    setShowCombineCardsModal(false)
    setShowStandingOrderModal(false)
    setShowCouponModal(false)
  }

  const handleOpenPaymentMethodModel = () => {
    handleCardRenewal('add_card', userInfo.code, language, dispatch)
    setPaymentModal(true)
  }

  const switchModals = () => {
    localStorage.setItem('subscribe', 'true')
    setShowStandingOrderModal(false)
    setPaymentModal(true)
  }

  const returnTextButtons = (text: string, action: () => void, show: boolean = true) => {
    return show ? (
      <TextBtnContainer
        id={generateFieldUuid(text, 'link')}
        longArrow
        onClick={action}
        size={calculateDynamicFont({
          max: FontSize.SIXTEEN,
          min: FontSize.FOURTEEN,
        })}
        fontFamily={FontFamily.BOLD}
        text={t(text)}
      />
    ) : null
  }
  return (
    <Contanier>
      <TextStyled>
        <Text
          fontSize={FontSize.TWENTY_FOUR}
          textAlign="left"
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
        >
          {t('quickLinks')}
        </Text>
      </TextStyled>
      {/* <Text
        textAlign="left"
        fontSize={calculateDynamicFont({
          max: FontSize.TWENTY,
          min: FontSize.SIXTEEN,
        })}
      >
        {t('switchOnStandingOrder')}
      </Text>
      <BtnContainer
        text={t('placeStandingOrder')}
        fontSize={FontSize.THIRTEEN}
        onClick={() => setShowStandingOrderModal(true)}
      /> */}
      {returnTextButtons(!userInfo.has_standing_order ? 'placeStandingOrder' : 'deactivateStandingOrderBTn', () =>
        setShowStandingOrderModal(true)
      )}
      {returnTextButtons('combineTwoCards', () => setShowCombineCardsModal(true))}
      {returnTextButtons('renewCard', () => setShowCouponModal(true), checkBtnAccess(userInfo))}
      {returnTextButtons('buyAsAGift', () => window.open(`https://osta.museokortti.fi/gift/${language}`))}
      {language === 'fi' && returnTextButtons('museumCardBenefits', () => window.open(`https://museot.fi/edut/`))}
      {returnTextButtons('paymentInformation', handleOpenPaymentMethodModel, userInfo.has_standing_order)}
      {/* {returnTextButtons('removeCardInformation', checkBtnAccess(userInfo), () => {})} */}
      <GuysImg src={GuysIcon} />
      <ModalContainer
        isVisible={showPaymentModal || showCombineCardsModal || showStandingOrderModal || showCouponModal}
        type={showCombineCardsModal || showCouponModal ? 'combine' : ''}
        setVisible={closeModals}
      >
        {showCouponModal ? (
          <CouponModal handleClick={handleClick} />
        ) : showStandingOrderModal ? (
          <StandingOrderModal action={switchModals} />
        ) : showPaymentModal ? (
          <PaymentMethodModal />
        ) : (
          <CombineCardsModal />
        )}
      </ModalContainer>
    </Contanier>
  )
}

export default Order

const Contanier = styled.div`
  flex: 1 1 400px;
  height: 503px;
  background: ${Colors.WHITE};
  box-shadow: 0px 10px 22px rgba(7, 27, 133, 0.05);
  border-radius: 16px;
  padding: 40px 28px 0px 34px;
  position: relative;
  @media (max-width: 700px) {
    height: 432px;
  }
`
const GuysImg = styled.img`
  position: absolute;
  right: 15px;
  bottom: 0;
  width: 300 @media (max-width: 800px) {
    width: 210px;
  }
`
const TextStyled = styled.div`
  margin-bottom: 12px;
`

const TextBtnContainer = styled(TextBotton)`
  margin-bottom: 12px;
  @media (max-width: 350px) {
    margin-bottom: 8px;
  }
  img {
    margin-top: 5px !important;
  }
`

// const BtnContainer = styled(Button)`
//   width: 170px;
//   border: none;
//   margin: 24px 0px 42px;
//   @media (max-width: 700px) {
//     margin: 24px 0px 32px;
//   }

//   @media (max-width: 350px) {
//     margin: 24px 0px 20px;
//   }
// `
