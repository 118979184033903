import { ReactElement } from 'react'

function AppStore(props: any): ReactElement {
  return (
    <svg width="144" height="40" viewBox="0 0 144 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M133.896 0.00013H10.1089C9.7422 0.00013 9.3799 0.00013 9.01417 0.00213C8.70802 0.00413 8.40431 0.00994 8.09522 0.01483C7.42373 0.0227318 6.75383 0.0818063 6.09132 0.19154C5.42974 0.303663 4.78889 0.515046 4.19044 0.81854C3.59272 1.1246 3.04656 1.5223 2.57179 1.9972C2.09452 2.47077 1.69668 3.01815 1.39357 3.61829C1.08962 4.21724 0.878859 4.85907 0.768569 5.52161C0.65723 6.18332 0.597317 6.85265 0.589369 7.52361C0.580089 7.83021 0.579109 8.13783 0.574219 8.44447V31.5587C0.579109 31.8692 0.580089 32.17 0.589369 32.4806C0.59732 33.1516 0.657232 33.8209 0.768569 34.4825C0.878555 35.1455 1.08933 35.7877 1.39357 36.3868C1.69654 36.985 2.09444 37.5302 2.57179 38.0011C3.04476 38.4781 3.59127 38.8761 4.19044 39.1798C4.78889 39.4841 5.42967 39.6968 6.09132 39.8106C6.75394 39.9195 7.42378 39.9786 8.09522 39.9874C8.40431 39.9942 8.70802 39.9981 9.01417 39.9981C9.37989 40.0001 9.74222 40.0001 10.1089 40.0001H133.896C134.256 40.0001 134.621 40.0001 134.98 39.9981C135.285 39.9981 135.598 39.9942 135.902 39.9874C136.573 39.9791 137.241 39.92 137.902 39.8106C138.566 39.696 139.209 39.4834 139.811 39.1798C140.409 38.8759 140.955 38.478 141.428 38.0011C141.904 37.5284 142.303 36.9836 142.609 36.3868C142.911 35.7872 143.12 35.1451 143.228 34.4825C143.34 33.8208 143.402 33.1516 143.414 32.4806C143.418 32.17 143.418 31.8692 143.418 31.5587C143.426 31.1954 143.426 30.8341 143.426 30.4649V9.53626C143.426 9.17005 143.426 8.80677 143.418 8.44447C143.418 8.13783 143.418 7.83021 143.414 7.52357C143.402 6.85255 143.34 6.18337 143.228 5.52157C143.12 4.85941 142.911 4.21763 142.609 3.61825C141.992 2.41533 141.013 1.43616 139.811 0.81845C139.209 0.515697 138.566 0.30437 137.902 0.19145C137.241 0.0812328 136.573 0.0221378 135.902 0.01469C135.598 0.00981 135.285 0.00395 134.98 0.002C134.621 0 134.256 0 133.896 0V0.00013Z"
        fill="#2C253D"
      />
      <path
        d="M9.01908 39.1251C8.71441 39.1251 8.41708 39.1212 8.1148 39.1144C7.48858 39.1063 6.86382 39.0517 6.24566 38.9513C5.66925 38.852 5.11086 38.6674 4.58892 38.4034C4.07176 38.1416 3.60007 37.7984 3.19192 37.3868C2.77786 36.9801 2.43313 36.5084 2.17141 35.9903C1.90679 35.4689 1.72367 34.91 1.62841 34.3331C1.52553 33.7132 1.46987 33.0864 1.46191 32.4581C1.45557 32.2472 1.44727 31.545 1.44727 31.545V8.44446C1.44727 8.44446 1.45611 7.75305 1.46196 7.54992C1.46958 6.9226 1.52491 6.29678 1.62748 5.67785C1.72292 5.09937 1.90619 4.53887 2.17095 4.01575C2.43171 3.49807 2.77452 3.02599 3.18608 2.6178C3.59718 2.20575 4.07039 1.86073 4.58843 1.59533C5.10917 1.33222 5.6665 1.14885 6.24175 1.05139C6.86194 0.949958 7.48887 0.895118 8.11726 0.887332L9.01958 0.875122H134.976L135.889 0.887822C136.511 0.895221 137.133 0.949572 137.747 1.05041C138.328 1.1491 138.891 1.33374 139.418 1.59826C140.456 2.13311 141.3 2.97929 141.833 4.01819C142.094 4.5377 142.274 5.09363 142.368 5.66712C142.472 6.29112 142.53 6.92187 142.542 7.55433C142.545 7.83753 142.545 8.14173 142.545 8.44447C142.553 8.81947 142.553 9.1764 142.553 9.53626V30.4649C142.553 30.8282 142.553 31.1827 142.545 31.5401C142.545 31.8653 142.545 32.1632 142.541 32.4698C142.529 33.091 142.472 33.7105 142.37 34.3233C142.277 34.9044 142.095 35.4676 141.83 35.9933C141.567 36.5057 141.224 36.9734 140.815 37.379C140.406 37.7928 139.934 38.1381 139.415 38.4015C138.89 38.6675 138.328 38.8529 137.747 38.9513C137.129 39.0523 136.504 39.1068 135.878 39.1144C135.585 39.1212 135.278 39.1251 134.981 39.1251L133.897 39.1271L9.01908 39.1251Z"
        fill="black"
      />
      <path
        d="M25.3461 20.3008C25.3569 19.4662 25.5786 18.6478 25.9906 17.9218C26.4026 17.1959 26.9916 16.5859 27.7027 16.1488C27.251 15.5036 26.655 14.9727 25.9622 14.5981C25.2694 14.2236 24.4987 14.0157 23.7115 13.9911C22.0323 13.8148 20.4044 14.9959 19.5486 14.9959C18.6764 14.9959 17.3589 14.0086 15.9401 14.0378C15.0225 14.0674 14.1281 14.3343 13.3442 14.8123C12.5604 15.2904 11.9137 15.9633 11.4672 16.7656C9.53322 20.1141 10.9758 25.0351 12.8284 27.7417C13.7553 29.0671 14.8386 30.5475 16.2561 30.495C17.6431 30.4375 18.1612 29.6105 19.8355 29.6105C21.4942 29.6105 21.9802 30.495 23.4265 30.4616C24.9148 30.4375 25.8526 29.1304 26.747 27.7925C27.4129 26.8481 27.9254 25.8044 28.2653 24.7C27.4006 24.3343 26.6627 23.7221 26.1436 22.9398C25.6245 22.1575 25.3471 21.2397 25.3461 20.3008Z"
        fill="white"
      />
      <path
        d="M22.6137 12.2111C23.4253 11.2369 23.8251 9.98469 23.7283 8.72046C22.4884 8.85068 21.3432 9.44324 20.5207 10.3801C20.1185 10.8377 19.8105 11.3702 19.6143 11.9469C19.4181 12.5237 19.3374 13.1335 19.377 13.7415C19.9971 13.7478 20.6106 13.6134 21.1713 13.3483C21.7319 13.0833 22.2251 12.6944 22.6137 12.2111Z"
        fill="white"
      />
      <path d="M39.9697 14.7019H36.248V8.73511H37.1748V13.8499H39.9697V14.7019Z" fill="white" />
      <path
        d="M40.9062 13.4285C40.9062 12.6179 41.5098 12.1506 42.5811 12.0842L43.8008 12.0139V11.6252C43.8008 11.1497 43.4863 10.8811 42.8789 10.8811C42.3828 10.8811 42.0391 11.0632 41.9404 11.3816H41.0801C41.1709 10.6081 41.8984 10.1121 42.9199 10.1121C44.0488 10.1121 44.6856 10.6741 44.6856 11.6252V14.7019H43.8301V14.0691H43.7598C43.6171 14.2961 43.4167 14.4811 43.179 14.6053C42.9414 14.7296 42.6751 14.7885 42.4072 14.7761C42.2182 14.7958 42.0271 14.7756 41.8464 14.7169C41.6656 14.6582 41.4992 14.5623 41.3577 14.4353C41.2163 14.3083 41.1031 14.1531 41.0253 13.9797C40.9476 13.8063 40.907 13.6185 40.9062 13.4285ZM43.8008 13.0437V12.6672L42.7012 12.7375C42.0811 12.779 41.7999 12.99 41.7999 13.387C41.7999 13.7922 42.1514 14.0281 42.6349 14.0281C42.7765 14.0424 42.9196 14.0281 43.0556 13.986C43.1916 13.9439 43.3177 13.8749 43.4265 13.783C43.5353 13.6912 43.6245 13.5784 43.6888 13.4514C43.7531 13.3244 43.7912 13.1857 43.8008 13.0437L43.8008 13.0437Z"
        fill="white"
      />
      <path
        d="M47.2803 9.07788V10.2195H48.2559V10.968H47.2803V13.2834C47.2803 13.7551 47.4746 13.9617 47.917 13.9617C48.0302 13.9613 48.1434 13.9545 48.2559 13.9412V14.6814C48.0963 14.71 47.9346 14.7252 47.7725 14.7268C46.7842 14.7268 46.3906 14.3791 46.3906 13.511V10.968H45.6758V10.2194H46.3906V9.07788H47.2803Z"
        fill="white"
      />
      <path
        d="M49.1719 13.4285C49.1719 12.6179 49.7754 12.1506 50.8467 12.0842L52.0664 12.0139V11.6252C52.0664 11.1497 51.752 10.8811 51.1445 10.8811C50.6484 10.8811 50.3047 11.0632 50.2061 11.3816H49.3457C49.4365 10.6081 50.1641 10.1121 51.1855 10.1121C52.3145 10.1121 52.9512 10.6741 52.9512 11.6252V14.7019H52.0957V14.0691H52.0254C51.8827 14.2961 51.6823 14.4811 51.4447 14.6053C51.207 14.7296 50.9407 14.7885 50.6729 14.7761C50.4838 14.7958 50.2928 14.7756 50.112 14.7169C49.9312 14.6582 49.7648 14.5623 49.6234 14.4353C49.482 14.3083 49.3687 14.1531 49.2909 13.9797C49.2132 13.8063 49.1726 13.6185 49.1719 13.4285ZM52.0664 13.0437V12.6672L50.9668 12.7376C50.3467 12.7791 50.0654 12.99 50.0654 13.387C50.0654 13.7922 50.417 14.0281 50.9004 14.0281C51.0421 14.0424 51.1852 14.0281 51.3212 13.986C51.4572 13.9439 51.5833 13.8749 51.6921 13.7831C51.8009 13.6912 51.8901 13.5784 51.9544 13.4514C52.0187 13.3244 52.0568 13.1857 52.0664 13.0437V13.0437Z"
        fill="white"
      />
      <path
        d="M54.0859 13.4285C54.0859 12.6179 54.6895 12.1506 55.7607 12.0842L56.9805 12.0139V11.6252C56.9805 11.1497 56.666 10.8811 56.0586 10.8811C55.5625 10.8811 55.2188 11.0632 55.1201 11.3816H54.2598C54.3506 10.6081 55.0781 10.1121 56.0996 10.1121C57.2285 10.1121 57.8652 10.6741 57.8652 11.6252V14.7019H57.0098V14.0691H56.9395C56.7967 14.2961 56.5963 14.4811 56.3587 14.6053C56.1211 14.7296 55.8548 14.7885 55.5869 14.7761C55.3979 14.7958 55.2068 14.7756 55.0261 14.7169C54.8453 14.6582 54.6789 14.5623 54.5374 14.4353C54.396 14.3083 54.2828 14.1531 54.205 13.9797C54.1272 13.8063 54.0867 13.6185 54.0859 13.4285ZM56.9805 13.0437V12.6672L55.8809 12.7376C55.2607 12.7791 54.9795 12.99 54.9795 13.387C54.9795 13.7922 55.331 14.0281 55.8145 14.0281C55.9561 14.0424 56.0992 14.0281 56.2352 13.986C56.3712 13.9439 56.4974 13.8749 56.6062 13.7831C56.7149 13.6912 56.8041 13.5784 56.8684 13.4514C56.9328 13.3244 56.9708 13.1857 56.9805 13.0437V13.0437Z"
        fill="white"
      />
      <path
        d="M42.8828 27.129H38.1494L37.0127 30.4855H35.0078L39.4912 18.067H41.5742L46.0576 30.4855H44.0186L42.8828 27.129ZM38.6397 25.5802H42.3917L40.542 20.1325H40.4902L38.6397 25.5802Z"
        fill="white"
      />
      <path
        d="M55.7393 25.9591C55.7393 28.7726 54.2334 30.5802 51.961 30.5802C51.3853 30.6103 50.8128 30.4777 50.309 30.1976C49.8052 29.9174 49.3905 29.5011 49.1123 28.9962H49.0693V33.4806H47.2109V21.4312H49.0098V22.9371H49.0439C49.3349 22.4346 49.7566 22.0204 50.2643 21.7386C50.7719 21.4568 51.3464 21.3178 51.9268 21.3365C54.2247 21.3365 55.7393 23.1529 55.7393 25.9591ZM53.8291 25.9591C53.8291 24.1256 52.8819 22.9205 51.4365 22.9205C50.0166 22.9205 49.0615 24.151 49.0615 25.9591C49.0615 27.7833 50.0166 29.005 51.4365 29.005C52.8819 29.005 53.8292 27.8087 53.8292 25.9591H53.8291Z"
        fill="white"
      />
      <path
        d="M65.7055 25.9591C65.7055 28.7726 64.2002 30.5802 61.9277 30.5802C61.352 30.6103 60.7796 30.4777 60.2758 30.1976C59.772 29.9174 59.3572 29.5011 59.0791 28.9962H59.0361V33.4806H57.1777V21.4312H58.9765V22.9371H59.0107C59.3017 22.4346 59.7234 22.0204 60.231 21.7386C60.7387 21.4568 61.3132 21.3178 61.8935 21.3365C64.1914 21.3365 65.7055 23.1529 65.7055 25.9591ZM63.7959 25.9591C63.7959 24.1256 62.8486 22.9205 61.4033 22.9205C59.9834 22.9205 59.0283 24.151 59.0283 25.9591C59.0283 27.7833 59.9834 29.005 61.4033 29.005C62.8486 29.005 63.7959 27.8087 63.7959 25.9591H63.7959Z"
        fill="white"
      />
      <path
        d="M72.2892 27.0255C72.4269 28.257 73.6232 29.0655 75.2579 29.0655C76.8243 29.0655 77.9513 28.2569 77.9513 27.1466C77.9513 26.1827 77.2716 25.6056 75.6622 25.21L74.0528 24.8219C71.7725 24.2711 70.7139 23.2047 70.7139 21.4742C70.7139 19.3317 72.5811 17.86 75.2315 17.86C77.8565 17.86 79.6553 19.3317 79.7159 21.4742H77.8399C77.7276 20.235 76.7032 19.4869 75.2061 19.4869C73.709 19.4869 72.6846 20.2438 72.6846 21.3453C72.6846 22.2233 73.3389 22.7399 74.9395 23.1353L76.3076 23.4713C78.8555 24.0738 79.9131 25.0977 79.9131 26.9142C79.9131 29.2374 78.0635 30.6925 75.1201 30.6925C72.3662 30.6925 70.5068 29.2716 70.3867 27.0255L72.2892 27.0255Z"
        fill="white"
      />
      <path
        d="M83.9258 19.2887V21.4313H85.6475V22.903H83.9258V27.8947C83.9258 28.67 84.2705 29.0314 85.0273 29.0314C85.2317 29.0278 85.4358 29.0135 85.6387 28.9884V30.4513C85.2984 30.5148 84.9525 30.5436 84.6064 30.5372C82.7734 30.5372 82.0586 29.8487 82.0586 28.0929V22.903H80.7422V21.4313H82.0586V19.2887H83.9258Z"
        fill="white"
      />
      <path
        d="M86.6445 25.9591C86.6445 23.11 88.3223 21.3199 90.9385 21.3199C93.5635 21.3199 95.2334 23.1099 95.2334 25.9591C95.2334 28.8156 93.5723 30.5978 90.9385 30.5978C88.3057 30.5978 86.6445 28.8155 86.6445 25.9591ZM93.3398 25.9591C93.3398 24.0045 92.4443 22.8512 90.9385 22.8512C89.4326 22.8512 88.5371 24.0133 88.5371 25.9591C88.5371 27.921 89.4326 29.0656 90.9385 29.0656C92.4443 29.0656 93.3398 27.921 93.3398 25.9591Z"
        fill="white"
      />
      <path
        d="M96.7656 21.4313H98.5381V22.9723H98.5811C98.701 22.491 98.983 22.0657 99.3795 21.7678C99.7761 21.4699 100.263 21.3176 100.759 21.3365C100.973 21.3358 101.187 21.359 101.396 21.4059V23.1441C101.125 23.0615 100.843 23.0236 100.561 23.0318C100.291 23.0209 100.021 23.0685 99.7715 23.1714C99.5216 23.2744 99.297 23.4301 99.113 23.6281C98.929 23.826 98.79 24.0614 98.7056 24.3182C98.6212 24.5749 98.5934 24.8468 98.624 25.1153V30.4854H96.7656V21.4313Z"
        fill="white"
      />
      <path
        d="M109.964 27.8263C109.714 29.4698 108.113 30.5978 106.065 30.5978C103.432 30.5978 101.797 28.8331 101.797 26.0021C101.797 23.1617 103.44 21.3199 105.987 21.3199C108.492 21.3199 110.067 23.0406 110.067 25.7863V26.423H103.673V26.5353C103.643 26.8685 103.685 27.2041 103.795 27.52C103.905 27.8358 104.082 28.1246 104.312 28.3672C104.542 28.6097 104.822 28.8005 105.131 28.9268C105.441 29.0531 105.774 29.112 106.108 29.0997C106.548 29.1409 106.988 29.0392 107.365 28.8097C107.742 28.5803 108.034 28.2354 108.199 27.8263L109.964 27.8263ZM103.682 25.1241H108.208C108.225 24.8245 108.179 24.5246 108.075 24.2434C107.97 23.9621 107.808 23.7055 107.6 23.4897C107.391 23.2739 107.14 23.1035 106.863 22.9893C106.585 22.875 106.287 22.8194 105.987 22.8258C105.685 22.824 105.385 22.8822 105.105 22.997C104.825 23.1117 104.57 23.2808 104.356 23.4945C104.141 23.7082 103.971 23.9622 103.856 24.2419C103.74 24.5216 103.681 24.8215 103.682 25.1241Z"
        fill="white"
      />
      <path
        d="M114.98 21.3287C116.986 21.3287 118.423 22.4381 118.466 24.0387H116.719C116.642 23.2389 115.962 22.7487 114.929 22.7487C113.922 22.7487 113.251 23.2126 113.251 23.9187C113.251 24.4607 113.698 24.822 114.637 25.0549L116.16 25.4084C117.984 25.8469 118.673 26.5178 118.673 27.844C118.673 29.4787 117.124 30.5979 114.912 30.5979C112.777 30.5979 111.341 29.5041 111.203 27.8518H113.044C113.174 28.7209 113.87 29.1858 114.998 29.1858C116.108 29.1858 116.806 28.7297 116.806 28.0071C116.806 27.4475 116.461 27.1467 115.515 26.9055L113.896 26.51C112.261 26.1135 111.435 25.2785 111.435 24.0222C111.435 22.4215 112.872 21.3287 114.98 21.3287Z"
        fill="white"
      />
      <path
        d="M122.623 19.2887V21.4313H124.345V22.903H122.623V27.8947C122.623 28.67 122.968 29.0314 123.725 29.0314C123.929 29.0278 124.133 29.0135 124.336 28.9884V30.4513C123.996 30.5148 123.65 30.5436 123.304 30.5372C121.471 30.5372 120.756 29.8487 120.756 28.0929V22.903H119.439V21.4313H120.756V19.2887H122.623Z"
        fill="white"
      />
      <path
        d="M125.529 27.9122C125.529 26.3292 126.742 25.3732 128.894 25.2442L131.372 25.1065V24.4175C131.372 23.4107 130.71 22.8423 129.583 22.8423C129.165 22.7866 128.741 22.8854 128.391 23.12C128.041 23.3547 127.788 23.7091 127.681 24.1168H125.942C125.994 22.4811 127.517 21.3199 129.634 21.3199C131.794 21.3199 133.223 22.4987 133.223 24.2799V30.4855H131.441V28.9962H131.398C131.113 29.5012 130.697 29.9202 130.195 30.2095C129.692 30.4988 129.121 30.6478 128.541 30.6407C128.16 30.678 127.776 30.6352 127.412 30.5151C127.049 30.395 126.715 30.2002 126.431 29.9432C126.148 29.6863 125.921 29.3729 125.765 29.0231C125.61 28.6733 125.53 28.2949 125.529 27.9122ZM131.372 27.0948V26.3976L129.144 26.5353C128.033 26.6046 127.405 27.0861 127.405 27.8605C127.405 28.6525 128.06 29.1691 129.058 29.1691C129.347 29.1887 129.637 29.1502 129.91 29.0559C130.184 28.9616 130.437 28.8135 130.652 28.6201C130.868 28.4268 131.043 28.1923 131.166 27.9303C131.29 27.6683 131.36 27.3843 131.372 27.0948Z"
        fill="white"
      />
    </svg>
  )
}

export default AppStore
