import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont, Colors } from 'constants/index'
import { useTranslation } from 'react-i18next'

const ContactService = () => {
  const [t] = useTranslation()

  return (
    <Container>
      <Wrapper>
        <Text
          fontWeight={FontWeight.EIGHT}
          fontFamily={FontFamily.EXTRABOLD}
          fontSize={calculateDynamicFont({
            max: FontSize.TWENTY_FOUR,
            min: FontSize.TWENTY_FOUR,
          })}
        >
          {t('customerService')}
        </Text>
        <Text
          fontSize={calculateDynamicFont({
            max: FontSize.SIXTEEN,
            min: FontSize.SIXTEEN,
          })}
        >
          {t('youCanAlsoContact')}
        </Text>
        <div className="d-flex mt-3">
          <Text
            fontSize={calculateDynamicFont({
              max: FontSize.SIXTEEN,
              min: FontSize.SIXTEEN,
            })}
          >
            {'044 784 5745 |'}
          </Text>
          &nbsp;
          <Text
            fontSize={calculateDynamicFont({
              max: FontSize.SIXTEEN,
              min: FontSize.SIXTEEN,
            })}
            className="pointer"
            color={Colors.FRENCH_ROSE}
          >
            {' museokortti@museot.fi'}
          </Text>
        </div>
      </Wrapper>
    </Container>
  )
}
export default ContactService

const Container = styled.div`
  margin: 84px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 700px) {
    margin: 48px 0px;
  }
`
const Wrapper = styled.div`
  max-width: 445px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`
