import styled from 'styled-components'
import LastVisited from './LastVisited'
import UserLevel from './UserLevel'

const LevelAndVisited = () => {
  return (
    <Contanier>
      <UserLevel />
      <LastVisited />
    </Contanier>
  )
}

export default LevelAndVisited

const Contanier = styled.div`
  flex: 1 1 350px;
  max-height: 503px;
  display: flex;
  flex-direction: column;
  margin-left: 44px;
  @media (max-width: 700px) {
    margin-left: 0px;
    margin-top: 64px;
    max-height: unset;
  }
`
