import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'
import { announcementModel } from './announcementModel'

export const getAnnouncementAsync: AsyncThunk<announcementModel, void, { rejectValue: string }> = createAsyncThunk(
  'web/announcement/offers',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await WebService.webAnnouncementOffers()
    } catch (error: any) {
      return handleApiCallError(error, rejectWithValue, dispatch)
    }
  }
)
