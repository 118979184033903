import { PrivateRoutePresentation, PrivateRoutePresentationProps } from '../RoutePresentation'
import { RootState } from 'store'
import { RouteProps } from 'react-router'
import { connect } from 'react-redux'
import { Status } from 'models'

export const LoginGuardRoute = connect<PrivateRoutePresentationProps, RecordObject, RouteProps, RootState>(
  (state: RootState): PrivateRoutePresentationProps => {
    const { status } = state.auth
    return {
      isAuthorized: status !== Status.SUCCEEDED,
      pathname: '/home',
      search: 'empty',
    }
  }
)(PrivateRoutePresentation)

export type RecordObject<T = unknown> = Record<string, T>
