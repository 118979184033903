import { Action, configureStore, ThunkAction, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import authReducers from './auth'
import userReducers from './user'
import priceReducers from './price'
import exhibitionReducers from './exhibition'
import userTransactionReducers from './userTransaction'
import announcementnReducers from './announcement'
import elementReducers from './element'
import { visitsReducers } from './visits'
import paymentReducers from './payment'
import newsReducers from './news'
import { tokenUtils } from 'utils'

const combinedReducer = combineReducers({
  auth: authReducers,
  user: userReducers,
  price: priceReducers,
  exhibition: exhibitionReducers,
  userTransaction: userTransactionReducers,
  announcement: announcementnReducers,
  element: elementReducers,
  payment: paymentReducers,
  visits: visitsReducers,
  news: newsReducers,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'logout/logoutRequest/fulfilled') {
    tokenUtils.removeToken()
    state = undefined
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
