import CardImage from 'assets/icons/card1.png'
import Text from '../Text'
import { CreditCardIcon } from 'assets/iconComponents'
import { FontSize, FontWeight, FontFamily } from 'constants/index'
import { FunctionComponent } from 'react'
import { cardNumberPattern, padStartCode } from 'utils/functions'
import styled from 'styled-components'

interface Props {
  cardNumber: number
  fontWeight?: FontWeight
  fontSize?: FontSize | string
}
interface imageProps {
  image?: string
}
const CustomerCard: FunctionComponent<Props> = ({ cardNumber, fontSize = FontSize.SIXTEEN }) => {
  return (
    <Container image={CardImage} className="customCard">
      <CreditCardIcon className="img" />
      <Text
        fontFamily={FontFamily.NORMAL}
        textAlign={'center'}
        whiteSpace={'nowrap'}
        fontSize={fontSize}
        className="card-number"
      >
        {cardNumberPattern(padStartCode(cardNumber.toString()))}
      </Text>
    </Container>
  )
}

export default CustomerCard

const Container = styled.div<imageProps>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  .img {
    width: inherit;
    height: inherit;
    position: absolute;
  }
  .card-number {
    padding-bottom: 20px;
    z-index: 1;
  }
`
