import { ReactElement } from 'react'
import { Colors } from 'constants/colors'

interface Props {
  color?: Colors
  className?: string
  onClick?: () => void
}

function Heart({ color = Colors.FRENCH_ROSE, className, onClick }: Props): ReactElement {
  return (
    <svg onClick={onClick} className={className} width={27} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.789 0c-3.014.008-5.556 2.045-6.465 4.85C12.402 2.044 9.84.026 6.83.02 3.043.033-.017 3.228 0 7.17.025 14.645 13.723 24 13.723 24S26.69 14.325 26.667 7.104C26.643 3.19 23.572.002 19.789 0Z"
        fill={color}
        stroke={color === Colors.WHITE ? Colors.DARKER_GREY : undefined}
      />
    </svg>
  )
}

export default Heart
