import React from 'react'
import { VoidFunctionComponent } from 'react'
import MediaQuery from 'react-responsive'
import { ResponsiveConstant } from '../../constants/responsive-constant'
import DesktopSize from './DesktopSize'
import MobileSize from './MobileSize'

const Visited: VoidFunctionComponent = () => {
  return (
    <>
      <MediaQuery query={ResponsiveConstant.isTabletOrMobile}>
        <MobileSize />
      </MediaQuery>
      <MediaQuery query={ResponsiveConstant.isDesktopOrLaptop}>
        <DesktopSize />
      </MediaQuery>
    </>
  )
}

export default Visited
