import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, BodyWrapper } from './ModalStyledComponents'
import ModalTitleAndMessage from './ModalTitleAndMessage'
import { useAppSelector } from 'store/hooks'
import { DebitCards } from 'Components/PaymentCards'
import PaymentCardsWrapper from 'Components/PaymentCards/PaymentCardsWrapper'
import { selectPaymentFormsGroups } from 'store/payment'
import { returnGroupCards, redirectPost } from 'utils/functions'

const PaymentMethodModal = () => {
  const [t] = useTranslation()
  const paymentGroups = useAppSelector(selectPaymentFormsGroups)
  const [loading, setLoading] = useState(false)
  const initialTitleState = {
    title: t('paymentMethod'),
    message: t('paymentMethodModalMessage'),
    submessage: t('paymentMethodModalSubMessage'),
  }

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [setLoading])

  return (
    <Container>
      <BodyWrapper>
        <ModalTitleAndMessage {...initialTitleState} />
        <PaymentCardsWrapper
          className="justify-content-between"
          handleSubmit={selectedAction => {
            setLoading(true)
            redirectPost(selectedAction.action, {
              ...selectedAction.fields,
            })
          }}
          loading={loading}
          cards={returnGroupCards(DebitCards, paymentGroups)}
        />
      </BodyWrapper>
    </Container>
  )
}
export default PaymentMethodModal
