import { FunctionComponent, useState } from 'react'
import { Tabs } from 'antd'
import UpdateUserInfo from './UpdateUserInfo'
import ProfileSummary from './ProfileSummary'
import Receipts from './Receipts'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const { TabPane } = Tabs

const AllTabsForProfilePage: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const [activeKey, setActiveKey] = useState('1')
  const onKeyChange = (key: string) => setActiveKey(key)

  return (
    <Tabs defaultActiveKey="1" size="large" activeKey={activeKey} tabBarGutter={72} onChange={onKeyChange}>
      <TabPane tab={t('summary')} key="1">
        <Container>
          <ProfileSummary onChange={onKeyChange} />
        </Container>
      </TabPane>
      <TabPane tab={t('receipts')} key="2">
        <Container>
          <Receipts />
        </Container>
      </TabPane>
      <TabPane tab={t('contactInfo')} key="3">
        <Container>
          <UpdateUserInfo />
        </Container>
      </TabPane>
    </Tabs>
  )
}

export default AllTabsForProfilePage

const Container = styled.div`
  padding: 0px;
  width: 100%;
  height: 100%;
  @media (max-width: 700px) {
    padding: 0 24px;
  }
`
