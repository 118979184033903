import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import Close from 'assets/icons/close.svg'

interface ModalParams {
  isVisible: boolean
  className?: string
  children: ReactNode
  closable?: boolean
  type?: string
  setVisible: () => void
}

const ModalComponent: FunctionComponent<ModalParams> = ({
  isVisible,
  className,
  children,
  closable = false,
  type,
  setVisible,
}: ModalParams) => {
  return (
    <Modal
      centered
      visible={isVisible}
      footer={null}
      closable={closable}
      style={{ position: 'relative' }}
      className={className}
      onCancel={setVisible}
    >
      <CloseContainer src={Close} alt="alose" onClick={setVisible} />
      {children}
    </Modal>
  )
}

export default ModalComponent

const CloseContainer = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`
