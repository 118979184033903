import { VFC } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import styled from 'styled-components'
import Text from 'Components/UiKit/Text'
import { levels } from 'Components/Profile/dependencies/Tabs/ProfileSummary/Cards/Levels'
import { definedLevel } from 'utils/definedLevel'
import { FontSize, FontFamily, FontWeight } from 'constants/fonts'
import { useAppSelector } from 'store/hooks'
import { selectData } from 'store/user'

const CircleSlider: VFC = () => {
  const { visits_count } = useAppSelector(selectData)
  const { levelNumber, levelColor, levelMaxNumber } = definedLevel(visits_count)
  const Icon = levels[levelNumber as keyof typeof levels].icon

  const maxLevel = levelMaxNumber + (levelMaxNumber !== 21 ? 1 : 0)

  return (
    <Container>
      <CircularProgressbarWithChildren
        value={visits_count}
        circleRatio={0.75}
        maxValue={maxLevel}
        styles={{
          trail: {
            strokeLinecap: 'butt',
            transform: 'rotate(-135deg)',
            transformOrigin: 'center center',
          },
          path: {
            strokeLinecap: 'butt',
            transform: 'rotate(-135deg)',
            transformOrigin: 'center center',
            stroke: levelColor,
          },
        }}
      >
        <LevelStyled className={'fit-parent'}>
          <Icon className={'responsive-image'} />
        </LevelStyled>
        <DetailTextStyled>
          <Text
            fontSize={FontSize.TWENTY}
            width="fit-content"
            fontFamily={FontFamily.NORMAL}
            fontWeight={FontWeight.FOUR}
          >
            {visits_count}&nbsp;/&nbsp;
          </Text>
          <Text
            fontSize={FontSize.TWENTY}
            width="fit-content"
            fontFamily={FontFamily.BOLD}
            fontWeight={FontWeight.SEVEN}
          >
            {maxLevel}
          </Text>
        </DetailTextStyled>
      </CircularProgressbarWithChildren>
    </Container>
  )
}

export default CircleSlider

const Container = styled.div`
  width: 189px;
  height: 189px;
  padding-top: 24px;
  margin: 0 auto;
  position: relative;
`
const DetailTextStyled = styled.div`
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`
const LevelStyled = styled.div`
  width: 70px;
  height: 70px;
`
