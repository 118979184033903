import {
  VisaIcon,
  NordeaIcon,
  DanskeBankIcon,
  SPankkiIcon,
  // EazybreakIcon,
  EpassiIcon,
  EdenredIcon,
  MasterCardIcon,
  OPIcon,
  OmaspIcon,
  AlandsbankenIcon,
  SaastopankkiIcon,
  HandelsbankenIcon,
  PopPankkiIcon,
  MobilePayIcon,
  AktiaIcon,
  SmartumIcon,
  Collectorb2c,
} from 'assets/iconComponents'

export const DebitCards = [
  {
    group: 'solinor',
    icon: VisaIcon,
  },
  {
    group: 'solinor',
    icon: MasterCardIcon,
  },
]

export const InternetBankCards = [
  {
    group: 'paytrail',
    icon: NordeaIcon,
    id: 'nordea',
  },
  {
    group: 'paytrail',
    icon: OPIcon,
    id: 'osuuspankki',
  },
  {
    group: 'paytrail',
    icon: DanskeBankIcon,
    id: 'danske',
  },
  {
    group: 'paytrail',
    icon: AktiaIcon,
    id: 'aktia',
  },
  {
    group: 'paytrail',
    icon: PopPankkiIcon,
    id: 'pop',
  },
  {
    group: 'paytrail',
    icon: SaastopankkiIcon,
    id: 'saastopankki',
  },
  {
    group: 'paytrail',
    icon: AlandsbankenIcon,
    id: 'alandsbanken',
  },
  {
    group: 'paytrail',
    icon: HandelsbankenIcon,
    id: 'handelsbanken',
  },
  {
    group: 'paytrail',
    icon: SPankkiIcon,
    id: 'spankki',
  },
  {
    group: 'paytrail',
    icon: OmaspIcon,
    id: 'omasp',
  },
  {
    group: 'paytrail',
    icon: MobilePayIcon,
    id: 'mobilepay',
  },
  {
    group: 'paytrail',
    icon: Collectorb2c,
    id: 'collectorb2c',
  },
]

export const CulturalVoucherCards = [
  {
    group: 'smartum',
    icon: SmartumIcon,
  },
  {
    group: 'epassi',
    icon: EpassiIcon,
  },
  {
    group: 'edenred',
    icon: EdenredIcon,
  },
  // {
  //   group: 'eazybreak',
  //   icon: EazybreakIcon,
  // },
]

export const AllMethods = [...DebitCards, ...InternetBankCards, ...CulturalVoucherCards]
